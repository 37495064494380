import { Pipe, PipeTransform } from '@angular/core';

/*
 * This pipe doesn't currently supports the url with query params.
*/

@Pipe({
  name: 'filename'
})

export class FilenamePipe implements PipeTransform {

  transform(url: string, extension: boolean = true): string {
    let fileName: string = '';
    if(extension)
      fileName = url.split('/').pop();
    else
      fileName = url.split('/').pop().split('.')[0]
    return fileName;
  }

}
