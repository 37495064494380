<div class="col-md-12 w-100 px-3 py-3" [formGroup]="form">
  <div
    class="upload-files-wrapper"
    [ngClass]="{ 'cursor-not-allowed': productImages?.length >= maxMediaUploadCount }"
  >
    <div
      (click)="fileInput.click()"
      [ngClass]="{ disable: productImages?.length >= maxMediaUploadCount }"
    >
      <div class="upload-btn">
        <button
          mat-stroked-button
          type="button"
          class="btn upload-button-blue float-none mb-1"
        >
          <img src="../../../assets/icons/tables/upload.svg" class="mr-2" />
          <span>Upload files</span>
        </button>
        <input
          #fileInput
          type="file"
          multiple
          (change)="getFileDetails($event)"
          style="display:none;"
          accept="image/png,image/webp,image/jpeg,.pdf,.mp4"
          formArrayName="media"
        />
        <div class="note-container ml-1">Note : mp4, png, jpeg, PDF | Size : 500kb / 200kb </div>
      </div>
    </div>
  </div>
</div>
<div class="col-md-12" *ngIf="productImages?.length > 0">
  <div class="files-uploaded-count mb-2">
    {{ productImages.length }} files have been uploaded
  </div>
  <ng-container *ngFor="let image of productImages; let i = index">
    <div class="files-action-container display-flex">
      <div
        class="file-details-container"
      >
        <div class="file-name-container">
          <img
            src="../../../assets/icons/general/warning.svg"
            alt="error"
            class="mr-1"
            *ngIf="image.size > 10000000"
          />
          <span class="file-name">{{image.name}}</span>
        </div>
        <img
          src="../../../assets/icons/tables/edit.svg"
          alt="uploaded"
          class="file-status"
          (click)="editUploadedImage.click()"
        />
        <input
          #editUploadedImage
          type="file"
          (change)="editFileDetails($event, i)"
          style="display:none;"
          accept="image/png,image/webp,image/jpeg,.pdf,.mp4"
        />
      </div>
      <img
        class="ml-2 file-status"
        src="../../../assets/icons/general/delete.svg"
        alt=""
        (click)="removeImage(i)"
      />
    </div>
    <div class="error-text mb-4" *ngIf="image.size > 10000000">
      Error: file size is >500 KB. Please resize the image
      <a href="https://tinypng.com/" target="_blank" class="link-blue">here</a>
    </div>
  </ng-container>
</div>