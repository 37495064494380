import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { createHeader } from '@app/helpers/helper-functions';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  baseURL: string = environment.baseURL;
  header: any;
  authToken: any;
  tokenRefreshed: boolean = false;
  constructor(private http: HttpClient) {
    this.header = new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "webVersion": environment.versions.app
    });
  }

  getOTP(data): Observable<any> {
    let userData = {
      fromDevice: 2,
      mobile: data.mobileNumber,
      isFourDigit: true,
      communicationChannel: 1,
      clientType: 9,
      whatsAppOptIn: false,
    };
    // return this.http.post(this.baseURL + 'otps', userData, {
    //   headers: this.header
    // });
    return this.http.post(this.baseURL + "v2/otps", userData, {
      headers: this.header,
      observe: "response"
    });
  }
  /*
  for octa user this API will verify toke and
  for normal mobile no and otp base user its verify mobile and otp
  */
  verifyOtpOrToken(data): Observable<any> {
    // return this.http.post(this.baseURL + 'otps/verify', data, {
    //   headers: this.header
    // });
    let userData: any = {
      otp: data.otp,
      mobile: data.mobile,
      clientType: 9,
      appType: data.appType
    };
    if(data.appType === "ADMIN") {
      userData = {...userData, token: data.token}
    }
    return this.http.post(this.baseURL + "v2/authenticate", userData, {
      headers: this.header,
      observe: "response"
    });
  }

  refreshToken(data?): Observable<any>{
    const authUser = JSON.parse(localStorage.getItem('authUser'))
    const isOktaUser = JSON.parse(localStorage.getItem('isOktaUser'))
    let userData: any = {
      refreshToken: authUser.refreshToken,
      appType: isOktaUser ? "ADMIN" : "SELLER",
      clientType: 9,
      mobile: authUser.mobile
    }
    return this.http.post(this.baseURL + "refresh/token", userData, {
      headers: this.header,
      observe: "response"
    })
  }

  resendOtp(data): Observable<any> {
    let userData = {
      fromDevice: 2,
      mobile: data.mobileNumber,
      communicationChannel: 1,
      clientType: 9,
    }
    return this.http.post(this.baseURL + "resend/otp", userData, {
      headers: this.header,
      observe: "response"
    });
  }

  sellerSignUp(data): Observable<any> {
    return this.http.post(this.baseURL + "seller/signup", data, {
      headers: this.header,
      observe: "response"
    });
  }

  getSellerDetails(appUserId): Observable<any> {
    let a_token = JSON.parse(localStorage.getItem('authUser'))
    return this.http.get(this.baseURL + `seller/appUserId/${appUserId}`, {
      headers: createHeader(),
      observe: "response",
    });
  }

  getAuthUser(): Observable<any> {
    // api/app-users
    let a_token = JSON.parse(localStorage.getItem('authUser'))
    this.authToken = a_token.token;
    return this.http.get(this.baseURL + 'app-users', {
      headers: createHeader(),
      observe: 'response'
    });
  }

  logout(data): Observable<any> {
    let a_token = JSON.parse(localStorage.getItem('authUser'))
    this.authToken = a_token.token;
    return this.http.post(this.baseURL + "app-user/logout", data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  checkStatusOfTnc(): Observable<any> {
    let a_token = JSON.parse(localStorage.getItem('authUser'));
    this.authToken = a_token.token;

    return this.http.get(this.baseURL+"tnc/seller/latest", {
      headers: createHeader(),
      observe: 'response'
    });
  }

  getEnterpriseDetails(enterpriseId): Observable<any> {
    let a_token = JSON.parse(localStorage.getItem('authUser'));
    this.authToken = a_token.token;
    return this.http.get(this.baseURL+`enterprises/${enterpriseId}`, {
      headers: createHeader(),
      observe: 'response'
    });
  }


  signAgreement(enterpriseId): Observable<any> {
    let a_token = JSON.parse(localStorage.getItem('authUser'));
    this.authToken = a_token.token;

    return this.http.post(this.baseURL+"agreements/enterprise", {enterpriseId} ,{
      headers: createHeader(),
      observe: 'response'
    });
  }

  setTncStatus(data): Observable<any> {
    let a_token = JSON.parse(localStorage.getItem('authUser'))
    this.authToken = a_token.token;
    return this.http.post(this.baseURL+ "tnc/seller", data, {
      headers: createHeader(),
      observe: "response"
    })
  }
}
