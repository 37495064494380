import { MomentDateAdapter } from "@angular/material-moment-adapter";

export class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: "long" | "short" | "narrow") {
    return ["S", "M", "T", "W", "T", "F", "S"];
  }
}

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
