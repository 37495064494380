import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'seller-agreement-modal',
  templateUrl: './seller-agreement-modal.component.html',
  styleUrls: ['./seller-agreement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SellerAgreementModalComponent implements OnInit {
  urlAgreement: string;
  showBoth: boolean = false;
  height : number;
  s3Url = {
    distributor : "https://afs-static-content.s3.ap-south-1.amazonaws.com/sellerAgreement.pdf",
    brand_or_manufacturer : 'https://afs-static-content.s3.ap-south-1.amazonaws.com/sellerAgreementBrand.pdf',
    distributor_and_brand_or_manufacturer : 'https://afs-static-content.s3.ap-south-1.amazonaws.com/sellerAgreementBrand.pdf',
    update_distributor : 'https://afs-static-content.s3.ap-south-1.amazonaws.com/update_sellerAgreement.pdf',
    update_brand_or_manufacturer : 'https://afs-static-content.s3.ap-south-1.amazonaws.com/update_sellerAgreementBrand.pdf',
    update_distributor_and_brand_or_manufacturer : 'https://afs-static-content.s3.ap-south-1.amazonaws.com/update_sellerAgreementBrand.pdf'
  }
  bothAgreements = [
    {
      name: "Distributor Agreement",
      url: this.s3Url.distributor
    },
    {
      name: "Brand Agreement",
      url: this.s3Url.brand_or_manufacturer
    }
  ]
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private dialogRef: MatDialogRef<SellerAgreementModalComponent>
  ) {
    this.height = this.dialog_data.showAcceptButton ? 600 : 650;
    const sellerType = this.dialog_data.sellerType;
    if(sellerType == "showBoth"){
      this.showBoth = true;
    }
    this.urlAgreement = this.s3Url[sellerType];
   }
   
  ngOnInit(): void {}

  onAccept() {
    this.dialogRef.close({value: "accepted"})
  }

  closeModal(){
    this.dialogRef.close({value: "cancel"})
  }
}
