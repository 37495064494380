<div class="background">
  <div class="gradient">
    <div class="container">
      <h1>{{ 'anms.title.long' | translate }}</h1>
      <div class="actions">
        <a [ngClass]="routeAnimationsElements" mat-raised-button class="actions-main" color="primary" routerLink="../features">
          {{ 'anms.menu.features' | translate }}
        </a>
        <a mat-raised-button class="actions-main" color="accent" routerLink="../examples" [ngClass]="routeAnimationsElements">
          {{ 'anms.menu.examples' | translate }}
        </a>
        <a mat-raised-button class="actions-main" color="warn" routerLink="../settings" [ngClass]="routeAnimationsElements">
          {{ 'anms.about.change-theme' | translate }}
        </a>
        <br>
        <span [ngClass]="routeAnimationsElements">{{ 'anms.about.check-blogs' | translate }}</span>
        <br>
        <a mat-raised-button
           [ngClass]="routeAnimationsElements"
           target="_blank"
           href="https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1">
           <fa-icon [icon]="['fab','medium-m']"></fa-icon>
          Material Theming
        </a>
        <a mat-raised-button
           [ngClass]="routeAnimationsElements"
           target="_blank"
           href="https://medium.com/@tomastrajan/6-best-practices-pro-tips-for-angular-cli-better-developer-experience-7b328bc9db81">
           <fa-icon [icon]="['fab','medium-m']"></fa-icon>
          Angular Cli Tips
        </a>
        <a mat-raised-button
           [ngClass]="routeAnimationsElements"
           target="_blank"
           href="https://medium.com/@tomastrajan/object-assign-vs-object-spread-in-angular-ngrx-reducers-3d62ecb4a4b0">
           <fa-icon [icon]="['fab','medium-m']"></fa-icon>
          Ngrx Typescript Tips
        </a>
        <a mat-raised-button
           [ngClass]="routeAnimationsElements"
           target="_blank"
           href="https://medium.com/@tomastrajan/how-to-style-angular-application-loading-with-angular-cli-like-a-boss-cdd4f5358554">
           <fa-icon [icon]="['fab','medium-m']"></fa-icon>
          App Loading Style
        </a>
      </div>
      <div class="get-started" [ngClass]="routeAnimationsElements">
        <h2 data-testid='get-started'>{{ 'anms.about.get-started' | translate }}</h2>
        <code>git clone
          https://github.com/tomastrajan/angular-ngrx-material-starter.git
          new-project</code><br>
        <code>cd new-project</code><br>
        <code>npm install</code><br>
        <code>npm start</code>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="follow-releases" [ngClass]="routeAnimationsElements">
    <h2 rtl>{{ 'anms.about.get-notified.title' | translate }}</h2>
    <p rtl>
      {{ 'anms.about.get-notified.follow' | translate }} <a href="https://www.twitter.com/releasebutler" target="_blank">Release
        Butler</a>
      {{ 'anms.about.get-notified.description' | translate }}
    </p>

    <img [src]="releaseButler" alt="Release Butler tweet example">
  </div>
</div>
<div class="container" rtl>
  <div class="contributors" [ngClass]="routeAnimationsElements">
    <h2>{{ 'anms.about.contributors.title' | translate }}</h2>
    <p>{{ 'anms.about.contributors.description1' | translate }}</p>
    <p>{{ 'anms.about.contributors.description2' | translate }}</p>
    <p>
      {{ 'anms.about.contributors.description3' | translate }}
      <a href="https://github.com/tomastrajan/angular-ngrx-material-starter/issues" target="_blank">{{'anms.about.contributors.issues'
        | translate}}</a>
      {{ 'anms.about.contributors.description4' | translate }}
      <a href="https://github.com/tomastrajan/angular-ngrx-material-starter/blob/master/CONTRIBUTING.md" target="_blank">{{'anms.about.contributors.guide'
        | translate}}</a>
      ...
    </p>

    <div class="contributors-wrapper">

      <!-- ALL-CONTRIBUTORS-LIST:START -->
  <a class="contributor" href="https://medium.com/@tomastrajan" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/3764868?v=4">
    <span>Tomas Trajan</span>
  </a>


  <a class="contributor" href="https://twitter.com/tim_deschryver" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/28659384?v=4">
    <span>Tim Deschryver</span>
  </a>


  <a class="contributor" href="http://gs500coder.blogspot.com" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/1336862?v=4">
    <span>Moshe</span>
  </a>


  <a class="contributor" href="https://github.com/hhubik" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/14813201?v=4">
    <span>hhubik</span>
  </a>


  <a class="contributor" href="https://github.com/mumairofficial" target="_blank">
    <img src="https://avatars2.githubusercontent.com/u/6498132?v=4">
    <span>Muhammad Umair</span>
  </a>


  <a class="contributor" href="https://github.com/philmerrell" target="_blank">
    <img src="https://avatars2.githubusercontent.com/u/2514268?v=4">
    <span>Phil Merrell</span>
  </a>


  <a class="contributor" href="https://www.linkedin.com/in/kharshats" target="_blank">
    <img src="https://avatars3.githubusercontent.com/u/1059539?v=4">
    <span>Valery Kharshats</span>
  </a>


  <a class="contributor" href="https://1nv1n.GitHub.io/" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/3885804?v=4">
    <span>Neil Pathare</span>
  </a>


  <a class="contributor" href="http://peterkrieg.com" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/7385488?v=4">
    <span>Peter Krieg</span>
  </a>


  <a class="contributor" href="https://github.com/alexkonovalov" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/11887873?v=4">
    <span>Alex</span>
  </a>


  <a class="contributor" href="https://github.com/scheifi" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/42332935?v=4">
    <span>Fiona</span>
  </a>


  <a class="contributor" href="https://www.dehopre.com" target="_blank">
    <img src="https://avatars3.githubusercontent.com/u/97023?v=4">
    <span>Fabien Dehopré</span>
  </a>


  <a class="contributor" href="http://www.matiasiglesias.com.ar" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/2591889?v=4">
    <span>Matias Iglesias</span>
  </a>


  <a class="contributor" href="https://github.com/Jeykairis" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/10895934?v=4">
    <span>Jeremy Kairis</span>
  </a>


  <a class="contributor" href="https://github.com/Zuiago" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/8050831?v=4">
    <span>Iago Andrade</span>
  </a>


  <a class="contributor" href="https://github.com/aideslucas" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/8929821?v=4">
    <span>aideslucas</span>
  </a>


  <a class="contributor" href="https://www.linkedin.com/in/terrystrachan/" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/4921146?v=4">
    <span>Terry Strachan</span>
  </a>


  <a class="contributor" href="https://github.com/lau32" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/8844319?v=4">
    <span>Laurentiu Amagdei</span>
  </a>


  <a class="contributor" href="https://github.com/simply10w" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/16257515?v=4">
    <span>Petar Djordjevic</span>
  </a>


  <a class="contributor" href="https://github.com/ZackDeRose" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/3788405?v=4">
    <span>Zachary DeRose</span>
  </a>


  <a class="contributor" href="https://github.com/erhise" target="_blank">
    <img src="https://avatars3.githubusercontent.com/u/28264731?v=4">
    <span>erhise</span>
  </a>


  <a class="contributor" href="http://joost.io" target="_blank">
    <img src="https://avatars2.githubusercontent.com/u/14245982?v=4">
    <span>Joost Zöllner</span>
  </a>


  <a class="contributor" href="https://github.com/zetsnotdead" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/15807730?v=4">
    <span>Tomasz Kula</span>
  </a>


  <a class="contributor" href="http://code-it.eu/" target="_blank">
    <img src="https://avatars2.githubusercontent.com/u/18703886?v=4">
    <span>Front-End Developer</span>
  </a>


  <a class="contributor" href="http://vishalsodani.com" target="_blank">
    <img src="https://avatars1.githubusercontent.com/u/364183?v=4">
    <span>Vishal Sodani</span>
  </a>


  <a class="contributor" href="https://github.com/kosmas58" target="_blank">
    <img src="https://avatars2.githubusercontent.com/u/43474?v=4">
    <span>Kosmas Schütz</span>
  </a>


  <a class="contributor" href="https://github.com/huyen1990" target="_blank">
    <img src="https://avatars2.githubusercontent.com/u/44722180?v=4">
    <span>huyen1990</span>
  </a>


  <a class="contributor" href="https://medium.com/@chauey" target="_blank">
    <img src="https://avatars3.githubusercontent.com/u/5130533?v=4">
    <span>Chau (Joe) Nguyen</span>
  </a>


  <a class="contributor" href="https://www.amadousall.com/" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/8947112?v=4">
    <span>Amadou Sall</span>
  </a>


  <a class="contributor" href="https://github.com/dino" target="_blank">
    <img src="https://avatars3.githubusercontent.com/u/26004739?v=4">
    <span>Dino</span>
  </a>


  <a class="contributor" href="https://github.com/Doehl" target="_blank">
    <img src="https://avatars0.githubusercontent.com/u/1913751?v=4">
    <span>Mathias Døhl</span>
  </a>


  <a class="contributor" href="http://www.carlintveld.nl" target="_blank">
    <img src="https://avatars2.githubusercontent.com/u/6196260?v=4">
    <span>Carl in 't Veld</span>
  </a>
<!-- ALL-CONTRIBUTORS-LIST:END -->

    </div>
  </div>
</div>
