import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from './services/okta.service';
import {ActivatedRoute} from '@angular/router';
import { Utility } from '@app/utility/utility';

@Component({ styleUrls: ['./callback.component.scss'],
    template: `<router-outlet></router-outlet>
    <div class="main-section" *ngIf="showHideErrorMessage">
        <div class="navbar-header">
            <img class="logo-image" src="https://in.uplonline.com/images/logo.png" alt="">

        </div>

        <div>
            <p class="heading">
                <i class="fa fa-exclamation-triangle alert-color" aria-hidden="true"></i> Oops ! You do not have access to Nurture Retail Admin.
                Please go through the following points for getting access
            </p>
        </div>

        <div>
            <p class="contact">
                1. After logging into OKTA check if the below app (highlighted in red border) 'Nurture Retail Admin' shows up in the okta dashboard.
            </p>
            <div>
                <a href="assets/Okta-retail-page-app.png" target="_blank">
                    <img src="assets/Okta-retail-page-app.png" title="Open image" class="accessImg">
                </a>
                
            </div>
            <p class="contact">2. If the above app does not show in the okta home dashboard please click on “Service Now” app in the okta home dashboard and raise the IT request by following the below points.</p>
            <div class="subpoints">
                <p class="contact">1. Login in to <a class="link-color" href="https://uplonline.service-now.com/sp?id=sc_category" target="_blank">Service Portal - UP</a></p>
                <p class="contact">2. Click on Request --> Raise a Request</p>
                <p class="contact">3. Choose “Additional Service Request”</p>
                <p class="contact">4. In application choose “Okta App Integration”</p>
                <p class="contact">5. In the description add comment as below and submit</p>
                    <div class="subpoints">
                        <ul>
                            <li>Please add < UGDN > to Nurture Retail Admin Portal</li>
                            <li>Replace < UGDN > with appropriate UGDN./li>
                            <li>Once the ticket is fullfilled, then application can be accessed with below url</li>
                            <li><a class="link-color" href="https://retail.nurture.farm/admin-sign-in" target="_blank">https://retail.nurture.farm/admin-sign-in</a></li>
                            <li>Please add < UGDN > to Nurture Retail Admin Portal</li>  
                        </ul>
                    </div>
            </div>
            <p class="contact">4. Post the request is fulfilled, please check if the above app (highlighted in red arrow) shows up in the okta dashboard.</p>
            <p class="contact">
                5. If you have access for 'Nurture Retail Admin' in the okta home dashboard page and not able to login still, Please drop an email to <a class="link-color" href="mailto: web-support@nurture.farm" target="_blank">web-support@nurture.farm</a> with your UGDN for the access
            </p>
        </div>
    </div>
    ` })
export class CallbackComponent implements OnInit {
    isQueryParams: any;
    showHideErrorMessage = false;
    constructor(private okta: OktaAuthService,
                private activeRoute: ActivatedRoute,
                private utility: Utility) {}

    async ngOnInit() {

        if(localStorage.getItem("authUser")) {
            this.utility.roleBaseNavigation();
        } else {
            this.isQueryParams = this.activeRoute.snapshot.queryParams['error'];
            if (!this.isQueryParams) {
            // Handles the response from Okta and parses tokens
                await this.okta.handleAuthentication();
                this.showHideErrorMessage = false;
            } else {
                this.showHideErrorMessage = true;
            }
        }
        
    }
}
