import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsAuthenticated } from '@app/core/auth/auth.selectors';
import { AppState } from '@app/core/core.state';
import { AuthenticationHelper } from '@app/helpers/authentication';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(selectIsAuthenticated));
  }
}

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot) {
    let rolesAccess = route.data["roles"] as Array<string>;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      if(authUser.role.includes('ADMIN')) {
        return true;
      } else {
        if(rolesAccess.some(role => authUser.role.includes(role))) {
          return true;
        } else {
          navigateToSignInPage(this.router);
        }
      }
    } else {
      navigateToSignInPage(this.router);
    }
  }
}

@Injectable()
export class AdminShareGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      if(authUser.role.includes('ADMIN') ||
          authUser.role.includes('DASHBOARD_USER')) {
        return true;
      } else {
        navigateToSignInPage(this.router);
      }
    } else {
      navigateToSignInPage(this.router);
    }
  }
}

@Injectable()
export class PartnerGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      if(authUser.role.includes('DELIVERY_PARTNER')) {
        return true;
      } else {
        navigateToSignInPage(this.router);
      }
    } else {
      navigateToSignInPage(this.router);
    }
  }
}

@Injectable()
export class VerifierGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      if(authUser.role.includes('KYC_VERIFICATION')) {
        return true;
      } else {
        navigateToSignInPage(this.router);
      }
    } else {
      navigateToSignInPage(this.router);
    }
  }
}

@Injectable()
export class OktaLoginVerifier implements CanActivate {
  
  constructor(private router: Router) {}
  canActivate() {
    if(AuthenticationHelper.isUserAuthenticated()) {
      if(localStorage.getItem("isOktaUser")) {
        return true;
      }
      else {
        this.router.navigate(["sign-in"]);
      }
    } else {
      // redirect to seller sign in page
      return true;
    }
  }
}

function navigateToSignInPage(router) {
  if(localStorage.getItem("isOktaUser")) {
    router.navigate(["admin-sign-in"]);
  } else {
    router.navigate(["sign-in"]);
  }
}