export class AuthenticationHelper {

    private constructor() {

    }

    /**
     * To check dashboard user login
     * @param token
     */
    static isDashboardUser() {
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        let isDashboardUser = false;
         if (!authUser.role.includes('ADMIN') && authUser.role.includes('DASHBOARD_USER')) {
             isDashboardUser = true;
         }
        return isDashboardUser;
    }

    /**
     * To check admin user login
     * @param token
     */
    static isAdminUser() {
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        let isAdminUser = false;
        if (authUser.role.includes('ADMIN')) {
            isAdminUser = true;
        }
        return isAdminUser;
    }

    /**
     * To check logistic partner user
     */
    static isPartnerUser() {
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        let isPartneUser = false;
        if (authUser) {
            if (authUser.role[0] === ('DELIVERY_PARTNER')) {
                isPartneUser = true;
            }
        }
        return isPartneUser;
    }

    /**
     * To check user edit access
     * @param component
     */
    static checkEditAcess(component) {
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        let hasAccess = false;
        if (authUser) {
            if (authUser.role.includes('ADMIN') || authUser.role.includes(component)) {
                hasAccess = true;
            }
        }
        return hasAccess;
    }

     /**
     * To check verifier user login
     */
      static isVerifier() {
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        let isVerifier = false;
        if (authUser.role.includes('KYC_VERIFICATION')) {
            isVerifier = true;
        }
        return isVerifier;
    }

       /**
     * To check retailer homepage acess
     */
        static checkRetailerHomepageAccess() {
            let authUser = JSON.parse(localStorage.getItem("authUser"));
            let hasAccess = false;
            if (authUser.role.includes('ADMIN') || authUser.role.includes('RETAILER_VIEW') 
             || authUser.role.includes('RETAILER_EDIT')) {
                hasAccess = true;
            }
            return hasAccess;
        }

    /* 
        ti check user is login through octa 
    */

    static isOKTAUser() {
        //let octaUser = JSON.parse(localStorage.getItem("okta-token-storage"));
        if(localStorage.getItem("isOktaUser")) {
            return true;
        } else  {
            return false;
        }
    }

    /* 
     check if user is logined or not 
    */
    static isUserAuthenticated() {
        //let authUser = localStorage.getItem("authUser");
        if(localStorage.getItem("authUser")) {
            // alredy logined
            return true;
        } else {
            // not logined
            return false;
        }
    }

     /**
     * To set access to view retailer name and mobile. 
     */
    static setRetailerDetailsAccess(enterpriseList) {
        const allowedEnterprise = [1, 2, 3];
        const hasAccess = enterpriseList.find(enterprise => allowedEnterprise.includes(enterprise.id)) ? true : false;
        localStorage.setItem("retailerInfoAccess", hasAccess.toString());
        return hasAccess;
    }

     /**
     * To check access to view retailer name and mobile. 
     */
    static checkRetailerDetailsAccess() {
        return localStorage.getItem("retailerInfoAccess") === 'false' ? false : true;
    }

    static checkIfProspectSeller() {
        const a_user = JSON.parse(localStorage.getItem("authUser"));
        if (a_user?.role?.includes("PROSPECT_SELLER")) {
          return true;
        } else {
          return false;
        }
    }

    static checkPendingOrdersViewAccess() {
        const a_user = JSON.parse(localStorage.getItem("authUser"));
        if (a_user?.role?.includes("PENDING_ORDERS_VIEW")) {
          return true;
        } else {
          return false;
        }
    }

    static checkIfUGDNPresent() {
        const a_user = JSON.parse(localStorage.getItem("authUser"));
        return a_user?.ugdn ? true : false;
    }

    static checkIfRolePresent(role) {
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        return authUser?.role?.includes(role);
    }
}