import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { createHeader, createTokenHeader } from "@app/helpers/helper-functions";

@Injectable()
export class EnterpriseService {
  baseURL: string = environment.baseURL;
  header: any;
  authToken: any;
  constructor(private http: HttpClient) {}

  getAllEnterprise(): Observable<any> {
    const enterpriseListUrl = this.baseURL + `enterprises/country/1`;
    return this.http.get(enterpriseListUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }
}
