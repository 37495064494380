<div *ngFor="let menu of item; let i = index">
  <mat-list-item (click)="showMenu(menu)" class="parent" [disableRipple]="true"
  [ngClass]="{'active-menu': menu.label === activeLabel}">
    <span class="full-width" *ngIf="sidebar">{{ menu.label }}</span>
    <img [src]="menu.icon" mat-list-icon class="menu-icon" />
    <mat-icon
      class="menu-button"
      [ngClass]="{
        rotated: menu.isExpand && menu.children && menu.children.length
      }"
      *ngIf="menu.children && menu.children.length && sidebar"
      >expand_more</mat-icon
    >
  </mat-list-item>
  <div
    class="submenu"
    [ngClass]="{
      expanded: menu.isExpand && menu.children && menu.children.length
    }"
    *ngIf="menu.children && menu.children.length && menu.isExpand && sidebar"
  >
    <a mat-list-item *ngFor="let child of menu.children">
      <div (click)="showSubMenu(child, menu)" class="menu-label">
        {{ child.label }}
      </div>
    </a>
  </div>
</div>
