import { environment } from "@env/environment";
import { HttpHeaders } from '@angular/common/http';
import * as moment from "moment";

const csvtojson = require("csvtojson");

export const arrayBufferToString = (arrayBuffer: ArrayBuffer) => {
  const enc = new TextDecoder("utf-8");
  const arr = new Uint8Array(arrayBuffer);
  const str = enc.decode(arr);
  return str;
};

export const arrayBufferToJSON = async (arrayBuffer: ArrayBuffer, headers: string[]) => {
  const str = arrayBufferToString(arrayBuffer);
  const json = await csvtojson({
    headers: headers,
  }).fromString(str);
  return json;
};

export const getENV = () => {
  const baseURL = environment.baseURL;
  const env = baseURL?.split("//")[1]?.split(".")[0];
  return env;
};

export const emptyStringToNull = (value) => {
  return value == "" ? null : value;
};

export const createHeader = () => {
  let a_token = JSON.parse(localStorage.getItem("authUser"));
  return new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + a_token.token,
    "webVersion": environment.versions.app,
    "authServiceValidated" : "true"
  });
}

export const createTokenHeader = () => {
  let a_token = JSON.parse(localStorage.getItem("authUser"));
  return new HttpHeaders({
    Authorization: "Bearer " + a_token.token,
    "webVersion": environment.versions.app,
    "authServiceValidated" : "true"
  });
}

export const createGraphqlHeader = () => {
  return new HttpHeaders({
    "Content-Type": "application/json",
  });
}

export const tokenExpiringInMs = (token: string, timeInMs) => {
  const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
  return moment.unix(expiry).diff(moment()) < timeInMs;
}

