import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { PopupRoutingModule} from '@app/pop-up/pop-up.routing';
import { PopUpComponent} from '@app/pop-up/pop-up.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentsModule } from "@app/common-components/common-components.module";

@NgModule({
  declarations: [PopUpComponent],
  exports: [
    PopUpComponent
  ],
  imports: [
    CommonModule,
    PopupRoutingModule,
    SharedModule,
    TextMaskModule,
    NgxPaginationModule,
    NgSelectModule,
    CommonComponentsModule
  ]
})
export class PopupModule {}
