import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChange,
} from "@angular/core";
import * as moment from "moment";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { CustomDateAdapter } from "../custom-date-adapter";
import { CustomDatePickerHeaderComponent } from "../custom-date-picker-header/custom-date-picker-header.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "start-end-date-picker",
  templateUrl: "./start-end-date-picker.component.html",
  styleUrls: ["./start-end-date-picker.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StartEndDatePickerComponent implements OnInit {
  minDate: any;
  minEndDate: any;
  @Input() form: any;
  @Input() fetchedStartDate: any;
  @Input() fetchedEndDate: any;
  customDateHeader = CustomDatePickerHeaderComponent;

  constructor() {}

  ngOnInit(): void {
    this.minDate = moment(new Date());
    this.minEndDate = moment(new Date());
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes["fetchedStartDate"]?.currentValue) {
      this.form.controls["startDate"].setValue(
        moment(this.fetchedStartDate, "DD-MM-YYYY")
      );
      if (moment(this.fetchedStartDate).isAfter(new Date())) {
        const temp = moment(this.fetchedStartDate, "DD-MM-YYYY");
        this.minDate = temp;
        this.minEndDate = temp;
      }
    }
    if (changes["fetchedEndDate"]?.currentValue) {
      this.form.controls["endDate"].setValue(
        moment(this.fetchedEndDate, "DD-MM-YYYY")
      );
    }
  }

  startDateChange(event) {
    let startDateGet = event.value._d;
    this.minEndDate = moment(startDateGet, "DD-MM-YYYY");
    this.form.controls["endDate"].setValue(null);
  }
}
