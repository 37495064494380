<div [class]="'theme-wrapper ' + (theme$ | async)">
  <div
    class="toolbar"
    *ngIf="(isAuthenticated$ | async) ? !isPartnerPage : false"
    [class.mat-elevation-z4]="stickyHeader$ | async"
  >
      <div class="toolbar {{prospectSeller ? 'toolbar-seller' : ''}}" *ngIf="(isAuthenticated$ | async) ? !isPartnerPage : false"
        [class.mat-elevation-z4]="(stickyHeader$ | async)">
        <mat-toolbar color="primary">
          <button *ngIf="!prospectSeller" mat-icon-button (click)="sidebar = !sidebar" class="">
            <fa-icon icon="bars"></fa-icon>
          </button>

          <span (click)="roleBaseNavigation()" class="branding spacer">
            <img [src]="logo" />
          </span>

          <div>

            <button *ngIf="(isAuthenticated$ | async)" mat-icon-button [matMenuTriggerFor]="toolbarUserMenu">
              <fa-icon icon="user-circle" class="user-profile-icon"></fa-icon>
            </button>

            <span class="d-none d-md-inline user-name">
              {{authUser}}
            </span>

            

            <mat-menu #toolbarUserMenu="matMenu">
              <button mat-menu-item (click)="onLogoutClick()">
                <mat-icon>
                  <fa-icon icon="power-off"></fa-icon>
                </mat-icon>
                <span>{{ 'anms.menu.logout' | translate }}</span>
              </button>
              <button *ngIf="!prospectSeller" mat-menu-item (click)="onAgreementClick()">
                <mat-icon>
                  <fa-icon icon="file-contract"></fa-icon>
                </mat-icon>
                <span>Agreement</span>
              </button>
            </mat-menu>
          </div>

        </mat-toolbar>
      </div>
  </div>

  <mat-sidenav-container [ngClass] = "{'full-height': !(isAuthenticated$ | async)}" autosize>
    <mat-sidenav
      #appDrawer
      mode="side"
      position="start"
      opened="true"
      *ngIf="(isAuthenticated$ | async) ? !isPartnerPage && !prospectSeller : false"
      class="sidebar-container"
      [ngClass]="{'sidebar-custom-open': sidebar}" 
    >
      <mat-nav-list>
        <app-menu-list-item
          [item]="navigationSideMenu"
          [(sidebar)]="sidebar"
        ></app-menu-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <div [ngClass]="{ wrapper: isAuthenticated$ | async }">
      <div
        [ngClass]="{
          content: (isAuthenticated$ | async) ? !isPartnerPage : false
        }"
        [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.path"
        [ngStyle]="{'background-color': '#FCFCFC'}"
      >
        <spinner></spinner>
        <div *ngIf="(isAuthenticated$ | async) ? !isPartnerPage && !prospectSeller : false" class="app-heading">
          <h3>
            <span style="text-align: center; font-weight: bold;">
              {{ appHeader }}
            </span>
          </h3>
        </div>

        <div>
          <router-outlet #o="outlet"></router-outlet>
        </div>
      </div>
    </div>
  </mat-sidenav-container>

</div>
