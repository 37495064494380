<!-- <section *ngIf="dialogData && dialogData.id === 'delete_record'">
  <modal #deleteModal [closeOnOutsideClick]="false" class="delete-common-popup delete-modal-user">
    <modal-header>
      <div class="float-left width-100 delete-heading">{{dialogData.title}}</div>
    </modal-header>
    <modal-content>
      <div class=" width-100 text-center font-size-15 " mat-dialog-content>
        <div>
          <img class="delete-popup-img" src="assets/warning.png" />
          <span mat-dialog-title class="dialog-title">
            {{dialogData.text}}
          </span>
        </div>
      </div>
    </modal-content>
    <modal-footer class="pop-up-button">
      <button class="cancel-btn" type="button" mat-raised-button (click)="cancelPopup()">CANCEL</button>
      <button class="pop-up-button-submit" type="submit" mat-raised-button (click)="deleteConfirmation(deleteID)">OK</button>
    </modal-footer>
  </modal>
</section> -->

<div class="popup mx-1 my-4" *ngIf="dialogData" mat-dialog-content>
  <div class="modal-header-container d-flex">
    <div class="modal-header-container--title">{{dialogData.title}}
      <p *ngIf="dialogData.html">
        {{dialogData.html}}
      </p>
    </div>
    <img src="../../../../assets/close.svg" (click)="onCloseCancel()" />
  </div>
  <div class="conference-title">
    <p *ngIf="dialogData.text && dialogData.id === 'unauthorized'" class="my-3 px-1">
      {{dialogData.text}}
    </p>
    <div *ngIf="dialogData && dialogData.id === 'change_FA' ">
      <!--<span class="change-fa-text ml-4">-->
        <!--<strong> Field Agent Assigned:</strong> {{dialogData.text}}-->
      <!--</span>-->
      <div class="float-left col-lg-12 add-country-guidelines-container add-main-container mt-4 px-2">
        <div class="float-left col-lg-12 px-0">
          <strong> Field Agent Assigned:</strong> <span class="ml-3">{{dialogData.text}}</span>
        </div>
      </div>
    </div>
    <div class="text-center my-3" *ngIf="dialogData.id === 'delete_record'">
      <img class="delete-popup-img" src="assets/warning.png" *ngIf="dialogData.id === 'delete_record'" />
      <span *ngIf="dialogData.text">
        {{dialogData.text}}
      </span>
    </div>
    <div class="text-center mt-3" *ngIf="dialogData.id === 'terms_condtion_cancel'" >
      <img class="delete-popup-img" src="assets/warning.png"/>
      <span *ngIf="dialogData.text">
        {{dialogData.text}}
      </span>
    </div>
  </div>

  <section *ngIf="dialogData.id === 'reject_kyc'">
    <form [formGroup]='rejectKycForm' (ngSubmit)="rejectKYC(rejectKycForm.value)" class="reject_kyc px-0 mt-2">
      <mat-form-field>
        <textarea matInput formControlName="description" placeholder="Reason for KYC rejection" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          mdAutosizeMinRows="1" maxlength="255"
                  onkeypress="if((event.charCode > 31 && event.charCode != 32)
                                    && (event.charCode < 48 || event.charCode > 57)
                                    && (event.charCode < 65 || event.charCode > 90)
                                    && (event.charCode < 97 || event.charCode > 122))
                                    {return false;}"></textarea>
        <mat-error *ngIf="(rejectKycForm?.controls?.description.touched || submitted) && rejectKycForm?.controls?.description.errors?.required">
          <span class="form-error-message"> Please mention the reason for KYC Rejection. </span>
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="rejectKycFormInvalid && !rejectKycForm?.controls?.description.touched">
        <span class="form-error-message">
          Please mention the reason for KYC Rejection.
        </span>
      </mat-error>
    </form>
  </section>
  <section *ngIf="dialogData.id === 'extend_expiry'" >
    <div class="extend-expiry-content">
      <p class="extend-expiry-description">The expiry period will be extened by <span class="boldFont">3 days</span> and it will applied for all the suborders</p>
      <div class="order-id-list" *ngFor="let id of dialogData.idsList">
        <p class="order-id">{{id}}</p>
      </div>
    </div>
  </section>
  <section *ngIf="dialogData.id === 'attachment_process_completed'" >
    <div class="attachment-upload-success">{{dialogData.text}}
    </div>
  </section>
  

  <section *ngIf="dialogData.id === 'reject_doc'" class="reject-doc">
    <form [formGroup]='rejectDocForm' (ngSubmit)="rejectKYC(rejectDocForm.value)" class="reject_kyc px-0 mt-2">
      <p *ngIf="this.dialogData.type === 'kycReject'" class="note mb-2">*It will reject KYC for this Retailer.</p>
      <mat-label>Notification message:</mat-label>
      <div class="mb-2 mt-1">
        <ng-select class="swal-select-dropdown" [clearable]="false" [clearOnBackspace]="true" [items]="kycRemarks" bindLabel="itemName"
        bindValue="itemName" formControlName="notification" dropdownPosition="bottom">
        </ng-select>
                 <!-- <textarea matInput formControlName="notification" placeholder="{{getPlaceholderAndReason(this.dialogData.type).placeholder}}"
                           cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                           mdAutosizeMinRows="1" maxlength="150"
                           onkeypress="if((event.charCode > 31 && event.charCode != 32)
                                      && (event.charCode < 48 || event.charCode > 57)
                                    && (event.charCode < 65 || event.charCode > 90)
                                    && (event.charCode < 97 || event.charCode > 122))
                                    {return false;}"></textarea> -->
        <mat-error *ngIf="(rejectDocForm?.controls?.notification.touched || submitted) && rejectDocForm?.controls?.notification.errors?.required">
          <span class="form-error-message">{{getPlaceholderAndReason(this.dialogData.type).reason}}</span>
        </mat-error>
      </div>
      <mat-label>SMS:</mat-label>
      <!-- <mat-form-field> -->
        <div class="mb-3 mt-1">
        <ng-select class="swal-select-dropdown" [clearable]="false" [clearOnBackspace]="true" [items]="kycRemarks" bindLabel="itemName"
        bindValue="itemName" formControlName="sms" dropdownPosition="bottom">
        </ng-select>
                 <!-- <textarea matInput formControlName="sms" placeholder="{{getPlaceholderAndReason(this.dialogData.type).placeholder}}" cdkTextareaAutosize
                           #autosize="cdkTextareaAutosize"
                           mdAutosizeMinRows="1" maxlength="30"
                           onkeypress="if((event.charCode > 31 && event.charCode != 32)
                                      && (event.charCode < 48 || event.charCode > 57)
                                    && (event.charCode < 65 || event.charCode > 90)
                                    && (event.charCode < 97 || event.charCode > 122))
                                    {return false;}"></textarea> -->
        <mat-error *ngIf="(rejectDocForm?.controls?.sms.touched || submitted) && rejectDocForm?.controls?.sms.errors?.required">
          <span class="form-error-message">{{getPlaceholderAndReason(this.dialogData.type).reason}}</span>
        </mat-error>
      </div>
      <!-- </mat-form-field> -->
    </form>
  </section>

  <section *ngIf="dialogData.id === 'reject_kyc_doc'" class="reject-doc">
    <form [formGroup]='rejectKYCDocForm' (ngSubmit)="rejectKYC(rejectKYCDocForm.value)" class="reject_kyc px-0 mt-2">
      <p *ngIf="this.dialogData.type === 'kycReject'" class="note">*It will reject KYC for this Retailer.</p>
      <mat-label>Notification message:</mat-label>
      <div class="mb-3 mt-2">
        <ng-select class="swal-select-dropdown" [clearable]="false" [clearOnBackspace]="true" [items]="docType == 'SEED_CER' ? seedRemarks : docType == 'PESTICIDE_CER' ? pesticideRemarks : fertilizerRemarks" bindLabel="itemName"
        bindValue="itemName" formControlName="notification" dropdownPosition="bottom">
        </ng-select>
                 <!-- <textarea matInput formControlName="notification" placeholder="{{getPlaceholderAndReason(this.dialogData.type).placeholder}}"
                           cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                           mdAutosizeMinRows="1" maxlength="150"
                           onkeypress="if((event.charCode > 31 && event.charCode != 32)
                                      && (event.charCode < 48 || event.charCode > 57)
                                    && (event.charCode < 65 || event.charCode > 90)
                                    && (event.charCode < 97 || event.charCode > 122))
                                    {return false;}"></textarea> -->
        <mat-error *ngIf="(rejectKYCDocForm?.controls?.notification.touched || submitted) && rejectKYCDocForm?.controls?.notification.errors?.required">
          <span class="form-error-message">{{getPlaceholderAndReason(this.dialogData.type).reason}}</span>
        </mat-error>
      </div>
    </form>
  </section>

  <section *ngIf="dialogData.id === 'retailer_block_unblock'" class="reject-doc">
    <form [formGroup]='retailerBlockUnblockForm' (ngSubmit)="rejectKYC(retailerBlockUnblockForm.value)" class="reject_kyc px-0 mt-2">
      <mat-label>Reason:</mat-label>
      <mat-form-field class="margin-bottom-8">
                 <textarea matInput formControlName="reason" placeholder="{{dialogData.isRetailerBlock ? 'Reason for Retailer Unblock' : 'Reason for Retailer Block'}}"
                           cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                           mdAutosizeMinRows="1" maxlength="255"
                           onkeypress="if((event.charCode > 31 && event.charCode != 32)
                                      && (event.charCode < 48 || event.charCode > 57)
                                    && (event.charCode < 65 || event.charCode > 90)
                                    && (event.charCode < 97 || event.charCode > 122))
                                    {return false;}"></textarea>
        <mat-error *ngIf="(retailerBlockUnblockForm?.controls?.reason.touched || submitted) && retailerBlockUnblockForm?.controls?.reason.errors?.required">
          <span class="form-error-message">{{dialogData.isRetailerBlock ? 'Please mention the reason for Retailer Unblock.' : 'Please mention the reason for Retailer Block.'}}</span>
        </mat-error>
      </mat-form-field>
    </form>
  </section>

  <section *ngIf="dialogData.id === 'pending_kyc'">
    <form [formGroup]='pendingKycForm' class="reject_kyc px-0 mt-2">
      <!-- <mat-form-field> -->
        <div class="mb-5 mt-5">
          <mat-label>Reason for KYC pending</mat-label>
        <ng-select class="swal-select-dropdown" [clearable]="false" [clearOnBackspace]="true" [items]="pendingRemarks" bindLabel="itemName"
        bindValue="itemName" formControlName="description" dropdownPosition="bottom">
        </ng-select>
        <!-- <textarea matInput formControlName="description" placeholder="Reason for KYC pending" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  mdAutosizeMinRows="1" maxlength="255"
                  onkeypress="if((event.charCode > 31 && event.charCode != 32)
                                    && (event.charCode < 48 || event.charCode > 57)
                                    && (event.charCode < 65 || event.charCode > 90)
                                    && (event.charCode < 97 || event.charCode > 122))
                                    {return false;}"></textarea> -->
        <mat-error *ngIf="(pendingKycForm?.controls?.description.touched || submitted) && pendingKycForm?.controls?.description.errors?.required">
          <span class="form-error-message"> Please mention the reason for KYC Pending. </span>
        </mat-error>
      </div>
      <!-- </mat-form-field> -->
      <mat-error *ngIf="pendingKycFormInvalid && !pendingKycForm?.controls?.description.touched">
        <span class="form-error-message">
          Please mention the reason for KYC Pending.
        </span>
      </mat-error>
    </form>
  </section>

  <section *ngIf="dialogData.id === 'cancel_order'">
    <form [formGroup]='cancelOrderForm' class="reject_kyc px-0 mt-2">
      <mat-form-field>
        <textarea matInput formControlName="description" placeholder="Reason for Order Cancellation" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  mdAutosizeMinRows="1" maxlength="255"
                  onkeypress="if((event.charCode > 31 && event.charCode != 32)
                                    && (event.charCode < 48 || event.charCode > 57)
                                    && (event.charCode < 65 || event.charCode > 90)
                                    && (event.charCode < 97 || event.charCode > 122))
                                    {return false;}"></textarea>
        <mat-error *ngIf="(cancelOrderForm?.controls?.description.touched || submitted) && cancelOrderForm?.controls?.description.errors?.required">
          <span class="form-error-message">Please mention the reason for Order Cancellation.</span>
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="cancelOrderFormInvalid && !cancelOrderForm?.controls?.description.touched">
        <span class="form-error-message">
          Please mention the reason for Order Cancellation.
        </span>
      </mat-error>
    </form>
  </section>

  <div class="col-md-12 add-new-value-modal px-1 mt-4" *ngIf="dialogData.id === 'export_order_details'">

    <div mat-dialog-content>
      <form [formGroup]='orderExportForm' (ngSubmit)="onSubmitDate(orderExportForm.value)">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="row date-field-container">
              <div class="range-label px-3 mb-2">Order date range</div>
              <mat-date-range-picker-custom class="px-3 w-100" [range]="range" fieldSeparator="" fieldClass="w-100" label="Select order date range"></mat-date-range-picker-custom>
            </div>

              <div class="row pb-4 plant-dropdown">
                <div class="col">
                  <ng-select #selectPlantFilter class="plantListDropdown" [items]="listOfPlants"
                    bindLabel="formattedName" bindValue="plantCode" placeholder="Select Plant"
                    (change)="onChangePlant($event)" [closeOnSelect]="false" [multiple]="true"
                    formControlName="plant" dropdownPosition="bottom">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" class="mx-2">
                      <div class="row">
                          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" class="mr-2" (change)="handleSelectCheckbox($event)"/>
                                <span>{{item.formattedName}}</span>
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:1">
                            <span class="ng-value-label">{{item.plantCode}}</span>
                            <span class="ng-value-icon right" (click)="clear(item)"
                                aria-hidden="true">×</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 1">
                            <span class="ng-value-label">{{items.length - 1}} more...</span>
                        </div>
                    </ng-template>
                    <ng-template ng-header-tmp>
                        <div>
                            <button style="padding: 2px 5px" type="button"
                                class="btn btn-link list-btn select-clear-actions" (click)="onSelectAllPlantCode();">Select
                                All</button>
                            <button style="padding: 2px 15px" type="button"
                                class="btn btn-link list-btn select-clear-actions"
                                (click)="onClearAllPlantCode(); selectPlantFilter.handleClearClick()">Clear
                                All</button>
                        </div>
                    </ng-template>
                  </ng-select>

                  <mat-error class="plant-req-error " *ngIf="submitted && !orderExportForm?.controls?.plant.valid">
                    <div class="error-message-height" *ngIf="orderExportForm?.controls?.plant.hasError('required')">Plant is required</div>
                  </mat-error>
                </div>
                <div class="col">
                  <ng-select #selectStatusFilter class="plantListDropdown" [items]="listOfOrderStatus" bindLabel="value" bindValue="id" placeholder="Select Order Status" [loading]="loading"
                      [closeOnSelect]="false" [multiple]="true" formControlName="status" dropdownPosition="bottom">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" class="mx-2">
                        <div class="row">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" class="mr-2" (change)="handleSelectCheckbox($event)"/>
                                  <span>{{item.value}}</span>
                        </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items | slice:0:1">
                        <span class="ng-value-label">{{item.value}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label">{{items.length - 1}} more...</span>
                      </div>
                    </ng-template>
                    <ng-template ng-header-tmp>
                      <div>
                        <button style="padding: 2px 5px" type="button" class="btn btn-link list-btn select-clear-actions"
                                (click)="onSelectAllOrderStatus()">Select All</button>
                        <button style="padding: 2px 15px" type="button" class="btn btn-link list-btn select-clear-actions"
                                (click)="onClearAllOrderStatus(); selectStatusFilter.handleClearClick()">Clear All</button>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div *ngIf="dialogData.plantMandatory" class="row pb-4 plant-dropdown">
                <div class="col">
                  <ng-select #selectPlantRegionFilter class="plantListDropdown" [items]="plantRegionList" bindLabel="name" bindValue="id" placeholder="Select Plant Region"
                    (change)="onChangePlantRegion($event)" [closeOnSelect]="false" [multiple]="true" formControlName="filterPlantRegion" dropdownPosition="bottom">
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                      <span class="ng-value-label">{{item.name}}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 1">
                      <span class="ng-value-label">{{items.length - 1}} more...</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" class="mx-2">
                    <div class="row">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" class="mr-2" (change)="handleSelectCheckbox($event)"/>
                              <span>{{item.name}}</span>
                    </div>
                  </ng-template>
                  <ng-template ng-header-tmp>
                    <div>
                      <button style="padding: 2px 5px" type="button" class="btn btn-link list-btn select-clear-actions"
                              (click)="onSelectAllPlantRegion()">Select All</button>
                      <button style="padding: 2px 15px" type="button" class="btn btn-link list-btn select-clear-actions"
                              (click)="onClearAllPlantRegion(); selectPlantRegionFilter.handleClearClick()">Clear All</button>
                    </div>
                  </ng-template>
                  </ng-select>
                </div>
                <div class="col">
                  <ng-select #selectPlantModelFilter class="plantListDropdown" [items]="plantModelList" bindLabel="name" bindValue="id" placeholder="Select Plant Model"
                    (change)="onChangePlantModel($event)" [closeOnSelect]="false" [multiple]="true" formControlName="filterPlantModel" dropdownPosition="bottom">
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                      <span class="ng-value-label">{{item.name}}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 1">
                      <span class="ng-value-label">{{items.length - 1}} more...</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" class="mx-2">
                    <div class="row">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" class="mr-2" (change)="handleSelectCheckbox($event)"/>
                              <span>{{item.name}}</span>
                    </div>
                  </ng-template>
                  <ng-template ng-header-tmp>
                    <div>
                      <button style="padding: 2px 5px" type="button" class="btn btn-link list-btn select-clear-actions"
                              (click)="onSelectAllPlantModel()">Select All</button>
                      <button style="padding: 2px 15px" type="button" class="btn btn-link list-btn select-clear-actions"
                              (click)="onClearAllPlantModel(); selectPlantModelFilter.handleClearClick()">Clear All</button>
                    </div>
                  </ng-template>
                  </ng-select>
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- update order status  -->

  <section *ngIf="dialogData.id === 'update_order_status'">
    <div class="float-left col-lg-12 add-country-guidelines-container add-main-container margin-top-15">
      <div class="float-left col-lg-12 add-country-guidelines-container margin-top-15 padding-0">
        <div class="float-left col-lg-12 add-country-guidelines-container padding-0">
          <!-- <mat-card class="float-left col-lg-12">
              <mat-card-content> -->
          <form [formGroup]='orderStatusForm'>
            <!-- <div class="float-left col-lg-6">
                    <mat-label>Customer</mat-label>
                    <mat-form-field class="capital d-block" appearance="outline">
                      <input type="text" matInput autocomplete="false" formControlName="customer">
                    </mat-form-field>
                  </div>
                  <div class="float-left col-lg-6">
                    <mat-label>Order Amount</mat-label>
                    <mat-form-field class="capital d-block" appearance="outline">
                      <input type="text" matInput autocomplete="false" formControlName="orderAmount">
                    </mat-form-field>
                  </div>

                  <div class="float-left col-lg-4">
                    <mat-label>Order Date</mat-label>
                    <mat-form-field class="capital d-block" appearance="outline">
                      <input type="text" matInput autocomplete="false" formControlName="orderDate">
                    </mat-form-field>
                  </div>

                  <div class="float-left col-lg-4">
                    <mat-label>Order Number</mat-label>
                    <mat-form-field class="capital d-block" appearance="outline">
                      <input matInput formControlName="orderNumber" />
                    </mat-form-field>
                  </div> -->
            <div class="float-left col-12 ">
              <mat-label>Order Status</mat-label>
              <ng-select class="swal-select-dropdown" [clearable]="false" [clearOnBackspace]="true" [items]="statusList" bindLabel="itemName"
                bindValue="itemName" (change)="onChange($event)" formControlName="orderStatus">
              </ng-select>

              <!-- <angular2-multiselect class="topic-dropdown" [data]="statusList" [(ngModel)]="orderStatus" [ngModelOptions]="{standalone: true}"
                [settings]="multiDropdownSettings" (onSelect)="onItemSelect($event)">
              </angular2-multiselect> -->
            </div>
          </form>
          <!-- </mat-card-content>
            </mat-card> -->
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="dialogData.id === 'change_FA'">
    <div class="float-left col-lg-12 mb-4 mt-2 px-1 add-country-guidelines-container add-main-container margin-top-15">
      <form [formGroup]='changeFAForm'>
        <div class="float-left col-12 fa-label px-1">
          <mat-label><strong>Change Field Agent: </strong></mat-label>
          <ng-select class="ml-3 faStatusDropdown" [items]="fieldAgentList" placeholder="Select FA" [clearable]="false"
                     [clearOnBackspace]="true" bindLabel="empName" bindValue="id" [searchFn]="customFASearch" formControlName="pinCodeFAMappingId" dropdownPosition="bottom">

            <ng-template *ngIf="changeFAForm.value.pinCodeFAMappingId" ng-label-tmp let-item="item">
              <span *ngIf="item.empName">{{ item.empName + ' ' + '[' + item.pinCodeFrom + ' - ' + item.pinCodeTo + ']' + ' [' + item.plant + ']' }}</span>
            </ng-template>
            <ng-template *ngIf="fieldAgentList.length" ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span>{{ item.empName + ' ' + '[' + item.pinCodeFrom + ' - ' + item.pinCodeTo + ']' + ' [' + item.plant + ']' }}</span>
            </ng-template>
          </ng-select>
        </div>
      </form>
    </div>
  </section>
  <mat-dialog-actions>
    <div class="pop-up-button">
      <button type="button" mat-stroked-button class="button-basic secondary-button button--light-red" *ngIf="dialogData.showCancelButton" (click)="onCloseCancel()">Cancel</button>
      <button mat-stroked-button class="button-basic primary-button" *ngIf="dialogData.confirmButtonText" (click)=" onCloseConfirm()">{{dialogData.confirmButtonText}}</button>
    </div>
  </mat-dialog-actions>
</div>