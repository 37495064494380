import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { PopUpComponent } from '@app/pop-up/pop-up.component';
@Component({
  selector: 'terms-and-condition-modal',
  templateUrl: './terms-and-condition-modal.component.html',
  styleUrls: ['./terms-and-condition-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TermsAndConditionModalComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TermsAndConditionModalComponent>,
    public dialogeRefCancel: MatDialogRef<PopUpComponent>) {
  }

  ngOnInit() { }

  /* Function to display warning on cancel T&C Popup**/
  onCloseCancel() {
    this.dialogConfig.data = {
      title: "Warning",
      text: "You have to accept Terms and condition to proceed",
      height: "250px",
      width: "400px",
      id: "terms_condtion_cancel",
      showCancelButton: false,
      confirmButtonText: "OK"
    };
    this.dialogeRefCancel = this.dialog.open(PopUpComponent, {
      data: this.dialogConfig,
      disableClose: true,
      hasBackdrop: true
    });
    // this.dialogeRefCancel.afterClosed().subscribe(result => {
    // });
  }

  /* Function when user accept T&C**/
  onAccept() {
    this.dialogRef.close({ value: "Accepted" });
  }
}
