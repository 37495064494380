<div [formGroup]="form">
  <div class="float-left col-lg-6 padding-left-0">
    <mat-label>Start Date <i class="required">*</i></mat-label>
    <mat-form-field
      class="capital d-block  date-field-container"
      floatLabel="never"
    >
      <input
        matInput
        class="date-input"
        autocomplete="off"
        formControlName="startDate"
        (click)="startDatepicker.open()"
        [matDatepicker]="startDatepicker"
        (dateChange)="startDateChange($event)"
        [min]="minDate"
        placeholder="Select Start Date"
      />
      <mat-datepicker-toggle matSuffix [for]="startDatepicker">
      </mat-datepicker-toggle>
      <mat-datepicker
        #startDatepicker
        [calendarHeaderComponent]="customDateHeader"
      ></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="float-left col-lg-6 padding-right-0">
    <mat-label>Expiry Date <i class="required">*</i></mat-label>
    <mat-form-field
      class="capital d-block  date-field-container"
      floatLabel="never"
    >
      <input
        matInput
        class="date-input"
        autocomplete="off"
        formControlName="endDate"
        (click)="endDatepicker.open()"
        [matDatepicker]="endDatepicker"
        [min]="minEndDate"
        placeholder="Select Expiry Date"
      />
      <mat-datepicker-toggle matSuffix [for]="endDatepicker">
      </mat-datepicker-toggle>
      <mat-datepicker
        #endDatepicker
        [calendarHeaderComponent]="customDateHeader"
      ></mat-datepicker>
    </mat-form-field>
  </div>
</div>
