import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  CustomDateAdapter,
  MY_FORMATS,
} from "@app/common-components/custom-date-adapter";
import { MatRangePickerHeaderComponent } from "@app/common-components/mat-date-range-picker/mat-range-picker-header/mat-range-picker-header.component";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { CalendarService } from "@app/common-components/mat-date-range-picker/calendar-service.service";
import { MatDatepicker } from "@angular/material/datepicker";
import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "mat-date-range-picker-custom",
  templateUrl: "./mat-date-range-picker-custom.component.html",
  styleUrls: ["./mat-date-range-picker-custom.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MatDateRangePickerCustomComponent implements OnInit {
  @Input() range: FormGroup<{
    startDate: FormControl<moment.Moment>;
    endDate: FormControl<moment.Moment>;
  }>;
  @Input() maxDate: moment.Moment = moment(new Date());
  @Input() minDate: moment.Moment = null;
  @Input() label: string = "";
  @Input() fieldClass: string = "w-100";
  @Input() fieldSeparator: string = "-";
  @Input() showClearButton: boolean = false;
  @ViewChild("picker") datePicker: MatDatepicker<Date>;
  @Output() dateRangeUpdatedEvent = new EventEmitter<any>();
  customDateHeader: any = MatRangePickerHeaderComponent;
  maxRange: number;

  constructor(public calendarService: CalendarService) {}

  ngOnInit(): void {
    this.updateFieldSeparator();
    this.maxRange = this.calendarService.getMaxRange;
  }

  updateFieldSeparator() {
    if(this.range.controls["startDate"].value &&
    !this.range.controls["endDate"].value) {
      this.range.controls["endDate"].setValue(this.range.controls["startDate"].value, {emitEvent:true});
      this.calendarService.setEndDate(this.range.controls["startDate"].value);
    }
    this.dateRangeUpdatedEvent.emit(this.range);
    this.fieldSeparator =
      this.range.controls["startDate"].value &&
      this.range.controls["endDate"].value
        ? "-"
        : "";
  }

  setPicker() {
    this.calendarService.setStartDate(this.range.controls["startDate"].value);
    this.calendarService.setEndDate(this.range.controls["endDate"].value);
    this.datePicker?.open();
  }

  onClearDate() {
    this.range.controls["startDate"].setValue(null);
    this.range.controls["endDate"].setValue(null);
    this.calendarService.setStartDate(this.range.controls["startDate"].value);
    this.calendarService.setEndDate(this.range.controls["endDate"].value);
    this.updateFieldSeparator();
  }
}
