// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require("../../package.json");

export const environment = {
  firebase: {
    projectId: 'retail-admin-portal',
    appId: '1:128568878087:web:873cf21735c382309176a2',
    storageBucket: 'retail-admin-portal.appspot.com',
    apiKey: 'AIzaSyAk2zO5cOsvDaRAbnjGmicuxFmhLgNzTrc',
    authDomain: 'retail-admin-portal.firebaseapp.com',
    messagingSenderId: '128568878087',
    measurementId: 'G-6F0WKDGMM4',
  },
  appName: "SellerFirst",
  envName: "DEV",
  production: false,
  test: false,
  i18nPrefix: "",
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies["@angular/core"],
    ngrx: packageJson.dependencies["@ngrx/store"],
    material: packageJson.dependencies["@angular/material"],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies["@ngx-translate/core"],
    fontAwesome: packageJson.dependencies["@fortawesome/fontawesome-free"],
    angularCli: packageJson.devDependencies["@angular/cli"],
    typescript: packageJson.devDependencies["typescript"],
    cypress: packageJson.devDependencies["cypress"]
  },

  

  // baseURL: 'https://retail.nurture.farm/api/',

  baseURL: 'https://dev.retail.nurture.farm/api/',
  graphqlBaseUrl : 'https://dev.retail.nurture.farm/retail/captain_service/graphql',
  oktaClientId: '0oafueinvzo9xfT0m357'

};
