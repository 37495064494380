import { Injectable } from '@angular/core';

@Injectable()
export class CalendarService {
  maxRange: number = 0;
  selectedStartDate: any;
  selectedEndDate: any;

  constructor() { }

  setMaxRange(maxRange: number) {
    this.maxRange = maxRange;
  }

  get getMaxRange(){
    return this.maxRange;
  }

  setStartDate(startDate: any) {
    this.selectedStartDate = startDate;
  }

  get getStartDate(){
    return this.selectedStartDate;
  }

  setEndDate(endDate: any) {
    this.selectedEndDate = endDate;
  }

  get getEndDate(){
    return this.selectedEndDate;
  }

}