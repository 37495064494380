<img src="../../../../assets/close-with-bg.svg" (click)="onCloseCancel()" />
<div class="image-preview-slider">
    <carousel
    [cellsToShow]="1"
    [loop]="true"
    [height]="300"
    [arrows]="!disableNavigation"
    >
        <div class="carousel-cell" *ngFor="let doc of documents">
            <ng-container *ngIf="doc?.docType?.includes('image')">
            <img class="image-preview-slider--img" [src]="doc?.path">
            </ng-container>
            <ng-container *ngIf="doc?.docType == 'application/pdf'">
                <object width="100%" height="600" type="application/pdf" [data]="doc?.path | safeHtml" id="pdf_content">
                    <p>Your web browser doesn't have a PDF plugin.
                        Instead you can 
                        <a [href]="doc?.path" target="_blank">click here to
                            view the PDF file.
                        </a>
                    </p>
                </object>
            </ng-container>
            <ng-container *ngIf="doc?.docType?.includes('video')">
                <video width="500" height="300" controls>
                    <source [src]="doc?.path" [type]="doc?.docType">
                  Your browser does not support the video.
                  </video>
                  
            </ng-container>
        </div>
    </carousel>
</div>
