import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TermsAndConditionRoutingModule } from "@app/common-popup/terms-and-condition-modal/terms-and-condition-routing";
import { TermsAndConditionModalComponent } from "@app/common-popup/terms-and-condition-modal/terms-and-condition-modal.component";
import { PopupModule } from '@app/pop-up/pop-up.module';

@NgModule({
  declarations: [TermsAndConditionModalComponent],
  exports: [
    TermsAndConditionModalComponent
  ],
  imports: [
    CommonModule,
    TermsAndConditionRoutingModule,
    SharedModule,
    PopupModule
  ]
})
export class TermsAndConditionPopupModule {}
