<div class="upload-btn" *ngIf="!fileName">
  <button
    mat-stroked-button
    type="button"
    (click)="editImage.click()"
    class="btn upload-button-blue float-none"
    [disabled]="restrictAccess"
    [ngClass]="{'cursor-not-allowed': restrictAccess}"
  >
    <img src="../../../assets/icons/tables/upload.svg" class="mr-2" />
    <span>Upload File</span>
  </button>
</div>
<div class="files-action-container display-flex flex-column" *ngIf="fileName">
  <div class="file-details-container">
    <div class="file-name-container">
      <span class="file-name">{{ fileName }}</span>
    </div>
    <img
      src="../../../assets/icons/tables/edit.svg"
      alt="uploaded"
      class="file-status"
      [disabled]="restrictAccess"
      (click)="editImage.click()"
      [ngClass]="{'cursor-not-allowed': restrictAccess}"
    />
  </div>
  <div class="note-type" *ngIf="showTypeNote">Type: {{allowedMediaTypes}}</div>
</div>
<input
  #editImage
  type="file"
  (change)="fileUpload($event)"
  style="display:none;"
  [accept]="allowedMediaTypes"
/>
