<div class="custom-single-date-header">
    <button mat-icon-button (click)="previousClicked('year')" matTooltip="Previous year">
      <mat-icon class="basic-navigator">first_page</mat-icon>
    </button>
    <button mat-icon-button (click)="previousClicked('month')" matTooltip="Previous month" style="margin-left: -15px !important;">
      <mat-icon class="basic-navigator">keyboard_arrow_left</mat-icon>
    </button>
    <span class="custom-header-label">{{periodLabel}}</span>
    <button mat-icon-button (click)="nextClicked('month')" matTooltip="Next month" style="margin-right: -15px !important;">
      <mat-icon class="basic-navigator">keyboard_arrow_right</mat-icon>
    </button>
    <button mat-icon-button (click)="nextClicked('year')" matTooltip="Next year">
      <mat-icon class="basic-navigator">last_page</mat-icon>
    </button>
</div>