import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsAndConditionModalComponent } from "@app/common-popup/terms-and-condition-modal/terms-and-condition-modal.component";
const routes: Routes = [
  {
    path: '',
    component: TermsAndConditionModalComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TermsAndConditionRoutingModule {}

