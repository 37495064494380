import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { AccessToken, OktaAuth } from "@okta/okta-auth-js";
import { customEvents, logEvent } from "@app/analytics/custom-events";
import { MsalService } from "@azure/msal-angular";
@Injectable({
  providedIn: "root"
})
export class OktaAuthService {
  CLIENT_ID = environment.oktaClientId;
  ISSUER = "https://upl.okta.com";
  LOGIN_REDIRECT_URI = `${window.location.origin}/callback`;
  LOGOUT_REDIRECT_URI = window.location.origin;

  oktaAuth = new OktaAuth({
    clientId: this.CLIENT_ID,
    issuer: this.ISSUER,
    redirectUri: this.LOGIN_REDIRECT_URI,
    pkce: false
  });

  $isAuthenticated: Observable<boolean>;
  private observer: Observer<boolean>;

  constructor(private router: Router, private authService: MsalService) {
    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then(val => {
        observer.next(val);
      });
    });
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return !!(await this.oktaAuth.tokenManager.get("accessToken"));
  }

  login() {
    logEvent(customEvents.OKTA_LOGIN_CLICKED);
    // Save current URL before redirect
    // sessionStorage.setItem('okta-app-url', originalUrl || this.router.url);

    // Launches the login redirect.
    localStorage.setItem("isOktaUser", "true");
    this.oktaAuth.token.getWithRedirect({
      scopes: ["openid", "email", "profile"]
    }).then(() => {
      logEvent(customEvents.OKTA_REDIRECTION);
    });
  }

  loginMSAL() {
    this.authService.loginRedirect().subscribe(res => console.log('loginMSAL',res));
  }

  async handleAuthentication(): Promise<void> {
    const tokenContainer = await this.oktaAuth.token.parseFromUrl();
    this.oktaAuth.tokenManager.add("idToken", tokenContainer.tokens.idToken);
    this.oktaAuth.tokenManager.add(
      "accessToken",
      tokenContainer.tokens.accessToken
    );

    // Retrieve the saved URL and navigate back
    // const url = sessionStorage.getItem('okta-app-url');
    // await this.router.navigateByUrl(url '/landing-page');
    await this.router.navigateByUrl("/admin-sign-in");
  }

  async logout() {
    await this.oktaAuth.signOut({
      postLogoutRedirectUri: this.LOGOUT_REDIRECT_URI
    });
    localStorage.clear();
  }

  /**
   * Returns the current accessToken in the tokenManager.
   */
  async getAccessToken(): Promise<string | undefined> {
    try {
      const accessToken: AccessToken = (await this.oktaAuth.tokenManager.get(
        "accessToken"
      )) as AccessToken;
      return accessToken.accessToken;
    } catch (err) {
      // The user no longer has an existing SSO session in the browser.
      // (OIDC error `login_required`)
      // Ask the user to authenticate again.
      return undefined;
    }
  }
}
