<div mat-dialog-content>
        <h2 mat-dialog-title class="modal-title">
            {{dialog_data.title}}
            <button *ngIf="dialog_data.showCloseButton" class="cross-button float-right" (click)="closeModal()">
                <img src="assets/close.svg"/>
            </button>
        </h2>
    <div *ngIf="showBoth">
        <mat-tab-group preserveContent>
            <mat-tab *ngFor="let agreement of bothAgreements" [label]="agreement.name">
                <object width="100%" height="600" type="application/pdf" [data]="agreement.url | safeHtml" id="pdf_content">
                    <p>Your web browser doesn't have a PDF plugin.
                        Instead you can 
                        <a [href]="agreement.url" target="_blank">click here to
                            view the PDF file.
                        </a>
                    </p>
                </object>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div *ngIf="!showBoth" style="position: relative;">
        <object width="100%" [height]="height" type="application/pdf" [data]="urlAgreement | safeHtml" id="pdf_content">
            <p>Your web browser doesn't have a PDF plugin.
                Instead you can 
                <a [href]="urlAgreement" target="_blank">click here to
                    view the PDF file.
                </a>
            </p>
        </object>
    </div>
    <mat-dialog-actions class="d-block fix-bottom" *ngIf="dialog_data.showAcceptButton">
        <div class="checbox-box text-center">
            <mat-checkbox #confirmed >I have read and agreed to the Seller Agreement.</mat-checkbox>
        </div>
        <div class="buttons">
            <button class="popup-primary-btn accept-btn" mat-raised-button (click)="onAccept()" [disabled]="!confirmed.checked">Accept</button>
        </div>
    </mat-dialog-actions>
</div>
