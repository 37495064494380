import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import * as moment from "moment"
@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent implements OnInit {
  @Input() form;
  @Output() onChange = new EventEmitter<any>();
  @Input() placeHolder = "Select Date"
  @Input() showClearButton: boolean = true;
  maxDate = moment(new Date())
  options: any = {
    autoApply: false,
    alwaysShowCalendars: false,
    showCancel: false,
    showClearButton: false,
    linkedCalendars: true,
    singleDatePicker: false,
    showWeekNumbers: false,
    showISOWeekNumbers: false,
    customRangeDirection: true,
    lockStartDate: false,
    closeOnAutoApply: true
  };
  constructor() { }

  ngOnInit(): void { }

  onClearDate() {
    this.form.controls["filterOrderDate"].setValue(null)
  }
}
