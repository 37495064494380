import {
  Component,
  HostBinding,
  EventEmitter,
  Input,
  Output,
  OnInit,
} from "@angular/core";
import { MAT_RIPPLE_GLOBAL_OPTIONS } from "@angular/material/core";
import { NavItem } from "../modals/nav-item.modal";
import {NavigationEnd, Router} from "@angular/router";
import { NavService } from "./service/nav.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";

@Component({
  selector: "app-menu-list-item",
  templateUrl: "./menu-list-item.component.html",
  styleUrls: ["./menu-list-item.component.scss"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
  ],
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean;
  // @HostBinding("attr.aria-expanded") ariaExpanded;
  @Input() item: NavItem;
  // @Input() depth: number;
  isExpanded: boolean = true;
  @Input() sidebar: boolean;
  @Output() sidebarChange = new EventEmitter<boolean>();
  routerSubscription: Subscription;
  activeLabel: string;
  activeMenuItems: string[] = [];

  constructor(public navService: NavService, public router: Router) {
    // if (this.depth === undefined) {
    //   this.depth = 0;
    // }
    
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
              this.isChildrenAvailable(this.item);
            });
  }

  isChildrenAvailable(data): void {
    data.forEach((menu => {
        if (menu.link) {
            if(this.router.url.includes(menu.link)) {
                this.activeLabel = menu.label;
            }
        } else if(menu.children) {
            menu.children.forEach((item) => {
                if(this.router.url.includes(item.link)) {
                    this.activeLabel = menu.label;
                }
            });
        } 
    }))
}
  
ngOnInit() {
  this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.link && url) {
        this.expanded = url.indexOf(`/${this.item.link}`) === 0;
        // this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      if (this.router.url === `/${item.link}`) {
        this.router
          .navigateByUrl("/RefreshComponent", { skipLocationChange: true })
          .then(() => {
            this.router.navigate([item.link]);
          });
      } else {
        this.router.navigate([item.link]);
      }
    }
  }

  showMenu(menuItem: any): void {
    if(this.activeMenuItems.includes(menuItem.label)) {
      this.expanded = false;
      const index = this.activeMenuItems.indexOf(menuItem.label);
      if (index > -1) { 
        this.activeMenuItems.splice(index, 1); 
      }
    } else {
      this.expanded = true;
      this.activeMenuItems.push(menuItem.label)
    }
    menuItem.isExpand = this.expanded;
    this.onItemSelected(menuItem);
    this.sidebarChange.emit(true);
  }

  showSubMenu(child, menu) {
    menu.isExpand = false;
    this.onItemSelected(child);
    const index = this.activeMenuItems.indexOf(menu.label);
      if (index > -1) { 
        this.activeMenuItems.splice(index, 1); 
    }
    this.sidebarChange.emit(false);
  }
}
