<div class="note-container mx-3 my-2" *ngIf="maxRange"><b>Note:</b> You can only select a range of <b>{{maxRange}} days</b>.</div>
<div class="col-md-12 d-flex mt-2 range-inputs-container">
  <div class="col-md-6 px-0">
    <!-- <div class="header-sub-label">Start Date</div> -->
    <div class="header-sub-value">
      <div class="date-container" *ngIf="selectedStartDate !== 'Invalid date'">{{selectedStartDate}}</div>
      <div class="date-container" *ngIf="selectedStartDate === 'Invalid date'" style="color: #808080">Start Date</div>
    </div>
  </div>
  <div class="col-md-6 px-0">
    <!-- <div class="header-sub-label">End Date</div> -->
    <div class="header-sub-value">
      <div class="date-container" *ngIf="selectedEndDate !== 'Invalid date'">{{selectedEndDate}}</div>
      <div class="date-container" *ngIf="selectedEndDate === 'Invalid date'" style="color: #808080">End Date</div>
    </div>
  </div>
</div>  
<div class="custom-single-date-header">
  <button
    mat-icon-button
    (click)="previousClicked('year')"
    matTooltip="Previous year"
  >
    <mat-icon class="basic-navigator">first_page</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="previousClicked('month')"
    matTooltip="Previous month"
    style="margin-left: -15px !important;"
  >
    <mat-icon class="basic-navigator">keyboard_arrow_left</mat-icon>
  </button>
  <span class="custom-header-label">{{ periodLabel }}</span>
  <button
    mat-icon-button
    (click)="nextClicked('month')"
    matTooltip="Next month"
    style="margin-right: -15px !important;"
  >
    <mat-icon class="basic-navigator">keyboard_arrow_right</mat-icon>
  </button>
  <button mat-icon-button (click)="nextClicked('year')" matTooltip="Next year">
    <mat-icon class="basic-navigator">last_page</mat-icon>
  </button>
</div>
