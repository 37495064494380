<div class="date-field-container">
  <mat-form-field floatLabel="never" [class]="fieldClass">
    <mat-date-range-input
      [formGroup]="range"
      [rangePicker]="picker"
      (click)="setPicker()"
      [max]="maxDate"
      [min]="minDate"
      [separator]="fieldSeparator"
    >
      <input matStartDate [placeholder]="label" formControlName="startDate" />
      <input matEndDate formControlName="endDate" />
    </mat-date-range-input>
    <span>
      <button *ngIf="showClearButton ? range.controls['startDate'].value && range.controls['endDate'].value : false"
  class="remove-date-button">
          <i class="fa fa-times remove-date-icon" (click)="onClearDate()"></i>
      </button>
    </span>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker
      #picker
      [maxRange]="maxRange"
      [calendarHeaderComponent]="customDateHeader"
    >
      <mat-date-range-picker-actions>
        <button class="cal-action-cancel" matDateRangePickerCancel (click)="updateFieldSeparator()">
          Cancel
        </button>
        <button class="cal-action-btn" matDateRangePickerApply (click)="updateFieldSeparator()">Apply</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>
</div>
