<div class="input-date-container" [formGroup]="form">
  <input type="text" matInput class="date-range-input" id="orderDateFilter"
  ngxDaterangepickerMd
  [autoApply]="options.autoApply"
  [singleDatePicker]="options.singleDatePicker"
  [locale]="{applyLabel: 'Apply', format: 'DD-MM-YYYY'}"
  [showDropdowns]="true"
  startKey="startDate"
  endKey="endDate"
  autocomplete="off"
  formControlName="filterOrderDate"
  [maxDate]="maxDate"
  [showWeekNumbers]="options.showWeekNumbers"
  [showCancel]="options.showCancel"
  [showClearButton]="options.showClearButton"
  [showISOWeekNumbers]="options.showISOWeekNumbers"
  [customRangeDirection]="options.customRangeDirection"
  firstMonthDayClass="first-day"
  lastMonthDayClass="last-day"
  emptyWeekRowClass="empty-week"
  lastDayOfPreviousMonthClass="last-previous-day"
  firstDayOfNextMonthClass="first-next-day"
  name="daterange"
  [placeholder]="placeHolder"
  (datesUpdated)="onChange.emit()"
  />
  <button *ngIf="showClearButton && form.value.filterOrderDate ? form.value.filterOrderDate.startDate && form.value.filterOrderDate.endDate : false"
  class="remove-date-button">
  <i class="fa fa-times remove-date-icon" (click)="onClearDate()"></i>
  </button>
</div> 