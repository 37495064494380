import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { AuthenticationHelper } from "@app/helpers/authentication";
import { createHeader, createTokenHeader } from "@app/helpers/helper-functions";

@Injectable({
  providedIn: "root"
})
export class OrderService {
  baseURL: string = environment.baseURL;
  header: any;
  authToken: any;

  constructor(private http: HttpClient) {}

  getOrderHistoryList(pageNo, pageSize, searchString, plant, orderStatus, orderType, startDate, endDate, retailerId, plantModel, plantRegion): Observable<any> {
    let filterUrl = ""
    if(plant) {
      filterUrl = `&plantList=${plant}`;
    }
    if(orderStatus) {
      filterUrl = filterUrl + `&statusList=${orderStatus}`;
    }
    if(orderType) {
      filterUrl = filterUrl + `&orderType=${orderType}`;
    }
    if(startDate) {
      filterUrl = filterUrl + `&startDate=${startDate}`;
    }
    if(endDate) {
      filterUrl = filterUrl + `&endDate=${endDate}`;
    }
    if(plantModel) {
      filterUrl = filterUrl + `&plantModelList=${plantModel}`;
    }
    if(plantRegion) {
      filterUrl = filterUrl + `&plantRegionList=${plantRegion}`;
    }
    if(retailerId){
      filterUrl = filterUrl + `&retailerId.equals=${retailerId}`;
    }
    const orderListURL = this.baseURL + "retailers" + "/" + "order" + "/" + "list" + `?searchKey=${searchString}&` + "sort=createdDate,desc" + "&" + `page=${pageNo}` + "&" + `size=${pageSize}` + filterUrl
    + `&pendingAllowed=${AuthenticationHelper.checkPendingOrdersViewAccess()}`;
    return this.http.get(orderListURL, {
      headers: createHeader(),
      observe: "response"
    });
  }

  getOrderDetail(id): Observable<any> {
    // http://13.126.105.177/api/retailer/order/11
    const orderDetailURL = this.baseURL + "retailer" + "/" + "order" + "/" + id;
    return this.http.get(orderDetailURL, {
      headers: createHeader(),
      observe: "response"
    });
  }

  getOrderDistrict (): Observable<any> {
    const orderDistrictURL = `${this.baseURL}retailers/orders/allDistricts`;
    return this.http.get(orderDistrictURL, {
      headers: createHeader(),
      observe: "response"
    });
  }

  createOrder(data): Observable<any> {
    // http://13.126.105.177/api/retailer/sales/order
    const orderURL = this.baseURL + "retailer" + "/" + "sales" + "/" + "orders";
    return this.http.put(orderURL, data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  markPreInvoiced(data): Observable<any> {
    const preInvoiceURL = this.baseURL + "retailers/orders/pre-invoice";
    return this.http.post(preInvoiceURL, data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  exportOrderDeatils(data): Observable<any> {
    // /retailers/orders/export
    const exportUrl =
      this.baseURL + "retailers" + "/" + "orders" + "/" + "export"
      + `?pendingAllowed=${AuthenticationHelper.checkPendingOrdersViewAccess()}`;
    return this.http.post(exportUrl, data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  orderStatusImport(formData): Observable<any> {
    const statusImportURL =
      this.baseURL + "retailers" + "/" + "orders" + "/" + "status" + "/" + "import";
    return this.http.post(statusImportURL, formData, {
      headers: createTokenHeader(),
      observe: "response"
    });
  }

  uploadInvoice(formData, id) {
    console.log(id);
    // /retailer/order/{id}/invoice/upload   POST
    const invoiceUploadURL =
      this.baseURL + "retailer" + "/" + "order" + "/" + `${id}` + "/" + "invoice" + "/" + "upload";
    return this.http.post(invoiceUploadURL, formData, {
      headers: createTokenHeader(),
      observe: "response"
    });
  }

  getFAList(enterpriseId) {
    const faUrl = this.baseURL + `fa/list/enterprise/${enterpriseId}`
    return this.http.get(faUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  getAllPlantNames() {
    const plantListUrl = this.baseURL + "plants";
    return this.http.get(plantListUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  cancelOrder(data): Observable<any> {
    const orderURL = this.baseURL + "retailers/orders/cancel";
    return this.http.post(orderURL, data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  cancelOrderV2(data): Observable<any> {
    const orderURL = this.baseURL + "retailers/orders/cancel/v2";
    return this.http.post(orderURL, data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  returnCompleteOrder(data): Observable<any> {
    const returnCompleteURL = this.baseURL + "retailers/orders/return";
    return this.http.post(returnCompleteURL, data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  orderActionDocumentUpload(mediaPayload) : Observable<any> {
    const orderURL = this.baseURL + "retailer/order/cancel/doc/upload";
    return this.http.post(orderURL, mediaPayload, {
      headers: createTokenHeader(),
      observe: "response"
    });
  }

  updateOrderStatus(data): Observable<any> {
    return this.http.post(this.baseURL + "retailers/ordersStatus/update", data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  getOrderFailureResponse(orderId): Observable<any> {
    const orderUrl = this.baseURL + `payment/failure/reason/${orderId}`;
    return this.http.get(orderUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  orderTrigger(data): Observable<any> {
    return this.http.put(this.baseURL + "retailer/sales/orders/retryCount", data, {
      headers: createHeader(),
      observe: "response"
    });
  }

  getOrderSAPResponse(orderId): Observable<any> {
    const orderUrl = this.baseURL + `order/sap-response?id=${orderId}`;
    return this.http.get(orderUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  orderDeliveredStatusImport(formData): Observable<any> {
    const statusImportURL = this.baseURL + 'retailer/order/delivery/import';
    return this.http.post(statusImportURL, formData, {
      headers: createTokenHeader(),
      observe: "response"
    });
  }

  getAllPaymentMethods(appOrderId) {
    const paymentUrl = this.baseURL + `payment/v2/transactions?appOrderId=${appOrderId}`
    return this.http.get(paymentUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  eddDelayImport(formData): Observable<any> {
    const statusImportURL = this.baseURL + 'edd-delay/import';
    return this.http.post(statusImportURL, formData, {
      headers: createTokenHeader(),
      observe: "response"
    });
  }

  getRetailerInfo(id: any): Observable<any> {
    const retailerDetailUrl = this.baseURL + "retailers" + "/" + id;
    return this.http.get(retailerDetailUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  readyToDispatchOrdersApiRequest(idArray): Observable<any>  {
    const readyToDispatchOrderUrl = this.baseURL + 'retailer/sales/orders/mark/rtd';
    return this.http.post(readyToDispatchOrderUrl, idArray, {
      headers: createTokenHeader(),
      observe: "response"
    });
  }

  getAllPlantRegions() {
    const plantRegionUrl = this.baseURL + "regions";
    return this.http.get(plantRegionUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  getAllPlantModels() {
    const plantModelUrl = this.baseURL + "warehouseModels";
    return this.http.get(plantModelUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  getOrderStatusCount() {
    const orderStatusUrl = this.baseURL + "retailers/orders/all-status/counts"
    + `?pendingAllowed=${AuthenticationHelper.checkPendingOrdersViewAccess()}`;
    return this.http.get(orderStatusUrl, {
      headers: createHeader(),
      observe: "response"
    });
  }

  isOrderExpiryExtended(id: any) {
    // "retailers/orders/{id}/isExpiryExtended"
    const expiryExtendedUrl = this.baseURL + "retailer/order" + "/" + id + "/" + "isExpiryExtended";
    return this.http.get(expiryExtendedUrl,{
      headers: createHeader(),
      observe: "response"
    })
  }

  getAllOrdersForMasterId(masterId: any, pageNo: number, pageSize: number) {
    // "v4/retailer/orders"
    const ordersMasterIdUrl = this.baseURL + "v4/retailer/orders"
    const queryParams = `?masterAppOrderId=${masterId}&sort=createdDate,asc&page=${pageNo}&size=${pageSize}`
    return this.http.get(ordersMasterIdUrl+queryParams, {
      headers: createHeader(),
      observe: "response"
    })
  }

  extendExpiry(data) {
    // "retailer/order/extend/expiry"
    const extendExpiryUrl = this.baseURL + "retailer/order/extend/expiry"
    return this.http.post(extendExpiryUrl, data, {
      headers: createTokenHeader(),
      observe: "response"
    })
  }

  generateInvoice(id): Observable<any> {
    const generateInvoiceUrl = this.baseURL + "seller/order/" + id + "/invoice";
    return this.http.get(generateInvoiceUrl, {
      headers: createTokenHeader(),
      observe: "response"
    })
  }

  getInvoice(id): Observable<any> {
    const getInvoiceUrl = this.baseURL + "seller/order/" + id + "/getInvoiceUrl";
    return this.http.get(getInvoiceUrl, {
      headers: createTokenHeader(),
      observe: "response"
    })
  }

}
