import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ImagePreviewComponent implements OnInit {
  documents: any = [];
  dialogData: any;
  disableNavigation: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
  ) {
    this.dialogData = this.dialog_data.data;
    this.documents = this.dialogData?.docs;
    this.disableNavigation = this.dialogData?.disableNavigation ? true : false;
  }

  ngOnInit(): void {
  }

  onCloseCancel() {
    this.dialogRef.close({ value: "cancel" });
  }
}
