import browser from "browser-detect";
import { AfterViewInit, Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { AuthService } from "@app/services/auth.service";
import { Utility } from "@app/utility/utility"

import {
  ActionAuthLogin,
  ActionAuthLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated,
  NotificationService
} from "@app/core";
import { environment as env } from "@env/environment";

import {
  ActionSettingsChangeLanguage,
  ActionSettingsChangeAnimationsPageDisabled,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
} from "@app/settings";
import { Router } from "@angular/router";
import { EventsService } from "@app/events/events.service";
import { AuthenticationHelper } from './helpers/authentication';
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { PopUpComponent } from "@app/pop-up/pop-up.component";
import { NavItem } from './modals/nav-item.modal';
import {NavService} from './menu-list-item/service/nav.service';
import { customEvents, logEvent } from "./analytics/custom-events";
import { getPerformance } from "@angular/fire/performance";
// import {FestiveAnnouncementComponent} from '@app/common-popup/festive-announcement/festive-announcement.component'
@Component({
  selector: "anms-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('appDrawer', { static: true }) appDrawer;
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = "../assets/icons/sellerFirst/sellerFirstLogo.svg";
  authUser: any;
  roles = [];
  isPartnerPage: boolean;
  dialogConfig = new MatDialogConfig();
  sidebar: boolean = true;
  prospectSeller = false;

  navigation = [
    { link: "dashboard", label: "Dashboard" },
    { link: "retailers", label: "Retailers" },
    { link: "tutorials", label: "Help" },
    // { link: "product-management", label: "Products" },
    { link: 'price-import', label: "Price Management" },
    { link: 'inventory-import', label: "Inventory Management" },
    { link: "orders", label: "Order History " },
    // { link: "review-screen", label: "Reviews" },
    { link: "FA", label: "FA" },
    { link: "banner-images", label: "Banner Images" },
    { link: "delivery", label: "Delivery" },

    { link: "delivery-partners", label: "Delivery Partners" },
    { link: "delivery-schedules", label: "Delivery Schedules" },
    { link: "payout-dashboard", label: "Payments"}
    // { link: "territory-manager", label: "Territory Manager" },
    // { link: "regional-manager", label: "Regional Manager" },
    // { link: "zonal-manager", label: "Zonal Manager" },
    // { link: "country-manager", label: "Country Manager" },

    // { link: 'customers', label: 'Customers' },
    // { link: 'projects', label: 'Projects' },
    // { link: 'quotes', label: 'Quotes' }
  ];
  admin_navigation = [];
  dashboard_user_navigation = [
    { link: "dashboard", label: "Dashboard" },
    { link: "retailers", label: "Retailers" },
    { link: "orders", label: "Order History" }
  ];

  navigationSideMenu: NavItem[] = [];


  isAuthenticated$: Observable<boolean>;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;
  appHeader: String = "";
  constructor(
    private store: Store<AppState>,
    private storageService: LocalStorageService,
    private router: Router,
    private events: EventsService,
    private auth: AuthService,
    public dialog: MatDialog,
    public notification: NotificationService,
    private navService: NavService,
    private utility: Utility
  ) {
    // Initialize Performance Monitoring
    getPerformance();
    this.events.appHeader.subscribe(item => {
      if (item) {
        this.appHeader = item;
      }
    });

    this.getAuthUser();
    this.events.updateMenu.subscribe(item => {
      if (item) {
        this.getAuthUser();
        this.isPartnerPage = AuthenticationHelper.isPartnerUser();
        this.prospectSeller = AuthenticationHelper.checkIfProspectSeller();
      }
    });
  }
  private static isIEorEdgeOrSafari() {
    return ["ie", "edge", "safari"].includes(browser().name);
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }
    this.utility.checkForUpdateAgreement();
    this.isPartnerPage = AuthenticationHelper.isPartnerUser();
    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
    this.prospectSeller = AuthenticationHelper.checkIfProspectSeller();
  }

  ngAfterViewInit(): void {
    this.navService.appDrawer = this.appDrawer;
    this.events.appHeader.subscribe(item => {
      if (item) {
        this.appHeader = item;
      }
    });

    this.events.updateMenu.subscribe(item => {
      if (item) {
        this.getAuthUser();
        this.isPartnerPage = AuthenticationHelper.isPartnerUser();
        this.prospectSeller = AuthenticationHelper.checkIfProspectSeller();
      }
    });
  }

  onProfileClick() {
    this.store.dispatch(new ActionAuthLogin());
    this.router.navigate(["profile"]);
  }

  onLoginClick() {
    this.store.dispatch(new ActionAuthLogin());
    this.router.navigate(["customer"]);
    logEvent(customEvents.USER_LOGGED_IN)
  }

  onAgreementClick() {
    const sellerType = localStorage.getItem("sellerType")
    if(this.dialog.openDialogs.length === 0) {
      this.utility.openSellerAgreementPopup(sellerType=="null" ? "showBoth" : sellerType, false, true);
    }
  }

  onLogoutClick() {
    let data = {
      deviceOs: 'Desktop'
    }
    this.dialog.closeAll();
    sessionStorage.removeItem("agreementAccepted")
    logEvent(customEvents.USER_CLICKED_LOG_OUT)
    this.auth.logout(data).subscribe(response => {
          if (response && response.status === 200) {
            logEvent(customEvents.USER_LOGGED_OUT)
            this.store.dispatch(new ActionAuthLogout());
            const signInPageRoute = AuthenticationHelper.isOKTAUser() ? "admin-sign-in" : "sign-in";
            localStorage.clear();
            this.router.navigate([signInPageRoute]);
          }
        },
        err => this.utility.handleError(err));
  }

  roleBaseNavigation(){
    this.utility.roleBaseNavigation();
  }

  setSidebarData() {
    this.admin_navigation = [
      { link: "seller-dashboard", icon: "assets/icons/dashboard/home.svg", label: "Home", viewAccess: "DASHBOARD_VIEW", editAccess: "DASHBOARD_VIEW", isExpand: false },
      { link: "", icon: "assets/icons/dashboard/orders.svg", label: "Orders", isExpand: false, 
        children: [
          { link: "orders", label: "My Orders", viewAccess: "ORDER_HISTORY_VIEW", editAccess: "ORDER_HISTORY_EDIT"},
          { link: "delivery-partners", label: "Delivery Partners", viewAccess: "DELIVERY_PARTNER_VIEW", editAccess: "DELIVERY_PARTNER_EDIT" },
          { link: "cancellation-failure", label: "Cancellation Failure", viewAccess: "RETAILER_VIEW", editAccess: "RETAILER_VIEW"},
          { link: "delivery-schedules", label: "Schedule and Dispatch", viewAccess: "DELIVERY_SCHEDULE_VIEW", editAccess: "DELIVERY_SCHEDULE_EDIT" },
        ]
      },
      { link: '', icon: "assets/icons/dashboard/catalogue.svg", label: "Catalogue", isExpand: false,
        children: [
          { link: "master-catalogue", label: "Master Catalogue", viewAccess: "PRODUCT_VIEW", editAccess: "PRODUCT_EDIT"},
          { link: "my-catalogue", label: "My Catalogue", viewAccess: "MY_CATALOG_EDIT", editAccess: "MY_CATALOG_EDIT"},
          { link: "product-company", label: "Product's Company", viewAccess: "PRODUCT_VIEW", editAccess: "PRODUCT_EDIT"},
        ]
      },
      { link: "payout-dashboard", icon: "assets/icons/dashboard/payment.svg", label: "Payments", viewAccess: "PAYOUT_DASHBOARD_VIEW", editAccess: "PAYOUT_DASHBOARD_VIEW", isExpand: false},
      { link: '', icon: "assets/icons/dashboard/inventory.svg", label: "Inventory", isExpand: false,
        children: [
          { link: 'inventory-import', label: "My Inventory", viewAccess: "INVENTORY_MANAGEMENT_VIEW", editAccess: "INVENTORY_MANAGEMENT_EDIT" },
          { link: 'max-orderable-qty', label: "Max Orderable Qty", viewAccess: "MAX_ORDERABLE_QTY_VIEW", editAccess: "MAX_ORDERABLE_QTY_EDIT" },
          { link: 'min-orderable-qty', label: "Min Orderable Qty", viewAccess: "MOQ_VIEW", editAccess: "MOQ_EDIT" },
        ]
      },
      { link: '', icon: "assets/icons/dashboard/pricing.svg", label: "Pricing", isExpand: false,
        children: [
          { link: 'price-import', label: "My Pricing", viewAccess: "PRICE_MANAGEMENT_VIEW", editAccess: "PRICE_MANAGEMENT_EDIT" },
          { link: 'commission', label: "Commission", viewAccess: "COMMISSION_VIEW", editAccess: "COMMISSION_EDIT" },
          { link: 'min-cart-limit', label: "Min Cart Limit", viewAccess: "MIN_CART_LIMIT_VIEW", editAccess: "MIN_CART_LIMIT_EDIT" },
          { link: 'credit-period', label: "Credit Period", viewAccess: "PRICE_MANAGEMENT_VIEW", editAccess: "PRICE_MANAGEMENT_EDIT" },
        ]
      },
      { link: "", icon: "assets/icons/dashboard/promotions.svg", label: "Promotion", isExpand: false, 
        children: [
          { link: "banner-images", label: "Banners", viewAccess: "BANNER_IMAGES_VIEW", editAccess: "BANNER_IMAGES_EDIT"},
          { link: "discounts", label: "Discounts", viewAccess: "SKU_DISCOUNT_DETAILS_VIEW", editAccess: "SKU_DISCOUNT_DETAILS_EDIT", accessRoles: ["BULK_DISCOUNT_VIEW", "BULK_DISCOUNT_EDIT"]},
        ]
      },
      { link: "", icon: "assets/icons/dashboard/customer.svg", label: "Retailers", isExpand: false, 
        children: [
          { link: "retailers", label: "My Retailers", viewAccess: "RETAILER_VIEW", editAccess: "RETAILER_EDIT" },
          { link: 'kyc-verification', label: "KYC Verification", viewAccess: "KYC_VERIFICATION", editAccess: "KYC_VERIFICATION" },
          // { link: "sap-failure-retailers", label: "SAP Failure Retailers", viewAccess: "RETAILER_VIEW", editAccess: "RETAILER_VIEW" },
          { link: "block-unblock-retailers", label: "Block/Unblock", viewAccess: "BLOCK_UNBLOCK_RETAILER_VIEW", editAccess: "BLOCK_UNBLOCK_RETAILER_EDIT"},
          { link: "cash-collection", label: "Cash Collection", viewAccess: "CASH_COLLECTION_VIEW", editAccess: "CASH_COLLECTION_VIEW"},
          { link: "reward-points", label: "Reward Points", viewAccess: "REWARD_POINTS_VIEW", editAccess: "REWARD_POINTS_EDIT" },
          { link: "uid-code", label: "Farmer Scans", viewAccess: "FARMER_CONNECT_VIEW", editAccess: "FARMER_CONNECT_VIEW" },
        ]
      },
      {
        link: "", icon: "assets/icons/dashboard/insights.svg", label: "Business Insights", isExpand: false,
        children: [
          { link: 'inventory-replenishment', label : "Demand Planning", viewAccess: "REPLENISHMENT_INVENTORY_VIEW", editAccess: "REPLENISHMENT_INVENTORY_EDIT" },
          { link: "order-pendency", label: "Order Pendency", openAccess: true},
        ]
      },
      {
        link: "", icon: "assets/icons/dashboard/profile.svg", label: "Profile", isExpand: false,
        children: [
          { link: "enterprise", label: "Company Details", viewAccess: "ENTERPRISE_MANAGEMENT_EDIT", editAccess: "ENTERPRISE_MANAGEMENT_EDIT"},
          { link: "plant", label: "Plant", viewAccess: "ENTERPRISE_MANAGEMENT_EDIT", editAccess: "ENTERPRISE_MANAGEMENT_EDIT"},
          { link: "holidays", label: "Holidays", viewAccess: "EDD_VIEW", editAccess: "EDD_EDIT" },
          { link: "processing-time", label: "Processing Time", viewAccess: "EDD_VIEW", editAccess: "EDD_EDIT" }
        ]
      },
      {
        link: "", icon: "assets/icons/dashboard/admin.svg", label: "Admin", isExpand: false,
        children: [
          { link: "users", label: "Users", viewAccess: "USER_VIEW", editAccess: "USER_EDIT"},
          { link: "prospect-seller", label: "Prospect Seller", viewAccess: "ENTERPRISE_MANAGEMENT_EDIT", editAccess: "ENTERPRISE_MANAGEMENT_EDIT"},
          { link: "FA", label: "FA Pincode Mapping", viewAccess: "FA_VIEW", editAccess: "FA_EDIT"},
        ]
      },
      { link: "ananya-finance", icon: "assets/icons/dashboard/ananya-finance.svg", label: "Ananya Finance", isExpand: false, viewAccess: "AGIF_ADMIN", editAccess: "AGIF_ADMIN"},
      { link: "", icon: "assets/icons/dashboard/support_agent.svg", label: "SellerCare", isExpand: false,
        children: [
          { link: "tutorials", label: "Tutorials", openAccess: true},
          { link: "faqs", label: "FAQs", openAccess: true},
        ]
      }
      // { link: "product-management", label: "Products", viewAccess: "PRODUCT_VIEW", editAccess: "PRODUCT_EDIT" },
    ];
  }

  getAuthUser() {
    let a_user = JSON.parse(localStorage.getItem("authUser"));
    if (a_user !== null) {
      if (a_user.userName) {
        this.authUser = a_user.userName;
      } else {
        this.authUser = 'Dear Admin';
      }
      this.roles = [...a_user.role]
    }
    this.getRolebasedSidebar(this.roles)
  }

  getRolebasedSidebar(role) {
    this.setSidebarData();
    if (role.includes('ADMIN')) {
      if(!role.includes('KYC_VERIFICATION')) {
        this.admin_navigation[6].children.splice(1,1); 
      }
      this.navigationSideMenu = [...this.admin_navigation];
    } else {
      let filterParentOptions = [];
      this.admin_navigation.forEach(data => {
        if(data.children) {
          let filterChildOptions = [];
          data.children.forEach(childData => {
            const accessRoleBool = childData?.accessRoles && role.some(item => childData?.accessRoles.includes(item));
            if(role.includes(childData.viewAccess) || role.includes(childData.editAccess) || childData.openAccess || accessRoleBool) {
              filterChildOptions.push(childData);
            }
          })
          if(filterChildOptions.length) {
            data.children = filterChildOptions;
            // if(data.label === 'Retailers' || data.label === 'Estimated Date Of Delivery') {
            //     if(filterChildOptions.length === 1) {
            //       data.link = data.children[0].link;
            //       delete data.children;
            //     }
            // }
            filterParentOptions.push(data);
          }
        } else {
          if(role.includes(data.viewAccess) || role.includes(data.editAccess) || data.openAccess) {
            filterParentOptions.push(data);
          }
        }
      });
      this.admin_navigation = filterParentOptions;
      this.navigationSideMenu = [...this.admin_navigation];
    }
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
  }

  reloadComponent(uri) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri]);
    });
  }

  onSidebarChange(sidebar: boolean) {
    this.sidebar = sidebar;
  }

  /* Note: Can be used in future 
  
  festiveAnnouncement() {
    this.dialogConfig.data = {
      videoId: 's5LCFjDzLq4'
    };
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(FestiveAnnouncementComponent, {
        data: this.dialogConfig,
        panelClass: "custom-dialog-container",
        disableClose: false,
        hasBackdrop: true,
        minHeight: "480px",
        minWidth: "60%"
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          if (result.value === "visited") {
            localStorage.setItem('showFestiveAnnouncement', "true")
          }
        }
      })
    }
  } 

  */
}
