import { Injectable } from "@angular/core";
import { SpinnerService } from '@app/services/spinner.service';
import { NotificationService, AppState, ActionAuthLogout } from "@app/core";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { PopUpComponent } from "@app/pop-up/pop-up.component";
import { SellerAgreementModalComponent } from "@app/common-popup/seller-agreement-modal/seller-agreement-modal.component";
import { Observable, Observer, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TermsAndConditionModalComponent } from '@app/common-popup/terms-and-condition-modal/terms-and-condition-modal.component';
import {AuthService} from '@app/services/auth.service'
import { ActionAuthLogin } from "@app/core";
import { EventsService } from "@app/events/events.service";
import { AuthenticationHelper } from "@app/helpers/authentication";
import { LOCAL_STORAGE_KEYS, S3_RETAIL_APP_URL, S3_RETAIL_APP_URL_STAGE } from "./constants";
import { customEvents, logEvent } from '../analytics/custom-events';
import { environment as env} from "@env/environment";

export interface authUser {
  firstName: string;
  lastName: string;
  mobile: any;
  email: string;
  appRoles: any[];
  id: any;
}

@Injectable({
  providedIn: "root"
})



export class Utility {
  dialogConfig = new MatDialogConfig();
  authUser: authUser;
  enterpriseIds = [];
  updateChecked: boolean = false;
  updateAgreementMultiple = false;
  sellerTypeMultiple: string;
  enterpriseIdsToSign = [];
  refreshTokenReq: any;
  constructor(
    private spinner: SpinnerService,
    private notification: NotificationService,
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private events: EventsService,
  ) { }

  /*
    Method to change the base64 data to blob data
  */
  static base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "xls" });
  }

  /*
    Method to previewing the error in the toaste through out
    the application
  */
  commonError(err) {
    console.log("errrr", err);
    if (err && err.error && err.error.error && err.error.error.code === -104) {
      this.notification.error("Unauthorized user");
      this.store.dispatch(new ActionAuthLogout());
      localStorage.clear();
      this.router.navigate(["registration"]);
    } else if (
      err &&
      err.error &&
      err.error.error &&
      err.error.error.code === -102
    ) {
      this.notification.error("Unauthorized user");
      this.store.dispatch(new ActionAuthLogout());
      localStorage.clear();
      this.router.navigate(["registration"]);
    } else if (err && err.error && err.error.error && err.error.error.message) {
      this.notification.error(err.error.error.message);
    } else if (err && err.error && err.error.status === 400) {
      this.notification.error(`Unauthorized user, ${err.error.title}`);
      this.dialogConfig.data = {
        title: `Unauthorized user, ${err.error.title}`,
        text: "Please login to continue",
        height: "400px",
        width: "600px",
        id: "unauthorized",
        showCancelButton: false,
        confirmButtonText: "Ok"
      };
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: this.dialogConfig
      });
      dialogRef.afterClosed().subscribe((result) => {
        // this.notification.error(`Unauthorized user, ${err.error.title}`);
        if (result.value === 'ok') {
          this.store.dispatch(new ActionAuthLogout());
          const signInPageRoute = AuthenticationHelper.isOKTAUser() ? "admin-sign-in" : "sign-in";
          localStorage.clear();
          this.router.navigate([signInPageRoute]);
          // window.location.reload();
        }
      })

      // this.router.navigate([" sign-in"]);
    } else if (err && err.error && err.error.status === 401) {
      this.dialogConfig.data = {
        title: "Unauthorized user",
        text: "Please login to continue",
        height: "400px",
        width: "600px",
        id: "unauthorized",
        showCancelButton: false,
        confirmButtonText: "Ok"
      };
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: this.dialogConfig
      });
      dialogRef.afterClosed().subscribe((result) => {
        // this.notification.error(`Unauthorized user, ${err.error.title}`);
        if (result.value === 'ok') {
          this.store.dispatch(new ActionAuthLogout());
          const signInPageRoute = AuthenticationHelper.isOKTAUser() ? "admin-sign-in" : "sign-in";
          localStorage.clear();
          this.router.navigate([signInPageRoute]);
          // window.location.reload();
        }
      })
    }
    // else if (err && err.error && err.error.error && err.error.error.code === -500) {
    //   this.notification.error('Inetrnal Server Error');
    //   this.store.dispatch(new ActionAuthLogout());
    //   localStorage.clear();
    //   this.router.navigate(['registration']);
    // }
    this.spinner.showSpinner.emit(false);
  }

  /*
   Method to handle application error
   */

  handleError(err) {
    this.spinner.showSpinner.emit(false);
    if (err && (err.status === 401 || err.status === 0)) {
      this.handleRefreshTokenError();
    } else if(err.status === 403) {
      this.notification.error(err.error.detail ? err.error.detail : "Access is denied.");
    } else if(err.status === 400) {
      this.notification.error(err.error.title)
    } else if(err.status === 424) {
      this.notification.error(err.error.detail)
    } else if(err.status === 500 || err.status === 503 || err.status === 404) {
      this.notification.error("Something went wrong.")
    } else {
      this.isConnectionExist().subscribe(isOnline => {
        if(!isOnline) {
          this.notification.error("No Internet Connection.");
        }
      })
    }
  }

  handleRefreshTokenError() {
    if (this.dialog.openDialogs.length === 0) {
      this.dialogConfig.data = {
        title: "Unauthorized User, Session Expired",
        text: "Please login to continue",
        height: "400px",
        width: "600px",
        id: "unauthorized",
        showCancelButton: false,
        confirmButtonText: "Ok"
      };
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: this.dialogConfig,
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.value === "ok") {
          this.store.dispatch(new ActionAuthLogout());
          const signInPageRoute = AuthenticationHelper.isOKTAUser() ? "admin-sign-in" : "sign-in";
          localStorage.clear();
          this.router.navigate([signInPageRoute]);
        }
      });
    }
  }

  isConnectionExist() {
    return merge(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
          sub.next(navigator.onLine);
          sub.complete();
        }));
  }

  setTncStatus(authUser) {
    return new Promise((resolve, reject) => {
      this.auth.setTncStatus({ id: authUser.tncId }).subscribe(
        (res) => {
          authUser.token = res.body.token;
          authUser.tncStatus = false;
          localStorage.setItem("authUser", JSON.stringify(authUser));
          resolve("TNC_ACCEPTED");
        },
        (err) => this.handleError(err)
      );
    });
  }
  /**Function to show seller T&C popup**/

  isTermsAndConditionAccpted() {
    return new Promise((resolve, reject) => {
      let authUser = JSON.parse(localStorage.getItem("authUser"));
      const isTermsAndConditionsAcceptedAtSignUp = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.isTermsAndConditionsAcceptedAtSignUp)
      );
      if (isTermsAndConditionsAcceptedAtSignUp && authUser.tncStatus) {
        this.setTncStatus(authUser).then(() => {
          resolve("TNC_ACCEPTED");
        });
      } else if (authUser.tncStatus) {
        const dialogRef = this.dialog.open(TermsAndConditionModalComponent, {
          disableClose: true,
          hasBackdrop: true,
          panelClass: ["custom-dialog-container", "revamp-dialog-container"],
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result.value === "Accepted") {
            this.setTncStatus(authUser).then(() => {
              resolve("TNC_ACCEPTED");
            });
          }
        });
      } else {
        resolve("TNC_ACCEPTED");
      }
    });
  }

  navigateToSignInPage() {
    if(localStorage.getItem("isOktaUser")) {
      this.router.navigate(["admin-sign-in"]);
    } else {
      this.router.navigate(["sign-in"]);
    }
  }

  openSellerAgreementPopup(sellerType: string, showAcceptButton: boolean = true, showCloseButton: boolean = true) {
    this.dialogConfig= {
      height: Math.min(window.innerHeight,700) + "px",
      width: "1200px",
      disableClose: true,
      hasBackdrop: false,
      closeOnNavigation: false,
      data:{
        title: "Seller Agreement",
        id: "seller_agreement",
        confirmButtonText: "Accept",
        showAcceptButton,
        showCloseButton,
        sellerType
      }
    };
    if (this.dialog.openDialogs.length === 0 && sellerType!== "update_null"){
      logEvent(customEvents.REVIEW_PAGE_SUBMIT)
      const dialogRef = this.dialog.open(SellerAgreementModalComponent, this.dialogConfig);
      return dialogRef;
    }
  }

  login(data) {
    this.updateChecked = false;
    this.spinner.showSpinner.emit(true);
    this.auth.verifyOtpOrToken(data).subscribe(
      res => {
        if(data.appType == "SELLER") {
          localStorage.removeItem("isOktaUser");
        }
        this.getNavigateToApplication(res)
      },
      err => this.handleError(err)
    );
  }

  sellerSignUp(data) {
    this.spinner.showSpinner.emit(true);
    this.auth.sellerSignUp(data).subscribe(
      res => {
        this.spinner.showSpinner.emit(false);
        this.notification.success("Sign up successfully, please login using OTP")
        localStorage.setItem("sellerSignUp", JSON.stringify(res.body));
        this.router.navigate(["sign-in"]);
      },
      err => this.handleError(err)
    );
  }

  getNavigateToApplication(res) {
    const authToken = { token: res.body.token, refreshToken: res.body.refreshToken };
   
      localStorage.setItem("authUser", JSON.stringify(authToken));

      this.auth.checkStatusOfTnc().subscribe(tncData => {
        if (tncData && tncData.status === 200) {

          this.auth.getAuthUser().subscribe(data => {
            this.spinner.showSpinner.emit(false);
            this.notification.success("Login successfully")
            if (data && data.status === 200) {
              this.store.dispatch(new ActionAuthLogin());
              this.authUser = data.body;
              const roles = [];
              this.authUser.appRoles.map(role => roles.push(role.name))
              let userName = "";
              if (this.authUser.firstName) {
                userName = this.authUser.firstName;
              }
              if(this.authUser.lastName) {
                userName = userName + " " + this.authUser.lastName;
              }
              this.enterpriseIds = [];
              data.body.bizUserEnterpriseMappings.map((enterprise: { enterpriseId: any; }) => {
                if(!this.enterpriseIds.includes(enterprise.enterpriseId)){
                  this.enterpriseIds.push(enterprise.enterpriseId);
                }
              });
              logEvent(customEvents.USER_AUTHENTICATE_RESULT, {userName : userName, responseCode : data.status})
              const user = { ...authToken, userName: userName, role: roles, tncStatus: tncData.body.showTncPopUp, tncId: tncData.body.id, userId: data.body.id, enterpriseIds: this.enterpriseIds, email: data.body.email, mobile: data.body.mobile, ugdn: data.body.ugdn };
              if (user && authToken) {
                localStorage.setItem('authUser', JSON.stringify(user));
    
                this.roleBaseNavigation();
                this.events.updateMenu.emit(true);
              }
              this.checkForUpdateAgreement();
            }
          }, err => this.handleError(err))
        }
      }, err => this.handleError(err));
    
    
  }

  checkForUpdateAgreement() {
    if(AuthenticationHelper.isPartnerUser()) {
      return;
    }
    if(this.enterpriseIds.length == 0){
      this.enterpriseIds = JSON.parse(localStorage.getItem("authUser"))?.enterpriseIds;
    } 
    if (this.enterpriseIds?.length > 0 && !this.updateChecked && !sessionStorage.getItem("agreementAccepted")){
      if(this.enterpriseIds.length == 1){
        const enterpriseId = this.enterpriseIds[0];
        this.auth.getEnterpriseDetails(enterpriseId).subscribe(
          res => {
            if(res && res.status == 200){
              this.updateChecked = true;
              localStorage.setItem("sellerType", res.body.businessType || "null");
              if(res.body.updateAgreement && res.body.businessType){
                const dialogRef = this.openSellerAgreementPopup("update_" + res.body.businessType, true, false);
                dialogRef.afterClosed().subscribe((result) => {
                  if (result && result.value === 'accepted') {
                    this.auth.signAgreement(enterpriseId).subscribe(res => {
                      sessionStorage.setItem("agreementAccepted", "true")
                    });
                    logEvent(customEvents.UPDATE_SELLER_AGREEMENT_ACCEPTED, {sellerType: res.body.businessType})
                  }
                })
              }
            }
          },
          err => {
            this.handleError(err)
          }
        )
      }
      else {
        this.checkForUpdateAgreementMultipleEnterprise()
      }
    }
  }

  checkForUpdateAgreementMultipleEnterprise() {
    const length = this.enterpriseIds.length;
    let count = 0;
    const responses = [];
    this.enterpriseIds.forEach(id => {
      this.auth.getEnterpriseDetails(id).subscribe(
        res => {
          if(res && res.status == 200) {
            responses.push(res.body);
            count+=1;
            if(count == length) {
              this.calculateFlagAndSellerType(responses);
              return;
            }
          }
          else {
            count+=1
          }
        }
      )
    })
  }

  calculateFlagAndSellerType(responses) {
    responses.forEach(res => {
      if(res.updateAgreement){
        this.enterpriseIdsToSign.push(res.id);
        this.updateAgreementMultiple = true;
        if(res.businessType){
          this.sellerTypeMultiple = res.businessType;
        }
      }

    });
    localStorage.setItem("sellerType", this.sellerTypeMultiple || "null");
    if(this.updateAgreementMultiple && this.sellerTypeMultiple){
      const dialogRef = this.openSellerAgreementPopup("update_" + this.sellerTypeMultiple, true, false);
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.value === 'accepted') {
          this.signMultipleAgreementIds();
          logEvent(customEvents.UPDATE_SELLER_AGREEMENT_ACCEPTED, {sellerType: this.sellerTypeMultiple})
        }
      })
    }
    
  }

  signMultipleAgreementIds(){
    this.enterpriseIdsToSign.forEach(id => {
      this.auth.signAgreement(id).subscribe(res => {
      });
    })
    sessionStorage.setItem("agreementAccepted", "true");
    this.updateChecked = true;
  }

  roleBaseNavigation() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      if(authUser.role.includes('DASHBOARD_VIEW') || authUser.role.includes('DASHBOARD_EDIT')) {
        this.router.navigate(["seller-dashboard"]);
      } else if(authUser.role.includes('ORDER_HISTORY_VIEW') || authUser.role.includes('ORDER_HISTORY_EDIT')) {
        this.router.navigate(["orders"]);
      } else if (authUser.role.includes('DELIVERY_PARTNER')) {
        this.router.navigate(["partner-dashboard"]);
      } else if(authUser.role.includes('ADMIN') || authUser.role.includes('RETAILER_VIEW') || authUser.role.includes('RETAILER_EDIT')) {
        this.router.navigate(["retailers"]);
      } else if(authUser.role.includes('KYC_VERIFICATION')) {
        this.router.navigate(["kyc-verification"]);
      } else if(authUser.role.includes('PRODUCT_VIEW') || authUser.role.includes('PRODUCT_EDIT')){
        this.router.navigate(["product-management"]);
      } else if(authUser.role.includes('PRICE_MANAGEMENT_VIEW') || authUser.role.includes('PRICE_MANAGEMENT_EDIT')) {
        this.router.navigate(["price-import"]);
      } else if(authUser.role.includes('MIN_CART_LIMIT_VIEW') || authUser.role.includes('MIN_CART_LIMIT_EDIT')) {
        this.router.navigate(["min-cart-limit"]);
      } else if(authUser.role.includes('COMMISSION_VIEW') || authUser.role.includes('COMMISSION_EDIT')) {
        this.router.navigate(["commission"]);
      } else if(authUser.role.includes('INVENTORY_MANAGEMENT_VIEW') || authUser.role.includes('INVENTORY_MANAGEMENT_EDIT')) {
        this.router.navigate(["inventory-import"]);
      } else if(authUser.role.includes('MAX_ORDERABLE_QTY_VIEW') || authUser.role.includes('MAX_ORDERABLE_QTY_EDIT')) {
        this.router.navigate(["max-orderable-qty"]);
      } else if(authUser.role.includes('MOQ_VIEW') || authUser.role.includes('MOQ_EDIT')) {
        this.router.navigate(["min-orderable-qty"]);
      } else if(authUser.role.includes('REPLENISHMENT_INVENTORY_VIEW') || authUser.role.includes('REPLENISHMENT_INVENTORY_EDIT')) {
        this.router.navigate(["inventory-replenishment"]);
      } else if(authUser.role.includes('CASH_COLLECTION_VIEW')) {
        this.router.navigate(["cash-collection"]);
      } else if(authUser.role.includes('BLOCK_UNBLOCK_RETAILER_VIEW') || authUser.role.includes('BLOCK_UNBLOCK_RETAILER_EDIT')) {
        this.router.navigate(["block-unblock-retailers"]);
      } else if(authUser.role.includes('FA_VIEW') || authUser.role.includes('FA_EDIT')) {
        this.router.navigate(["FA"]);
      } else if(authUser.role.includes('BANNER_IMAGES_VIEW') || authUser.role.includes('BANNER_IMAGES_EDIT')) {
        this.router.navigate(["banner-images"]);
      } else if(authUser.role.includes('DELIVERY_PARTNER_VIEW') || authUser.role.includes('DELIVERY_PARTNER_EDIT')) {
        this.router.navigate(["delivery-partners"]);
      } else if(authUser.role.includes('DELIVERY_SCHEDULE_VIEW') || authUser.role.includes('DELIVERY_SCHEDULE_EDIT')) {
        this.router.navigate(["delivery-schedules"]);
      } else if(authUser.role.includes('REWARD_POINTS_VIEW') || authUser.role.includes('REWARD_POINTS_EDIT')) {
        this.router.navigate(["reward-points"]);
      } else if(authUser.role.includes('USER_VIEW') || authUser.role.includes('USER_EDIT')) {
        this.router.navigate(["users"]);
      } else if(authUser.role.includes('FARMER_CONNECT_VIEW')) {
        this.router.navigate(["uid-code"]);
      } else if(authUser.role.includes('ENTERPRISE_MANAGEMENT_EDIT')) {
        this.router.navigate(["enterprise"]);
      } else if(authUser.role.includes('EDD_VIEW') || authUser.role.includes('EDD_EDIT')) {
        this.router.navigate(["holidays"]);
      } else if(authUser.role.includes('SKU_DISCOUNT_DETAILS_VIEW') || authUser.role.includes('SKU_DISCOUNT_DETAILS_EDIT')) {
        this.router.navigate(["discounts"]);
      } else if(authUser.role.includes["MY_CATALOG_EDIT"]) {
        this.router.navigate(["my-catalogue"])
      } else if(authUser.role.includes('PROSPECT_SELLER')) {
        this.router.navigate(["seller-onboard"]);
      } else {
        this.router.navigate(["error"]);
      }
    }
  }

  getAllProductsZipS3Url() {
    if (env.envName === "PROD") {
      return S3_RETAIL_APP_URL;
    }
    else {
      return S3_RETAIL_APP_URL_STAGE;
    }
  }
}
 