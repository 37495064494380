import { getAnalytics, logEvent as firebaseLogEvent } from "@angular/fire/analytics";
export function logEvent(eventName : string, eventData?: any) {
  const analytics = getAnalytics(); 
  firebaseLogEvent(analytics, eventName, eventData);
}
export const customEvents = {
  // Sign in flow
  SELLER_LANDING_PAGE: "seller_landing_page",
  START_SELLING_CLICKED_LANDING_PAGE  :"start_selling_clicked_landing_page",
  SIGN_IN_CLICKED_LANDING_PAGE  :"sign_in_clicked_landing_page",
  GET_STARTED_CLICKED_LANDING_PAGE : "get_started_clicked_landing_page",
  GET_STARTED_SIGN_UP_PAGE : "get_started_sign_up_page",
  SIGN_IN_PAGE:"sign_in_page",
  USER_CLICKED_SEND_OTP : "user_clicked_send_otp",
  USER_CLICKED_LOGIN: "user_clicked_login",
  USER_CLICKED_RESEND_OTP : "user_clicked_resend_otp",
  USER_LOGGED_IN : "user_logged_in",
  USER_CLICKED_LOG_OUT : "user_clicked_log_out",
  USER_LOGGED_OUT  : "user_logged_out",
  SIGN_IN_PAGE_CLOSED :"sign_in_page_closed",
  USER_CLICKED_SIGN_UP : "user_clicked_sign_up",
  SELLER_AGREEMENT_ACCEPTED: "seller_agreement_accepted",
  SELLER_AGREEMENT_CLOSED : "seller_agreement_closed",
  UPDATE_SELLER_AGREEMENT_ACCEPTED: "update_seller_agreement_accepted",
  CLOSE_THANK_YOU_PAGE : "close_thank_you_page",

  // common events 
  PAGE_CHANGE: "page_change",
  SHOW_ALL : "show_all",
  FILTER_APPLIED : "filter_applied",
  SEARCH_APPLIED : "search_applied",
  MASTER_TOGGLE : "master_toggle",
  ROW_SELECTED : "row_selected", //
  ROW_UNSELECTED : "row_unselected",//
  DOWNLOAD_IMPORT_FORMAT_FILE : "download_import_format_file", //
  UPLOAD_IMPORT_FILE : "upload_import_file",
  IMPORT_CLICKED : "import_clicked",
  IMPORT_SUBMIT : "import_submit",
  IMPORT_CANCEL : "import_cancel",
  IMPORT_COMPLETED: " import_completed",//
  EXPORT_CLICKED : "export_clicked",
  EXPORT_SUBMIT : "export_submit",
  EXPORT_CANCEL : "export_cancel",
  EXPORT_COMPLETED : "export_completed",
  SUBMIT : "submit",
  CANCEL : "cancel",
  TAB_CHANGE : "tab_change",//

  // Orders page
  ORDERS_PAGE : "orders_page",
  ACTIVE_ORDER_TAB_CHANGED: "active_order_tab_changed",
  CANCEL_ORDER: "cancel_order",
  CANCEL_ORDER_CANCEL : "cancel_order_cancel",
  CANCEL_ORDER_SUBMIT: "cancel_order_submit",
  CHANGE_FA : "change_fa",
  CHANGE_FA_CANCEL : "change_fa_cancel",
  CHANGE_FA_UPDATE : "change_fa_update",
  BULK_MARK_READY_FOR_DISPATCH : "bulk_mark_ready_for_dispatch",
  MARK_READY_FOR_DISPATCH_SINGLE_ORDER : "mark_ready_for_dispatch_single_order",
  MARK_READY_FOR_DISPATCH_SINGLE_ORDER_SUBMIT : "mark_ready_for_dispatch_single_order_submit",
  MARK_READY_FOR_DISPATCH_SINGLE_ORDER_CANCEL : "mark_ready_for_dispatch_single_order_cancel",
  UPLOAD_EDD_DELAY_CLICKED: "upload_edd_delay_clicked",
  UPLOAD_EDD_DELAY_CANCEL : "upload_edd_delay_cancel",
  UPLOAD_EDD_DELAY_SUBMIT : "upload_edd_delay_submit",
  MULTIPLE_ORDERS_EXPORT : "multiple_orders_export",
  UPLOAD_INVOICE : "upload_invoice",
  INVOICE_FILE_UPLOADED : "invoice_file_uploaded",//
  UPLOAD_INVOICE_CANCEL : "upload_invoice_cancel",
  UPLOAD_INVOICE_SUBMIT : "upload_invoice_submit",
  OPEN_ORDER_DETAILS : "open_order_details",

  // SAP Order Cancellation Failure page
  SAP_ORDER_CANCELLATION_FAILURE : "sap_order_cancellation_failure_page",
  
  // Order Details page
  ORDER_DETAILS_PAGE : "order_details_page",
  ORDER_DETAILS_SAVE : "order_details_save",
  ORDER_DETAILS_BACK : "order_details_back",

  // Master Catalogue page
  MASTER_CATALOGUE_PAGE: "master_catalogue_page",
  ADD_PRODUCTS_TO_MY_CATALOG_CLICKED : "add_products_to_my_catalogue_clicked",
  ADD_PRODUCTS_TO_MY_CATALOG_SUBMIT : "add_products_to_my_catalogue_submit",
  ADD_PRODUCTS_TO_MY_CATALOG_CANCEL : "add_products_to_my_catalogue_cancel",
  EDIT_MASTER_CATALOGUE_PRODUCT : "edit_master_catalogue_product",
  VIEW_MASTER_CATALOGUE_PRODUCT : "view_master_catalogue_product",

  // Edit master catalogue
  EDIT_MASTER_CATALOGUE_PAGE : "edit_master_catalogue_page",
  ADD_MEDIA_MASTER_CATALOGUE_PRODUCT : "add_media_master_catalogue_product",
  REMOVE_MEDIA_MASTER_CATALOGUE_PRODUCT : "remove_media_master_catalogue_product",
  EDIT_MASTER_CATALOGUE_SAVE : "edit_master_catalogue_save",
  EDIT_MASTER_CATALOGUE_CLOSE : "edit_master_catalogue_close",

  // My Catalogue page
  MY_CATALOGUE_PAGE : "my_catalogue_page",
  ADD_NEW_PRODUCTS_CTA_CLICKED : "add_new_products_cta_clicked",
  UPLOAD_PRODUCT_DETAILS_CLICKED : "upload_product_details_clicked",
  UPLOAD_PRODUCT_DETAILS_SUBMIT : "upload_product_details_submit",
  UPLOAD_PRODUCT_DETAILS_CANCEL : "upload_product_details_cancel",
  BULK_ADD_TO_MY_CATALOGUE_CLICKED : "bulk_add_to_my_catalogue_clicked",
  BULK_ADD_TO_MY_CATALOGUE_SUBMIT : "bulk_add_to_my_catalogue_submit",
  BULK_ADD_TO_MY_CATALOGUE_CANCEL : "bulk_add_to_my_catalogue_cancel",
  EDIT_MY_CATALOGUE_PRODUCT_CLICKED : "edit_my_catalogue_product_clicked",

  // Edit my catalogue
  EDIT_MY_CATALOGUE_PAGE : "edit_my_catalogue-page",
  UPDATE_PRICE_CLICKED : "update_price_clicked",
  UPDATE_INVENTORY_CLICKED : "update_inventory_clicked",
  EDIT_MY_CATALOGUE_PAGE_CLOSE : "edit_my_catalogue_page_close",

  // My Inventory page
  MY_INVENTORY_PAGE : "my_inventory_page",
  INVENTORY_SYNC : "inventory_sync",
  INVENTORY_SYNC_SUBMIT : "inventory_sync_submit",
  INVENTORY_SYNC_CANCEL : "inventory_sync_cancel",
  EDIT_INVENTORY_CLICKED : "edit_inventory_clicked",

  INVENTORY_EDIT_PAGE : "inventory_edit_page",
  INVENTORY_EDIT_INVENTORY_UPDATE : "inventory_edit_inventory_update",
  INVENTORY_EDIT_CANCEL : "inventory_edit_cancel",

  // Delivery partners page
  DELIVERY_PARTNERS_PAGE : "delivery_partners_page",
  EDIT_DELIVERY_PARTNER_CLICKED : "edit_delivery_partner_clicked",
  UPDATE_DELIVERY_PARTNER_PAGE : "update_delivery_partner_page",
  UPDATE_DELIVERY_PARTNER_UPDATE : "update_delivery_partner_update",
  DEACTIVATE_DELIVERY_PARTNER : "deactivate_delivery_partner",
  DEACTIVATE_DELIVERY_PARTNER_SUBMIT : "deactivate_delivery_partner_submit",
  ADD_DELIVERY_PARTNER_CLICKED : "add_delivery_partner_clicked",
  ADD_DELIVERY_PARTNER_PAGE : "add_delivery_partner_page",
  ADD_DELIVERY_PARTNER_SUBMIT : "add_delivery_partner_submit",
  ADD_DELIVERY_PARTNER_CANCEL : "add_delivery_partner_cancel",

  // Schedule & Dispatch page
  SCHEDULE_AND_DISPATCH_PAGE : "schedule_and_dispatch_page",
  SCHEDULE_ORDER_CTA : "schedule_order_cta",
  SCHEDULE_ORDER_CTA_SUBMIT : "schedule_order_cta_submit",
  ACTIVE_SCHEDULE_AND_DISPATCH_TAB_CHANGED : "active_schedule_and_dispatch_tab_changed",
  UPLOAD_DELIVERED_STATUS_SUBMIT : "upload_delivered_status_submit",
  UPLOAD_DELIVERY_SCHEDULE_SUBMIT : "upload_delivery_schedule_submit",

  // Max orderable quantity page
  MAX_ORDERABLE_QTY_PAGE : "max_orderable_qty_page",
  ADD_NEW_MOQ: "add_new_moq",
  ADD_NEW_MOQ_SUBMIT: "add_new_moq_submit",
  EDIT_MOQ : "edit_moq",
  EDIT_MOQ_SUBMIT : "edit_moq_submit",
  DELETE_MOQ : "delete_moq",
  DELETE_MOQ_SUBMIT : "delete_moq_submit",
  DELETE_MOQ_CANCEL : "delete_moq_cancel",

  // My Pricing Page
  MY_PRICING_PAGE: "my_pricing_page",
  PRICE_SYNC : "price_sync",
  PRICE_SYNC_SUBMIT: "price_sync_submit",
  PRICE_SYNC_CANCEL : "price_sync_cancel",

  // Commission page
  COMMISSION_PAGE: "commission_page",

  // Min Cart Limit
  MIN_CART_LIMIT_PAGE: "min_cart_limit_page",
  MIN_CART_LIMIT_EDIT :"min_cart_limit_edit",
  MIN_CART_LIMIT_EDIT_SUBMIT : "min_cart_limit_edit_submit",
  MIN_CART_LIMIT_EDIT_CANCEL : "min_cart_limit_edit_cancel",

  // Credit Period
  CREDIT_PERIOD_PAGE: "credit_period_page",
  UPLOAD_CREDIT_PERIOD: "upload_credit_period",
  UPLOAD_CREDIT_PERIOD_SUBMIT :"upload_credit_period_submit",
  UPLOAD_CREDIT_PERIOD_CANCEL :"upload_credit_period_cancel",
  CREDIT_PERIOD_EDIT :"credit_period_edit",
  CREDIT_PERIOD_EDIT_SUBMIT: "credit_period_submit",
  CREDIT_PERIOD_EDIT_CANCEL : "credit_period_edit_cancel",

  // My Retailers Page
  MY_RETAILERS_PAGE: "my_retailers_page",
  REFERRER_IMPORT :"referrer_import",
  REFERRER_IMPORT_SUBMIT :"referrer_import_submit",
  REFERRER_IMPORT_CANCEL : "referrer_import_cancel",
  EXPORT_LSMW_MULTIPLE : "export_lsmw",
  EXPORT_LSMW_SINGLE : "export_lsmw_single",
  UPLOAD_RETAILER_CATALOGUE : "upload_retailer_catalogue",
  UPLOAD_RETAILER_CATALOGUE_SUBMIT :"upload_retailer_catalogue_submit",
  UPLOAD_RETAILER_CATALOGUE_CANCEL : "upload_retailer_catalogue_cancel",
  RETAILER_EDIT :"retailer_edit",
  RETAILER_DETAILS: "retailer_details",
  KYC_HISTORY : "kyc_history",
  CREDIT_BLOCK_DETAILS : "credit_block_details",

  // Edit Retailers page
  EDIT_RETAILERS_PAGE : "edit_retailers_page",
  EDIT_RETAILER : "edit_retailer",
  SAVE_PERSONAL_DETAILS : "save_personal_details",
  SAVE_FIRM_DETAILS : "save_firm_details",
  APPROVE_KYC_RETAILER : "approve_kyc_retailer",
  REJECT_KYC_RETAILER : "reject_kyc_retailer",
  REASSIGN_KYC_VERIFIER : "reassign_kyc_verifier",
  PENDING_KYC_RETAILER : "pending_kyc_retailer",
  REASSIGN_KYC_VERIFIER_SUBMIT : "reassign_kyc_verifier_submit",

  // Retailer details page
  RETAILER_DETAILS_PAGE : "retailer_details_page",

  KYC_VERIFICATION_PAGE: "kyc_verification_page",

  SAP_FAILURE_RETAILERS_PAGE : "sap_failure_retailers_page",

  BLOCK_UNBLOCK_RETAILERS_PAGE : "block_unblock_retailers_page",

  CASH_COLLECTION_PAGE : "cash_collection_page",

  FARMER_SCANS_PAGE : "farmer_scans_page",

  DEMAND_PLANNING_PAGE : "demand_planning_page",

  ORDER_PENDENCY_PAGE : "order_pendency_page",

  COMPANY_DETAILS_PAGE : "company_details_page",

  PROSPECT_SELLERS_PAGE : "prospect_sellers_page",

  PLANT_PAGE : "plant_page",

  HOLIDAYS_PAGE : "holidays_page",

  PROCESSING_TIME_PAGE : "processing_time_page",

  CREDIT_BLOCK_DETAILS_PAGE : "credit_block_details_page",
  CREDIT_BLOCK_VIEW_DETAILS : "credit_block_view_details",

  SKU_DISCOUNT_PAGE : "sku_discount_page",
  EDIT_SKU_DISCOUNT : "edit_sku_discount",
  EDIT_SKU_DISCOUNT_SUBMIT : "edit_sku_discount_submit",
  EDIT_SKU_DISCOUNT_CANCEL : "edit_sku_discount_cancel",
  DELETE_SKU_DISCOUNT : "delete_sku_discount",
  DELETE_SKU_DISCOUNT_SUBMIT :"delete_sku_discount_submit",
  UPDATE_SKU_DISCOUNT_PAGE : "update_sku_discount_page",
  UPDATE_SKU_DISCOUNT_SUBMIT : "update_sku_discount_submit",

  // Seller Onboard flow
  SELLER_ONBOARD_PAGE : "seller_onboard_page",
  ONBOARD_PAGE_FORM_SUBMIT: "onboard_page_form_submit",
  REVIEW_PAGE_SUBMIT: "review_page_submit",
  EDIT_FORM_REVIEW_PAGE : "edit_form_review_page",
   
  // Okta signin flow
  OKTA_LOGIN_CLICKED : "okta_login_clicked",
  OKTA_REDIRECTION : "okta_redirection",
  USER_AUTHENTICATE : "user_authenticate",
  USER_AUTHENTICATE_RESULT : "user_authenticate_result",
}