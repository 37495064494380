import { EventEmitter, Injectable, Output, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class EventsService {
  @Output()
  appHeader: EventEmitter<any> = new EventEmitter(true);
  deletePopup: EventEmitter<any> = new EventEmitter(false);
  clientName: EventEmitter<any> = new EventEmitter(true);
  updateMenu: EventEmitter<any> = new EventEmitter(true);
  constructor() {}
}
