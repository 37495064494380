import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { SettingsContainerComponent } from "@app/settings";
import {
  AuthGuardService,
  AdminGuard,
  AdminShareGuard,
  PartnerGuard,
  VerifierGuard,
  OktaLoginVerifier
} from "@app/core";
import { CallbackComponent } from "./callback.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('app/seller-landing-page/seller-landing-page.module').then(m => m.SellerLandingPageGridModule),
    pathMatch: "full"
  },
  {
    path: "sign-up",
    loadChildren: () => import('app/seller-signup/seller-signup.module').then(m => m.SellerSignupModule),
  },
  {
    path: "sign-in",
    loadChildren: () => import('app/seller-signin/seller-signin.module').then(m => m.SellerSigninModule),
  },
  {
    path: "seller-onboard",
    loadChildren: () => import('app/seller-onboard/seller-onboard.module').then(m => m.SellerOnboardModule),
    canActivate: [AdminGuard],
    data: { roles: ["PROSPECT_SELLER"] }
  },
  {
    path: "seller-dashboard",
    loadChildren: () => import('app/seller-dashboard/seller-dashboard.module').then(m => m.SellerDashboardModule),
    canActivate: [AdminGuard],
    data: { roles: ["DASHBOARD_VIEW", "DASHBOARD_EDIT"] }
  },
  {
    path: "settings",
    component: SettingsContainerComponent,
    data: { title: "anms.menu.settings" }
  },
  // {
  //   path: "profile",
  //   // loadChildren: "app/profile/profile.module#ProfileModule",
  //   loadChildren: () =>
  //     import("@app/profile/profile.module").then(m => m.ProfileModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "examples",
  //   // loadChildren: 'app/examples/examples.module#ExamplesModule'
  //   loadChildren: () =>
  //     import("@app/examples/examples.module").then(m => m.ExamplesModule)
  // },
  {
    path: "dashboard",
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AdminGuard],
    data: { roles: ["DASHBOARD_VIEW", "DASHBOARD_EDIT"] }
  },
  {
    path: "retailers",
    loadChildren: () => import('app/users/users.module').then(m => m.UsersModule),
    canActivate: [AdminGuard],
    data: {roles: ['RETAILER_VIEW', 'RETAILER_EDIT', 'KYC_VERIFICATION']}
  },  
  // {
  //   path: "customers",
  //   // loadChildren: 'app/customer/customer.module#CustomerModule',
  //   loadChildren: () =>
  //     import("@app/customer/customer.module").then(m => m.CustomerModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "projects",
  //   // loadChildren: 'app/project/project.module#ProjectModule',
  //   loadChildren: () =>
  //     import("@app/project/project.module").then(m => m.ProjectModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "sign-in",
  //   // loadChildren: 'app/sign-in/sign-in.module#SignInModule'
  //   loadChildren: () =>
  //     import("@app/sign-in/sign-in.module").then(m => m.SignInModule)
  // },
  // {
  //   path: "sign-in",
  //   loadChildren:
  //     () => import('app/logistic-partner-pages/partner-signin/partner-signin.module').then(m => m.PartnerSignInModule)
  // },
  // {
  //   path: "forgot-password",
  //   // loadChildren: "app/forgot-password/forgot-password.module#ForgotPasswordModule"
  //   loadChildren: () =>
  //     import("@app/forgot-password/forgot-password.module").then(
  //       m => m.ForgotPasswordModule
  //     )
  // },
  // {
  //   path: "set-password",
  //   // loadChildren: "app/set-password/set-password.module#SetPasswordModule"
  //   loadChildren: () =>
  //     import("@app/set-password/set-password.module").then(
  //       m => m.SetPasswordModule
  //     )
  // },
  // {
  //   path: "quotes",
  //   // loadChildren: 'app/quotes/quotes.module#QuotesModule',
  //   loadChildren: () =>
  //     import("@app/quotes/quotes.module").then(m => m.QuotesModule),
  //   canActivate: [AuthGuardService]
  // },
  {
    path: "orders",
    loadChildren: () => import('app/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AdminGuard],
    data: { roles: ["ORDER_HISTORY_VIEW", "ORDER_HISTORY_EDIT"] }
  },
  {
    path: "error",
    loadChildren: () => import('app/error-page/error-page.module').then(m => m.ErrorPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "FA",
    loadChildren: () => import('app/fa-grid/fa-grid.module').then(m => m.FAGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["FA_VIEW", "FA_EDIT"] }
  },
  {
    path: "payout-dashboard",
    loadChildren: () => import('app/payout-dashboard/payout-dashboard.module').then(m => m.PayoutDashboardModule),
    canActivate: [AdminGuard],
    data: { roles: ["PAYOUT_DASHBOARD_VIEW"]}
  },
  // {
  //   path: "territory-manager",
  //   // loadChildren: 'app/quotes/quotes.module#QuotesModule',
  //   loadChildren: () =>
  //     import("@app/territory-manager/territory-manager.module").then(
  //       m => m.TerritoryManagerModule
  //     ),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "regional-manager",
  //   // loadChildren: 'app/quotes/quotes.module#QuotesModule',
  //   loadChildren: () =>
  //     import("@app/regional-manager/regional-manager.module").then(
  //       m => m.RegionalManagerModule
  //     ),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "zonal-manager",
  //   // loadChildren: 'app/quotes/quotes.module#QuotesModule',
  //   loadChildren: () =>
  //     import("@app/zonal-manager/zonal-manager.module").then(
  //       m => m.ZonalManagerModule
  //     ),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "country-manager",
  //   // loadChildren: 'app/quotes/quotes.module#QuotesModule',
  //   loadChildren: () =>
  //     import("@app/country-manager/country-manager.module").then(
  //       m => m.CountryManagerModule
  //     ),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "review-screen",
  //   // loadChildren: 'app/quotes/quotes.module#QuotesModule',
  //   loadChildren: () =>
  //     import("@app/review-screen/review-screen.module").then(
  //       m => m.ReviewScreenModule
  //     ),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: "product-management",
  //   loadChildren:
  //     () => import('app/product-management/product-management.module').then(m => m.ProductManagementModule),
  //   canActivate: [AdminGuard],
  //   data: { roles: ["PRODUCT_VIEW", "PRODUCT_EDIT"] }
  // },
  {
    path: "price-import",
    loadChildren: () => import('app/price-import/price-import.module').then(m => m.PriceImportModule),
    canActivate: [AdminGuard],
    data: { roles: ["PRICE_MANAGEMENT_VIEW", "PRICE_MANAGEMENT_EDIT"] }
  },
  {
    path: "inventory-import",
    loadChildren:
      () => import('app/inventory-import/inventory-import.module').then(m => m.InventoryImportModule),
    canActivate: [AdminGuard],
    data: { roles: ["INVENTORY_MANAGEMENT_VIEW", "INVENTORY_MANAGEMENT_EDIT"] }
  },
  {
    path: "inventory-replenishment",
    loadChildren:
      () => import('app/inventory-replenishment/inventory-replenishment.module').then(m => m.InventoryReplenishmentModule),
    canActivate: [AdminGuard],
    data: {
      roles: ["REPLENISHMENT_INVENTORY_VIEW", "REPLENISHMENT_INVENTORY_EDIT"]
    }
  },
  {
    path: "banner-images",
    loadChildren: () => import('app/banner-images/banner-images.module').then(m => m.BannerImagesModule),
    canActivate: [AdminGuard],
    data: { roles: ["BANNER_IMAGES_VIEW", "BANNER_IMAGES_EDIT"] }
  },
  // {
  //   path: "partner-signin",
  //   loadChildren: () =>
  //     import("@app/logistic-partner-pages/partner-signin/partner-signin.module").then(m => m.PartnerSignInModule)
  // // },
  {
    path: "partner-dashboard",
    loadChildren:
      () => import('app/logistic-partner-pages/partner-dashboard/partner-dashboard.module').then(m => m.PartnerDashboardModule),
    canActivate: [PartnerGuard]
  },
  {
    path: "delivery-schedules",
    loadChildren:
      () => import('app/delivery-schedules/delivery-schedules.module').then(m => m.DeliverySchedulesModule),
    canActivate: [AdminGuard],
    data: { roles: ["DELIVERY_SCHEDULE_VIEW", "DELIVERY_SCHEDULE_EDIT"] }
  },
  {
    path: "delivery-partners",
    loadChildren:
      () => import('app/delivery-partners/delivery-partners.module').then(m => m.DeliveryPartnersModule),
    canActivate: [AdminGuard],
    data: { roles: ["DELIVERY_PARTNER_VIEW", "DELIVERY_PARTNER_EDIT"] }
  },
  {
    path: "users",
    loadChildren: () => import('app/admin-users/admin-users.module').then(m => m.AdminUsersGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["USER_VIEW", "USER_EDIT"] }
  },
  // {
  //   path: "sap-failure-orders",
  //   loadChildren: 'app/sap-failure-orders/sap-failure-orders.module#SapFailureOrdersGridModule',
  //   canActivate: [AdminGuard],
  //   data: {roles: ['ORDER_HISTORY_EDIT']}
  // },
  // {
  //   path: "sap-failure-retailers",
  //   loadChildren:
  //     () => import('app/sap-failure-retailers/sap-failure-retailers.module').then(m => m.SapFailureRetailersGridModule),
  //   canActivate: [AdminGuard],
  //   data: { roles: ["RETAILER_VIEW"] }
  // },
  {
    path: "max-orderable-qty",
    loadChildren:
      () => import('app/max-orderable-qty/max-orderable-qty.module').then(m => m.MaxOrderableQtyGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["MAX_ORDERABLE_QTY_VIEW", "MAX_ORDERABLE_QTY_EDIT"] }
  },
  {
    path: "min-orderable-qty",
    loadChildren:
      () => import('app/min-orderable-qty/min-orderable-qty.module').then(m => m.MinOrderableQtyGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["MOQ_VIEW", "MOQ_EDIT"] }
  },
  {
    path: "commission",
    loadChildren: () => import('app/commission/commission.module').then(m => m.CommissionGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["COMMISSION_VIEW", "COMMISSION_EDIT"] }
  },
  {
    path: "reward-points",
    loadChildren:
      () => import('app/reward-points/reward-points.module').then(m => m.RewardPointsGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["REWARD_POINTS_EDIT", "REWARD_POINTS_VIEW"] }
  },
  {
    path: "uid-code",
    loadChildren: () => import('app/uid-code/uid-code.module').then(m => m.UidCodeGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["FARMER_CONNECT_VIEW"] }
  },
  {
    path: "admin-sign-in",
    loadChildren: () => import('app/landing-page/landing-page.module').then(m => m.LandingPageModule),
    canActivate: [OktaLoginVerifier]
  },
  {
    path: "callback",
    component: CallbackComponent
  },
  {
    path: "cash-collection",
    loadChildren:
      () => import('app/cash-collection/cash-collection.module').then(m => m.CashCollectionGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["CASH_COLLECTION_VIEW"] }
  },
  {
    path: "min-cart-limit",
    loadChildren:
      () => import('app/min-cart-limit/min-cart-limit.module').then(m => m.MinCartLimitGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["MIN_CART_LIMIT_VIEW", "MIN_CART_LIMIT_EDIT"] }
  },
  {
    path: "block-unblock-retailers",
    loadChildren:
      () => import('app/block-unblock-retailer/block-unblock-retailer.module').then(m => m.BlockUnblockRetailerGridModule),
    canActivate: [AdminGuard],
    data: {
      roles: ["BLOCK_UNBLOCK_RETAILER_VIEW", "BLOCK_UNBLOCK_RETAILER_EDIT"]
    }
  },
  {
    path: "enterprise",
    loadChildren: () => import('app/enterprise/enterprise.module').then(m => m.EnterpriseGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["ENTERPRISE_MANAGEMENT_EDIT"] }
  },
  {
    path: "prospect-seller",
    loadChildren: () => import('app/enterprise/enterprise.module').then(m => m.EnterpriseGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["ENTERPRISE_MANAGEMENT_EDIT"], isProspectSeller: true }
  },
  {
    path: "plant",
    loadChildren: () => import('app/plant/plant.module').then(m => m.PlantGridModule),
    canActivate: [AdminGuard],
    data: { roles: ["ENTERPRISE_MANAGEMENT_EDIT"] }
  },
  {
    path: "holidays",
    loadChildren: () => import('app/edd-holidays/edd-holidays.module').then(m => m.EddHolidaysModule),
    canActivate: [AdminGuard],
    data: { roles: ["EDD_VIEW", "EDD_EDIT"] }
  },
  {
    path: "processing-time",
    loadChildren:
      () => import('app/edd-processing-time/edd-processing-time.module').then(m => m.EddProcessingTimeModule),
    canActivate: [AdminGuard],
    data: { roles: ["EDD_VIEW", "EDD_EDIT"] }
  },
  {
    path: "credit-period",
    loadChildren: () => import('app/credit-period/credit-period.module').then(m => m.CreditPeriodModule),
    canActivate: [AdminGuard],
    data: { roles: ["PRICE_MANAGEMENT_VIEW", "PRICE_MANAGEMENT_EDIT"] }
  },
  {
    path: "kyc-verification",
    loadChildren: () => import('app/verifiers/verifiers.module').then(m => m.VerifiersGridModule),
    canActivate: [VerifierGuard]
  },
  {
    path: "tutorials",
    loadChildren: () => import('app/tutorials/tutorials.module').then(m => m.TutorialsGridModule)
  },
  {
    path: "faqs",
    loadChildren: () => import('app/faqs/faqs.module').then(m => m.FaqsModule)
  },
  {
    path: "combo-offers",
    loadChildren: () => import('app/combo-offers/combo-offers.module').then(m => m.ComboOffersModule),
    canActivate: [AdminGuard],
    data: {roles: ['SKU_DISCOUNT_DETAILS_VIEW', 'SKU_DISCOUNT_DETAILS_EDIT']}
  },
  {
    path: "order-pendency",
    loadChildren:
      () => import('app/order-pendency/order-pendency.module').then(m => m.OrderPendencyGridModule)
  },
  {
    path: "sku-discount",
    loadChildren: () => import('app/sku-discount/sku-discount.module').then(m => m.SkuDiscountGridModule),
    canActivate: [AdminGuard],
    data: {roles: ['SKU_DISCOUNT_DETAILS_VIEW', 'SKU_DISCOUNT_DETAILS_EDIT']}
  },
  {
    path: "discounts",
    loadChildren: () => import('app/discounts/discounts.module').then(m => m.DiscountsModule),
    canActivate: [AdminGuard],
    data: {roles: ['SKU_DISCOUNT_DETAILS_VIEW', 'SKU_DISCOUNT_DETAILS_EDIT', 'BULK_DISCOUNT_VIEW', 'BULK_DISCOUNT_EDIT']}
  },
  {
    path: "my-catalogue",
    loadChildren: () => import('app/my-product-catalogue/my-product-catalogue.module').then(m=> m.MyProductCatalogueGridModule),
    canActivate: [AdminGuard],
    data: {roles: ['MY_CATALOG_EDIT']}
  },
  {
    path: "master-catalogue",
    loadChildren: () => import('app/master-catalogue/master-catalogue.module').then(m => m.MasterCatalogueModule),
    canActivate: [AdminGuard],
    data: {roles: ['PRODUCT_VIEW', 'PRODUCT_EDIT']}
  },
  {
    path: "product-company",
    loadChildren: () => import('app/product-company/product-company.module').then(m => m.ProductCompanyModule),
    canActivate: [AdminGuard],
    data : {roles: ['PRODUCT_VIEW', 'PRODUCT_EDIT']}
  },
  {
    path: "bulk-discount",
    loadChildren: () => import('app/bulk-discount/bulk-discount.module').then(m => m.BulkDiscountGridModule),
    canActivate: [AdminGuard],
    data: {roles: ['BULK_DISCOUNT_VIEW', 'BULK_DISCOUNT_EDIT']}
  },
  {
    path: "ananya-finance",
    loadChildren: () => import('app/ananya-finance/ananya-finance.module').then(m => m.AnanyaFinanceModule),
    canActivate: [AdminGuard],
    data : {roles: ['AGIF_ADMIN']}
  },
  {
    path: "cancellation-failure",
    loadChildren: () => import('app/sap-order-cancellation-failure/sap-order-cancellation-failure.module').then(m => m.SapOrderCancellationFailureModule),
    canActivate: [AdminGuard],
    data : {roles: ["RETAILER_VIEW"]}
  },
  {
    path: "**",
    redirectTo: "sign-in",
    // canActivate: [AuthGuardService]
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
