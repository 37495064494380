import { Injectable, Injector, ErrorHandler } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Utility } from '@app/utility/utility';
import { tokenExpiringInMs } from '../../helpers/helper-functions';
import { AuthService } from '@app/services/auth.service';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  isRefreshing: boolean = false;
  constructor(
    private injector: Injector,
    private utility: Utility,
    private auth: AuthService
    ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authToken = JSON.parse(localStorage.getItem("authUser"));
    if(!authToken?.token) {
      return next.handle(request);
    }
    if(request.url.endsWith('logout') || request.url.endsWith('refresh/token') || request.url.includes('afs-static-content') || !tokenExpiringInMs(authToken?.token, 60000)) {
      return next.handle(request);
    }
    if(authToken?.token && !tokenExpiringInMs(authToken?.token, 60000)) {
      return next.handle(request);
    }
    else if(authToken?.token && tokenExpiringInMs(authToken?.token, 60000)) {
      if(authToken?.refreshToken && !tokenExpiringInMs(authToken?.refreshToken, 60000)) {
        return this.auth.refreshToken().pipe(switchMap((res) => {
          if(res && res.status === 200) {
            const authToken = { ...JSON.parse(localStorage.getItem("authUser")), token: res.body?.token, refreshToken: res.body?.refreshToken }
            localStorage.setItem("authUser", JSON.stringify(authToken));
          }
          const authReq = request.clone({
            headers: request.headers.set('Authorization', "Bearer "+ res.body?.token)
          });
          return next.handle(authReq);
        })
        );
      }
      else {
        this.utility.handleRefreshTokenError();
        return next.handle(request);
      }
    }
    else {
      this.utility.handleRefreshTokenError();
      return next.handle(request)
    }
  }
}
