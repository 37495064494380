import {Injectable} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {
  MatDateRangeSelectionStrategy,
  DateRange,
} from '@angular/material/datepicker';
import {CalendarService} from "@app/common-components/mat-date-range-picker/calendar-service.service";


@Injectable()
export class MaxRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D> {
  start: any;
  public delta: number;
  constructor(
    public calendarService: CalendarService,
    private _dateAdapter: DateAdapter<D>
    ) {}

  selectionFinished(date: D, currentRange: DateRange<D>) {
    let { start, end } = currentRange;
    if(start && !end && start > date) {
      start = date;
    } else if (start == null || (start && end)) {
      start = date;
      end = null;
    } else if (end == null) {
      if(this.delta) {
      const maxDate = this._dateAdapter.addCalendarDays(start, this.delta - 1);
      end = date ? (date > maxDate ? maxDate : date) : null;
      } else 
        end = date;
    }
    this.calendarService.setStartDate(start);
    this.calendarService.setEndDate(end);
    return new DateRange<D>(start, end);
  }

  createPreview(
    activeDate: D | null,
    currentRange: DateRange<D>
  ): DateRange<D> {
    let rangeEnd;
    if (currentRange.start && !currentRange.end) {
      if(this.delta) {
      const maxDate = this._dateAdapter.addCalendarDays(
        currentRange.start,
        this.delta - 1
      );
      rangeEnd = activeDate
        ? activeDate > maxDate
          ? maxDate
          : activeDate
        : null;
      } else {
        rangeEnd = activeDate;
      }

      return new DateRange(currentRange.start, rangeEnd);
    }

    return new DateRange<D>(null, null);
  }
}
