import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { SellerAgreementModalComponent } from "@app/common-popup/seller-agreement-modal/seller-agreement-modal.component";

@NgModule({
  declarations: [SellerAgreementModalComponent],
  exports: [
    SellerAgreementModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class SellerAgreementModalModule {}
