export const LOCAL_STORAGE_KEYS = {
  isTermsAndConditionsAcceptedAtSignUp: "isTermsAndConditionsAcceptedAtSignUp",
};

export const S3_STATIC_BUCKET = "afs-static-content";
export const S3_ANNOUNCEMENTS_DIR =
  "SellerFirst_announcements_section/CSV_announcements/";
export const LANGUAGES = {
  EN_US: "English",
  HI_IN: "Hindi",
  GU: "Gujrati",
  TA: "Tamil",
  TE: "Telugu",
};

export const DATE_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export const S3_RETAIL_APP_URL_STAGE = 'https://retail-app-js.s3.ap-south-1.amazonaws.com/RetailAssetsStage/all_products.zip'
export const S3_RETAIL_APP_URL = 'https://retail-app-js.s3.ap-south-1.amazonaws.com/RetailAssets/all_products.zip'