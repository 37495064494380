<div class="col-md-12 terms-and-Conditions-modal m-4">
    <div class="modal-header-container d-flex">
        <div class="modal-header-container--title">Terms And Conditions</div>
        <img src="../../../../assets/close.svg" (click)="onCloseCancel()" />
    </div>
    <div mat-dialog-content class="content-section">
        <div class="row justify-content-center">
            <div class="col-md-12 d-block  px-0">
                <div class="row terms-and-Conditions-fields-margin">
                    <div class="content">
                    <p class="main-list"><strong>1.1</strong> The definitions and rules of interpretation in this clause apply in this Agreement (unless the context requires otherwise).</p>
                    <ol type="A">
                        <li><strong>Business of the Company</strong> means inter alia: (A) facilitation platform for online B2B agricultural inputs trading facility over the internet under the trade name nurture.retail; (B) business of providing online platform/technology and/or other mechanism/services to facilitate transactions, electronic commerce, mobile commerce, by and between respective buyers and sellers and enabling dealing in multiple categories of agricultural inputs..</li>
                        <li><strong>Company Property </strong>means all documents, books, manuals, materials, records, correspondence, papers and information (on whatever media and wherever located) relating to the Business of the Company or its business contacts, and any equipment, keys, hardware or software provided for the Seller's use by the Company during the Term, and any data or documents (including copies) produced, maintained or stored by the Seller or the Seller's computer systems or other electronic equipment during the Term.</li>
                        <li> <strong>Confidential Information</strong> means information in whatever form (including without limitation, in written, oral, visual or electronic form or on any magnetic or optical disk or memory, any communication through digital platform used for messaging services and wherever located) relating to the business of the seller or Business of the Company, products, affairs and finances of a Party and trade secrets including, without limitation, technical data and know-how or any of its suppliers, agents, customers, distributors, shareholders, management or business contacts and including (but not limited to) information that a Party creates, develops, receives or obtains from the other Party in connection with this Agreement, whether or not such information is marked confidential.</li>
                        <li><strong>Intellectual Property Rights</strong> means patents, rights to inventions, copyright and related rights, moral rights, trademarks, trade names and domain names, rights in get-up, rights in goodwill or to sue for passing off/infringement, rights in designs, rights in computer software, database rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications (or rights to apply) for, and renewals or extensions of, such rights and all similar or equivalent rights or forms of protection which may now or in the future subsist in any part of the world.</li>
                        <li><strong>Services</strong> means marketplace services proposed to be provided by the Company to the Seller, the details of which are more specifically set out in the Annexure 1 of this Agreement.</li>
                        <li> <strong>Termination Date</strong> means the date of termination of this Agreement, howsoever arising.</li>
                        <p><strong>Rules of interpretation</strong></p>
                        <li>The headings in this agreement are inserted for convenience only and shall not affect its construction.</li>
                        <li>A reference to a particular law is a reference to it as it is in force for the time being taking account of any amendment, extension, or re-enactment and includes any subordinate legislation for the time being in force made under it.</li>
                        <li>Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders.</li>
                        <li>Unless the context otherwise requires, words in the singular include the plural and in the plural include the singular.</li>
                        <li>The Annexure/s to this Agreement forms part of (and is incorporated into) this Agreement.</li>
                    </ol>
                    <br>
                    <p class="main-list"><strong>2. Term of Engagement</strong></p>
                        <p class="child-list mr-left-15"><strong>2.1</strong> The Company shall provide its Services to the Seller and the Seller has agreed to take the same on a non-exclusive basis.</p>
                        <p class="child-list mr-left-15"> <strong>2.2</strong> Details and terms of the Services provided by the Company are stated in the Scope of Work (SOW) at Annexure – 1 hereunder.</p>
                    <br>
                    <p class="main-list"><strong>3. Seller Duties</strong></p>
                        <p class="child-list mr-left-15"><strong>3.1 During the Term, the Seller shall:</strong></p>
                            <ol type="A">
                                <li>be in compliance with these terms of use, including those specified in the Annexure 1 and fulfil its obligations as specified therein;</li>
                                <li>secure and maintain the applicable licenses and provide the copy of the same to the Company to render support and Services as applicable under this Agreement;</li>
                                <li>comply with all applicable laws and business standards in relation to the products being sold on the Company’s platform and fulfilling its respective obligations under this Agreement and SOW;</li>
                                <li>if the Seller is unable to take the Services due to any issues, inform the Company of that fact as soon as reasonably practicable but no later than [•] days prior to the launch of the category on the Company’s platform. </li>
                            </ol>
                        <p class="child-list mr-left-15"><strong>3.2</strong> Unless Seller has been specifically authorised to do so in writing, the Company shall not have any authority to incur any expenditure in the name of or on account of the Seller.</p>
                        <p class="child-list mr-left-15"><strong>3.3</strong> Seller may use a third party to perform any administrative, clerical or secretarial functions which are reasonably incidental to the provision of the Services provided that, the Company will not be liable to bear the cost of such functions. </p>
                    <br>
                    <p class="main-list"> <strong>4. Fees and payment terms</strong></p>
                        <p class="child-list mr-left-15"><strong>4.1</strong> The Company shall provide payment services to the Seller in relation to each transaction on the Platform, in accordance with the terms detailed in Annexure 1 hereto.</p>
                        <p class="child-list mr-left-15"><strong>4.2</strong> Seller acknowledges and agrees that in performing the payment collection services, the Company is acting merely as a payment collector and shall take no responsibility as to the legality of any payment transaction between the Seller and the customers/retailers.</p>
                        <p class="child-list mr-left-15"><strong>4.3</strong> In consideration of the provision of the Services during the Term, the amount payable by the Seller to the Company shall be deducted from the consideration for the sale of goods payable by the Company to the Seller.</p>
                        <p class="child-list mr-left-15"><strong>4.4</strong> The Company shall be entitled to deduct from the fees, the statutory withholding taxes, including applicable GST, and/or any sums that the Seller may owe to the Company at any given point of time.</p>
                        <p class="child-list mr-left-15"><strong>4.5</strong> In case of any mismatches on account of tax collection at source, Seller shall be required to provide all relevant information to the Company, to correspond with the relevant authorities and also in case of any liability accruing on account of omission shall be Seller’s obligation to pay such deficit.</p>
                        <p class="child-list mr-left-15"><strong>4.6</strong> Seller shall provide the corresponding Harmonised System Nomenclature (HSN) code number and applicable GST rates for every product sold of the Platform. Declaring the HSN and GST rates of the product is the Seller’s responsibility, and that in order to sell the product(s) on the Platform, pre-declaring the respective HSN and GST rates shall be mandatory.</p>
                        <p class="child-list mr-left-15"><strong>4.7</strong> Seller will be responsible to provide their correct GST registration number against which the tax collected at source (under GST laws) need to be reported by the Company. The Company shall not be liable for any incorrect disclosures of GST registration number by the Seller and consequent loss of credits of tax collected at source. In case any tax claim, interest or penalty is imposed on the Company on account of incorrect disclosure of information by the Seller, then the Seller shall indemnify the Company with respect to all such claims.</p>
                        <p class="child-list mr-left-15"><strong>4.8</strong> The Company shall have the right to deduct or recover, as the case may be, tax deducted at source (“TDS”) as may be applicable under the applicable provisions of Income Tax Act,1961 (as may be amended from time to time). In respect of TDS deducted and / or recovered, the Company will provide certificate(s) evidencing and / or supporting the deposit of TDS in the prescribed format in accordance with the provisions of the Income Tax Act, 1961 (as may be amended from time to time). It is clarified that for this purpose, we will be entitled to rely upon the Permanent Account Number (“PAN”) and any other particulars provided to the Company and the Company shall have no obligation to validate or verify the same. In the event of any liability that may arise on account of incorrect or incomplete particulars / details being provided by Seller to the Company, including interest, penalty or any other levies, the Company shall have the right to recover the same from the Seller at it’s sole discretion.</p>
                        <p class="child-list mr-left-15"><strong>4.9</strong> Payment in full or in part of the fees claimed under this clause 4 shall be without prejudice to any other claims or rights of the Company against the Seller in respect of the provision of the Services.</p>
                    <br>
                    <p class="main-list"> <strong>5. Confidential information</strong></p>
                        <p class="child-list mr-left-15"><strong>5.1</strong> Both the Parties shall hold and cause to be held, in strict confidence all Confidential Information received by it from other Party for the purpose of carrying out the activities under this Agreement. This confidentiality obligation shall not apply to information, which is now or hereafter becomes, generally known to the public through no act or fault of other.</p>
                        <p class="child-list mr-left-15"><strong>5.2</strong> Both Parties shall use utmost care to maintain the confidentiality of the Confidential Information provided by the other Party and limit its disclosure only to such of its employees on a need-to-know basis, who would be working on this assignment and shall advise such employees and representatives of the existence and terms of this Agreement and of the obligations of confidentiality herein. The Party providing any information to its employee/representative shall ensure that such employee/representative shall maintain confidentiality of such information and shall be liable for any breach of confidentiality by its employees/representatives.</p>
                        <p class="child-list mr-left-15"><strong>5.3</strong> Further, unless prior written consent of the other Party is obtained, such Party shall not use the information provided by the other for any purpose other than the purpose contemplated under this Agreement.</p>
                        <p class="child-list mr-left-15"><strong>5.4</strong> Information shall not be duplicated or reproduced by either Party in any manner.</p>
                        <p class="child-list mr-left-15"><strong>5.5</strong> This Clause shall survive the expiration or termination of this Agreement.</p>
                        <p class="child-list mr-left-15"><strong>5.6</strong> Neither Party shall publicize or disclose the terms or existence of this Agreement, nor shall either Party use the name(s), trademark(s), or trade name(s) of other party, its affiliates, except as follows:  (i) With the prior written consent of the other party; or (ii) as may be necessary for the Company to perform its obligations under this Agreement; or (iii) as may otherwise be required by law.</p>
                    <br>
                    <p class="main-list"> <strong>6. Intellectual property</strong></p>
                        <p class="child-list mr-left-15"><strong>6.1</strong> Both the Parties agree and acknowledge that all copyright, know-how and other Intellectual Property Rights arising in any Confidential Information vests in and shall remain vested in and shall remain vested in the disclosing party and the recipient shall have no rights in any of the same and that the provision of such Confidential Information shall not create in favour of the recipient party any rights of ownership therein or license save as expressly set out otherwise in this Agreement.</p>
                        <p class="child-list mr-left-15"><strong>6.2</strong> The Seller shall not seek to register trademark of the Company or any of the sellers on its platform or any mark deceptively similar to trademark belonging to such Company or any of its sellers, nor distribute, sell, offer for sale, display, advertise, promote any product counterfeiting or imitating the trademark of the Company or any of the sellers and/or infringing any Intellectual Property Rights of the Company or any of the sellers. In the event of breach of this clause by the Seller, the Company shall have the right to terminate this Agreement with immediate effect. The Seller shall also be responsible for indemnifying the Company for any loss incurred by the Company on account of the breach of these obligations including reasonable legal costs. This clause shall survive after expiry/ termination of the Agreement.</p>
                    <br>
                    <p class="main-list"> <strong>7. Indemnity</strong></p>
                        <p class="child-list mr-left-15"><strong>7.1</strong> Seller shall indemnify and hold the Company, its affiliates and/or their respective representatives and assigns harmless against all notices, claims, demands, action, suits or proceedings given, made or initiated against Company on account of or arising out of any and all liabilities, damages, injuries, cause of action and expenses including attorney’s fees suffered or incurred by Company for: (a); (b) loss or damage caused to Company’s property; (c) negligence, misconduct and misrepresentation by the Seller or its representatives; (d) breach of  representation, warranties and obligations under this Agreement and SOW (as applicable); (e) any product quality related issues including any damage or loss caused to the Company, its products or business due to spoiled, defective or non-conforming product; and (f) any third party claims for Seller’s product related issues or infringement of Intellectual Property.</p>
                        <p class="child-list mr-left-15"><strong>7.2</strong> The Company shall not have any liability whatsoever for any claims arising from: (a) Seller’s acts or omissions; (b) an act or order of any government authority; (c) the insufficiency of the packing or labelling of shipment/ consignment; (d) the nature or description of the shipment/ consignment; (e) riots, civil commotions, strikes, lockouts, stoppage or restraint of labour; (f) explosion, fire, flood or storm; (g) any loss, miss-delivery, delay or damage to any shipment/ consignment; and/or (h) any dispute or claims between the Seller and the retailers/customers including without limitation relating to the shipment/ consignment or the Products hereunder.</p>
                        <p class="child-list mr-left-15"><strong>7.3</strong> Seller shall be liable for any damages including, but not limited to, indirect, special, incidental, and/or consequential damages, arising out of or in connection with the sale of goods or Services provided by them under this Agreement.</p>
                    <br>
                    <p class="main-list"> <strong>8. Termination</strong></p>
                        <p class="child-list mr-left-15"><strong>8.1</strong> The Company reserves the right to terminate the Agreement prior to the expiry of the Term, with immediate effect, with no liability to make any further payment to the Seller (other than in respect of amounts accrued before the Termination Date) if at any time, the Seller:</p>
                            <ol type="A">
                                <li>commits any gross misconduct affecting the Company;</li>
                                <li>commits any serious or repeated breach or non-observance of any of the provisions of this Agreement or refuses or neglects to comply with any reasonable and lawful directions of the Company;</li>
                                <li>is declared bankrupt or makes any arrangement with or for the benefit of its creditors or has a court order made against it;</li>
                                <li>commits any fraud or dishonesty or acts in any manner which in the opinion of the Company brings or is likely to bring the Company into disrepute or is materially adverse to the interests of the Company.</li>
                            </ol>
                        <p class="child-list mr-left-15"><strong>8.2</strong> Either Party may terminate this Agreement for convenience, by giving a prior written notice of at-least 30 (thirty) days to the other Party.</p>
                        <p class="child-list mr-left-15"><strong>8.3</strong> The rights of the Company under this clause 8 are without prejudice to any other rights that it might have at law to terminate the Agreement or to accept any breach of this Agreement on the part of the Seller as having brought the Agreement to an end. Any delay by the Company in exercising its rights to terminate shall not constitute a waiver of these rights.</p>
                    <br>
                    <p class="main-list"> <strong>9. Obligations on termination</strong></p>
                        <p class="child-list mr-left-15"><strong>9.1</strong> On the Termination Date, the Seller shall:</p>
                            <ol type="A">
                                <li>immediately deliver to the Company, all the Company Property and Confidential Information belonging to the Company in its possession or under its control.</li>
                                <li>irretrievably delete any information relating to the Business of the Company stored on any magnetic or optical disk or memory and all matter derived from such sources which is in its possession or under its control outside the premises of the Company, except if any information is required for regulatory purposes; and</li>
                                <li>provide a signed statement that it has complied fully with its obligations under this clause 9.</li>
                            </ol>
                        <p class="child-list mr-left-15"><strong>9.2</strong> The Company shall return all the remaining products unsold in its possession within a period of 10 business days from the Termination Date. In the event the termination has been initiated by the Seller for any reason except for breach by the Company or the termination is initiated by the Company for causes mentioned in Clause 9.1, all costs in relation to the return of the products (including transport freight and logistics) will be borne by the Seller.  </p>
                    <br>
                    <p class="main-list"> <strong>10. Status</strong></p>
                        <ol type="A">
                            <li>The relationship of the Seller to the Company will be that of independent contractor and nothing in this Agreement shall render it as an employee, worker, agent or partner of the Company and the Seller shall not hold itself out as such.</li>
                            <li>This Agreement constitutes a contract for the provision of services and not a contract of employment and accordingly the Seller shall be fully responsible for and shall indemnify the Company for and in respect of any liability arising from any employment-related claim or any claim based on worker status (including reasonable costs and expenses) brought by the Seller or its employees, workers, contractors against the Company arising out of or under the scope of this Agreement.</li>
                        </ol>
                        
                    <br>
                    <p class="main-list"> <strong>11. Notices</strong></p>
                        <ol type="A">
                            <li>Any notice given under this Agreement shall be in writing and signed by or on behalf of the Party giving it and shall be hand delivered, or sending it by pre-paid recorded delivery or registered post to the relevant Party at the addresses as mentioned in the Marketplace Services Agreement.</li>
                            <li>Any such notice shall be deemed to have been received:</li>
                            <li>if delivered personally, at the time of delivery;</li>
                            <li>if by email, when the email is sent; and</li>
                            <li>in the case of pre-paid recorded delivery or registered post.</li>
                        </ol>
                        <p class="child-list mr-left-15">Any legal notice should be served by e mail as well as by personal delivery or delivery by post.</p>
                    <br>
                    <p class="main-list"> <strong>12. Entire agreement</strong></p>
                        <p class="child-list mr-left-15">Each Party on behalf of itself acknowledges and agrees with the other Party that this Agreement constitutes the entire agreement and understanding between the Seller and the Company and supersedes any previous arrangement, understanding or agreement between them relating to scope of this Agreement.</p>
                    <br>
                    <p class="main-list"> <strong>13. Variation/ AMENDMENT</strong></p>
                        <p class="child-list mr-left-15">No variation or amendment of this Agreement shall be valid unless it is in writing and signed by or on behalf of each of the Parties.</p>
                    <br>
                    <p class="main-list"> <strong>14. Assignment</strong></p>
                        <p class="child-list mr-left-15">Seller cannot assign, sublicense or subcontract this Agreement or SOW to any third party without the prior written consent of the Company. Provided further that, at all times, the Company can assign, novate, sublicense or subcontract this Agreement or SOW to its subsidiaries and affiliates. This Agreement and any duly executed SOW shall be binding on and shall inure to the benefit of the Parties and their successors and permitted assigns.</p>
                    <br>
                    <p class="main-list"> <strong>15. Counterparts</strong></p>
                        <p class="child-list mr-left-15">This Agreement may be executed in any number of counterparts, each of which, when executed, shall be an original, and all the counterparts together shall constitute one and the same instrument. Digital signatures shall be deemed the equivalent of original signatures for all legal purposes.</p>
                    <br>
                    <p class="main-list"> <strong>16. arbitration, Governing law and jurisdiction</strong></p>
                        <p class="child-list mr-left-15"><strong>16.1</strong> Any dispute or difference arising out of or in connection with this Agreement, which cannot be amicably settled by the Parties within 30 days, shall be settled by arbitration in accordance with the rules of Arbitration and Conciliation Act, 1996 to be conducted by a sole arbitrator. The language of arbitration shall be English. The seat of arbitration shall be Mumbai. The arbitral award shall be final, conclusive and binding on the Parties and shall be enforceable in any court of competent jurisdiction.</p>
                        <p class="child-list mr-left-15"><strong>16.2</strong> This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with laws of India.</p>
                        <p class="child-list mr-left-15"><strong>16.3</strong> Subject to Clause 16.1 above, the Parties irrevocably agree that the courts of Mumbai shall have jurisdiction to settle any dispute or claim that arises out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims).</p>
                    </div>
                </div>

                <mat-dialog-actions class="d-block">
                    <div class="checbox-box text-left">
                        <mat-checkbox #confirmed class="ml-2">I have read and agree to the Terms and Conditions.</mat-checkbox>
                        
                    </div>
                    
                    <div class="buttons">
                        
                        <button class="button-basic secondary-button button--light-red py-0" type="button" mat-stroked-button  (click)="onCloseCancel()" >Cancel</button>
                        <button class="button-basic primary-button py-0" mat-stroked-button (click)="onAccept()" [disabled]="!confirmed.checked">Accept</button>
                    </div>
                </mat-dialog-actions>
            </div>
        </div>
    </div>
</div>