
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Inject
} from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";
import { NotificationService } from "@app/core";
import { Location } from "@angular/common";
import { EventsService } from "@app/events/events.service";
import { OrderService } from '@app/orders/service/order.service';
import { Router } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroup, FormControl } from "@angular/forms";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment'
import { CustomDateAdapter } from '@app/common-components/custom-date-adapter';
import {CustomDatePickerHeaderComponent} from "@app/common-components/custom-date-picker-header/custom-date-picker-header.component"
import {CalendarService} from "@app/common-components/mat-date-range-picker/calendar-service.service";

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: "pop-up",
  templateUrl: "./pop-up.component.html",
  styleUrls: ["./pop-up.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PopUpComponent implements OnInit, AfterViewInit {
  table: MatTable<any>;
  deleteID: string;
  setID: string;
  customerID: string;
  activeStatus: boolean;
  previousUrl: string;
  dialogData: any;
  rejectKycForm: UntypedFormGroup;
  rejectDocForm: UntypedFormGroup;
  rejectKYCDocForm: UntypedFormGroup;
  retailerBlockUnblockForm: UntypedFormGroup;
  cancelOrderForm: UntypedFormGroup;
  pendingKycForm: UntypedFormGroup;
  orderStatusForm: UntypedFormGroup;
  changeFAForm: UntypedFormGroup;
  orderExportForm: UntypedFormGroup;
  rejectKycFormInvalid: boolean;
  rejectDocFormInvalid: boolean;
  rejectKYCDocFormInvalid: boolean;
  retailerBlockUnblockFormInvalid: boolean;
  cancelOrderFormInvalid: boolean;
  pendingKycFormInvalid: boolean;
  multiDropdownSettings = {};
  startDateError: boolean;
  endDateError: boolean;
  startdate: any;
  enddate: any;
  fieldAgentList: any = [];
  listOfPlants: any = [];
  plantRegionList: any = [];
  plantModelList: any = [];
  listOfOrderStatus: any = [];
  submitted: boolean = false;
  FA: any;
  docType: any;
  date = new UntypedFormControl(new Date());
  statusList = [
    { id: 1, itemName: "Ordered" },
    { id: 2, itemName: "Changed" },
    { id: 3, itemName: "Cancelled" },
    { id: 4, itemName: "In-Transit" },
    { id: 5, itemName: "Delivered" },
    { id: 6, itemName: "Invoiced" },
    { id: 7, itemName: "Paid" }
  ];
  kycRemarks = [
    { id: 1, itemName: "Duplicate KYC submission" },
    { id: 2, itemName: "Firm name mismatch" },
    { id: 3, itemName: "GSTN cancelled" },
    { id: 4, itemName: "GSTN inactive" },
    { id: 5, itemName: "GSTN suspended" },
    { id: 6, itemName: "PAN number mismatch" },
    { id: 7, itemName: "PAN card missing" },
    { id: 8, itemName: "PAN image unclear" },
    { id: 9, itemName: "PAN image incomplete" },
    { id: 10, itemName: "Firm PAN not provided" },
    { id: 11, itemName: "PAN mismatch as GST portal" },
    { id: 12, itemName: "Pesticide license unclear" },
    { id: 13, itemName: "Seed license unclear" },
    { id: 14, itemName: "Fertilizer license unclear" },
    { id: 15, itemName: "Pesticide license incomplete" },
    { id: 16, itemName: "Seed license incomplete" },
    { id: 17, itemName: "Fertilizer license incomplete" },
    { id: 18, itemName: "Pesticide license expired" },
    { id: 19, itemName: "Seed license expired" },
    { id: 20, itemName: "Fertilizer license expired" },
    { id: 21, itemName: "Sign & Stamp missing" },
    { id: 22, itemName: "Approval needed from Abhishek Upadhyay" },
    { id: 23, itemName: "Invalid business proof" },
    { id: 24, itemName: "Wrong document uploaded" },
    { id: 25, itemName: "Business proof missing" },
    { id: 26, itemName: "Shop act image unclear" },
    { id: 27, itemName: "Shop act image incomplete" },
    { id: 28, itemName: "Shop act expired" },
    { id: 29, itemName: "Labour license image unclear" },
    { id: 30, itemName: "Labour license image incomplete" },
    { id: 31, itemName: "Labour license expired" },
    { id: 32, itemName: "Property tax image unclear" },
    { id: 33, itemName: "Property tax image incomplete" },
    { id: 34, itemName: "Property tax expired" },
    { id: 35, itemName: "Udyog Aadhar image unclear" },
    { id: 36, itemName: "Udyog Aadhar image incomplete" },
    { id: 37, itemName: "Udyog Aadhar expired" },
    { id: 38, itemName: "Mandi license image unclear" },
    { id: 39, itemName: "Mandi license image incomplete" },
    { id: 40, itemName: "Mandi license expired" },
    { id: 41, itemName: "Electricity bill more than 3 months old" },
    { id: 42, itemName: "Electricity bill image not clear" },
    { id: 43, itemName: "Ownership proof needed" },
    { id: 44, itemName: "Electricity bill image incomplete" },
    { id: 45, itemName: "Telephone bill more than 3 months old" },
    { id: 46, itemName: "Telephone bill image not clear" },
    { id: 48, itemName: "Telephone bill image incomplete" },
    { id: 49, itemName: "Water bill more than 3 months old" },
    { id: 50, itemName: "Water bill image not clear" },
    { id: 52, itemName: "Water bill image incomplete" },
    { id: 53, itemName: "On the request of CS team" },
    { id: 54, itemName: "On the request of Sales team" },
    { id: 55, itemName: "PIN code mismatch" }
  ];
  pesticideRemarks = [
    { id: 1, itemName: "Pest. License expired" },
    { id: 2, itemName: "Pest. License unclear" },
    { id: 3, itemName: "Renewal Challan image unclear" },
    { id: 4, itemName: "Pest. License incomplete" },
    { id: 5, itemName: "Wrong document provided" },
    { id: 6, itemName: "Sign & Stamp missing in license" },
    { id: 7, itemName: "Firm name mismatch" },
    { id: 8, itemName: "Need to change expiry date" },
    { id: 9, itemName: "Need to change remarks" },
    { id: 10, itemName: "On the request of CS team" },
    { id: 11, itemName: "On the request of Sales team" }
  ];
  seedRemarks = [
    { id: 1, itemName: "Seeds License expired" },
    { id: 2, itemName: "Seeds License unclear" },
    { id: 3, itemName: "Renewal Challan image unclear" },
    { id: 4, itemName: "Seeds License incomplete" },
    { id: 5, itemName: "Wrong document provided" },
    { id: 6, itemName: "Sign & Stamp missing in license" },
    { id: 7, itemName: "Firm name mismatch" },
    { id: 7, itemName: "Need to change expiry date" },
    { id: 7, itemName: "Need to change remarks" },
    { id: 7, itemName: "On the request of CS team" },
    { id: 7, itemName: "On the request of Sales team" }
  ];
  fertilizerRemarks = [
    { id: 1, itemName: "Fert. License expired" },
    { id: 2, itemName: "Fert. License unclear" },
    { id: 3, itemName: "Renewal Challan image unclear" },
    { id: 4, itemName: "Fert. License incomplete" },
    { id: 5, itemName: "Wrong document provided" },
    { id: 6, itemName: "Sign & Stamp missing in license" },
    { id: 7, itemName: "Firm name mismatch" },
    { id: 7, itemName: "Need to change expiry date" },
    { id: 7, itemName: "Need to change remarks" },
    { id: 7, itemName: "On the request of CS team" },
    { id: 7, itemName: "On the request of Sales team" }
  ];
  pendingRemarks = [
    { id: 1, itemName: "Need to change remarks" }
  ];
  customDateHeader = CustomDatePickerHeaderComponent;
  range: FormGroup<{ startDate: FormControl<any>; endDate: FormControl<any>; }>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    private events: EventsService,
    private location: Location,
    private notification: NotificationService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public orderService: OrderService,
    public dialogRef: MatDialogRef<PopUpComponent>,
    public calendarService: CalendarService
  ) {
    this.orderStatusForm = this.formBuilder.group({
      // customer: ["", [Validators.required]],
      // orderAmount: ["", [Validators.required]],
      // orderDate: ["", [Validators.required]],
      // orderNumber: ["", [Validators.required]],
      orderStatus: ["", [Validators.required]]
    });
    
    this.changeFAForm = this.formBuilder.group({
      pinCodeFAMappingId: ["", [Validators.required]]
    });
  }

  ngOnInit() {
    // console.log("DIALOG", this.dialogRef);
    this.dialogData = this.dialog_data.data;
    // console.log("DIALOG-data", this.dialog_data.data.data);
    this.docType = this.dialog_data?.data?.data;
    if (this.dialogData && this.dialogData.id === "unauthorized") {
      this.dialogRef.updateSize("40%", "auto");
      if(this.dialogData.isMobile) {
        this.dialogRef.updateSize("80%", "auto");
      }
    }
    if (this.dialogData && this.dialogData.id === "unauthorized-mobile") {
      this.dialogRef.updateSize("40%", "auto");
    }
    if (this.dialogData && this.dialogData.id === "reject_kyc") {
      this.dialogRef.updateSize("40%", "auto");
      this.rejectKycForm = this.formBuilder.group({
        description: ["", [Validators.required]]
      });
    }
    if (this.dialogData && this.dialogData.id === "reject_doc") {
      this.dialogRef.updateSize("40%", "auto");
      this.rejectDocForm = this.formBuilder.group({
        sms: ["", [Validators.required]],
        notification: ["", [Validators.required]]
      });
    }
    if (this.dialogData && this.dialogData.id === "reject_kyc_doc") {
      this.dialogRef.updateSize("40%", "auto");
      this.rejectKYCDocForm = this.formBuilder.group({
        notification: ["", [Validators.required]]
      });
    }
    if (this.dialogData && this.dialogData.id === "extend_expiry") {
      this.dialogRef.updateSize("40%", "auto");
    }
    if (this.dialogData && this.dialogData.id === "retailer_block_unblock") {
      this.dialogRef.updateSize("40%", "auto");
      this.retailerBlockUnblockForm = this.formBuilder.group({
        reason: ["", [Validators.required]]
      });
    }
    if (this.dialogData && this.dialogData.id === "cancel_order") {
      this.dialogRef.updateSize("40%", "auto");
      this.cancelOrderForm = this.formBuilder.group({
        description: ["", [Validators.required]]
      });
    }
    if (this.dialogData && this.dialogData.id === "pending_kyc") {
      this.dialogRef.updateSize("40%", "auto");
      this.pendingKycForm = this.formBuilder.group({
        description: ["", [Validators.required]]
      });
    }
    if (this.dialogData && this.dialogData.id === "update_order_status") {
      this.dialogRef.updateSize("40%", "80%");
      this.updateOrderStatus(this.dialogData);
    }
    if (this.dialogData && this.dialogData.id === "change_FA") {
      this.dialogRef.updateSize("50%", "auto");
      // this.updateFA(this.dialogData);
      this.getFAList(this.dialogData);
      // this.changeFAForm.controls["pinCodeFAMappingId"].setValue('');
    }
    if (this.dialogData && this.dialogData.id === 'delete_record') {
      this.dialogRef.updateSize("65%", "auto");
    }
    if (this.dialogData && this.dialogData.id === "export_order_details") {
      this.dialogRef.updateSize("60%", "auto");

      this.dialogData.placeholderStartDate = this.dialogData.deliveryExport ? "Order Start Date": "Start Date";
      this.dialogData.titleStartDate = this.dialogData.deliveryExport ? "select order start date": "select start date";

      this.dialogData.placeholderEndDate = this.dialogData.deliveryExport ? "Order End Date": "End Date";
      this.dialogData.titleEndDate = this.dialogData.deliveryExport ? "select order end date": "select end date";

      let defaultStartDate = moment().subtract(1, 'days');
      let defaultEndDate = moment();
      this.calendarService.setMaxRange(10);
      this.range = new FormGroup({
        startDate: new FormControl(defaultStartDate, [Validators.required]),
        endDate: new FormControl(defaultEndDate, [Validators.required]),
      });
      this.orderExportForm = this.formBuilder.group({
        startDate: this.range.controls['startDate'],
        endDate: this.range.controls['endDate'],
        plant: [''],
        status: [''],
        filterPlantRegion: [""],
        filterPlantModel: [""],  
      });

      // if(this.dialogData.plantMandatory) {
      //   this.orderExportForm.controls["plant"].setValidators(Validators.required)
      // }

      this.orderExportForm.controls["plant"].setValue(null);
      this.orderExportForm.controls["status"].setValue(null);
      this.orderExportForm.controls["filterPlantRegion"].setValue(null);
      this.orderExportForm.controls["filterPlantModel"].setValue(null);
      this.getAllPlantList();
      this.getplantRegionList();
      this.getplantModelList();  
      this.listOfOrderStatus = this.dialogData.listOfOrderStatus;
    }
    if (this.dialogData && this.dialogData.id === "terms_condtion_cancel") {
      this.dialogRef.updateSize("40%", "auto");
    }
  }

  setStartDateForOrderExport(event, date) {
    this.startdate = moment(event.target.value._d).format("DD-MM-YYYY")
  }

  setEndDateForOrderExport(event) {
    this.enddate = moment(event.target.value._d).format("DD-MM-YYYY")
  }

  checkDates(group: UntypedFormGroup) {
    if (group.controls.endDate.value < group.controls.startDate.value) {
      return { notValid: true }
    }
    return null;
  }

  ngAfterViewInit() {
    this.events.deletePopup.subscribe(item => {
      if (item) {
        // this.deleteModal.open();
      }
    });
  }

  getFAList(data) {
    let fa_list = [];
    this.orderService.getFAList(data.data.enterpriseId).subscribe((response: any) => {
      if (response.status === 200) {
        response.body.map((fa) => {
          fa_list.push(fa);
        });
        this.changeFAForm.controls["pinCodeFAMappingId"].setValue(data.data.pinCodeFAMappingId ? data.data.pinCodeFAMappingId : "");
        this.fieldAgentList = [...fa_list];
      }
      // console.log('getAllFas', this.fieldAgentList)
    });
  }

  onSubmitDate(value) {
    console.log('onSubmitDate', value);

  }

  updateFA(orderData) {
    console.log('updateFA', orderData);
    // this.changeFAForm.setValue({
    //   fieldAgent: orderData.data.fieldAgent;
    // });
    // this.changeFAForm.controls["fieldAgent"].setValue(orderData.data.fieldAgent);
    this.changeFAForm.controls["pinCodeFAMappingId"].setValue('George Michael');
  }

  updateOrderStatus(orderData) {
    this.multiDropdownSettings = {
      singleSelection: true,
      enableFilterSelectAll: false,
      text: orderData.data.orderStatus,
      enableSearchFilter: true,
      classes: "topic-dropdown",
      disabled: false,
      clearAll: false,
      position: "bottom",
      maxHeight: 175
      // lazyLoading: true,
    };

    this.orderStatusForm.setValue({
      // customer: orderData.data.customer,
      // orderAmount: orderData.data.orderAmount,
      // orderDate: orderData.data.orderDate,
      // orderNumber: orderData.data.orderNumber,
      orderStatus: orderData.data.orderStatus
    });
  }
  onChange(event) {
    // console.log('onselect', event);
    this.orderStatusForm.controls["orderStatus"].setValue(event.itemName);
  }

  deleteConfirmation(data: string) {
    const path = this.location.path();
    this.redirectTo(path);
    // this.location.back();
  }
  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
    this.notification.success("Record deleted successfully");
  }

  cancelPopup() {
    // this.deleteModal.close();
    this.events.deletePopup.emit(false);
  }

  getDates(dates) {
    if (dates.startDate._d) {
      this.startdate = moment(dates.startDate._d).format("DD-MM-YYYY")
    } else {
      this.startdate = moment(new Date()).format("DD-MM-YYYY")
    }
    if (this.orderExportForm.value.endDate._d) {
      this.enddate = moment(dates.endDate._d).format("DD-MM-YYYY")
    } else {
      this.enddate = moment(new Date()).format("DD-MM-YYYY")
    }
  }
  onCloseConfirm() {
    if (this.dialogData && this.dialogData.id == "update_order_status") {
      // console.log('update status', this.orderStatusForm.value);
      this.dialogRef.close({ data: this.orderStatusForm.value, value: "ok" });
    } else if (this.dialogData && this.dialogData.id === "reject_kyc") {
      if (this.rejectKycForm.invalid) {
        this.rejectKycFormInvalid = true;
      } else {
        this.rejectKycFormInvalid = false;
        this.dialogRef.close({ data: this.rejectKycForm.value, value: "ok" });
      }
    } else if (this.dialogData && this.dialogData.id === "reject_doc") {
      if (this.rejectDocForm.invalid) {
        this.rejectDocFormInvalid = true;
        this.rejectDocForm.get('sms').markAsTouched();
        this.rejectDocForm.get('notification').markAsTouched();
      } else {
        this.rejectDocFormInvalid = false;
        this.dialogRef.close({ data: this.rejectDocForm.value, value: "ok" });
      }
    } else if (this.dialogData && this.dialogData.id === "reject_kyc_doc") {
      if (this.rejectKYCDocForm.invalid) {
        this.rejectKYCDocFormInvalid = true;
        this.rejectKYCDocForm.get('notification').markAsTouched();
      } else {
        this.rejectKYCDocFormInvalid = false;
        this.dialogRef.close({ data: this.rejectKYCDocForm.value, value: "ok" });
      }
    } else if (this.dialogData && this.dialogData.id === "retailer_block_unblock") {
      if (this.retailerBlockUnblockForm.invalid) {
        this.retailerBlockUnblockFormInvalid = true;
        this.retailerBlockUnblockForm.get('reason').markAsTouched();
      } else {
        this.retailerBlockUnblockFormInvalid = false;
        this.dialogRef.close({ data: this.retailerBlockUnblockForm.value, value: "ok" });
      }
    } else if (this.dialogData && this.dialogData.id === "cancel_order") {
      if (this.cancelOrderForm.invalid) {
        this.cancelOrderFormInvalid = true;
      } else {
        this.cancelOrderFormInvalid = false;
        this.dialogRef.close({ data: this.cancelOrderForm.value, value: "ok" });
      }
    } else if (this.dialogData && this.dialogData.id === "pending_kyc") {
      if (this.pendingKycForm.invalid) {
        this.pendingKycFormInvalid = true;
      } else {
        this.pendingKycFormInvalid = false;
        this.dialogRef.close({ data: this.pendingKycForm.value, value: "ok" });
      }
    } else if (this.dialogData && this.dialogData.id === "export_order_details") {
      let plant = this.orderExportForm.value.plant;
      let status = this.orderExportForm.value.status;
      let plantRegionList = this.orderExportForm.value.filterPlantRegion;
      let plantModelList = this.orderExportForm.value.filterPlantModel;
      this.submitted = true;

      if(this.dialogData.plantMandatory) {
        const plantRegionInvalid = plantRegionList ? !plantRegionList.length : true;
        const plantModelInvalid = plantModelList ? !plantModelList.length : true;
        const plantInvalid = plant ? !plant.length : true;
        if(plantInvalid && plantRegionInvalid && plantModelInvalid) {
          this.notification.error("Please select plant or plant region or plant model");
          return;        
        }
      }
      this.getDates(this.orderExportForm.value);
      let dates = { startDate: this.startdate, endDate: this.enddate }
      this.dialogRef.close({ 
        date: dates, 
        plant: plant, 
        plantRegionList: plantRegionList,
        plantModelList: plantModelList,
        status: status,  
        value: "ok" 
      });
    } else if (this.dialogData && this.dialogData.id === "change_FA") {
      this.dialogRef.close({ data: this.changeFAForm.value, value: "ok" });
    }
    else {
      this.dialogRef.close({ value: "ok" });
    }
  }

  onCloseCancel() {
    this.dialogRef.close({ value: "cancel" });
  }
  rejectKYC(value) { }
  submit() {
    this.dialogRef.close(this.rejectKycForm.value);
  }

  customFASearch(term: string, item: any) {
    term = term.trim().toString().toLocaleLowerCase();
    return item.empName.toLocaleLowerCase().indexOf(term) > -1
        || item.pinCodeFrom.toString().toLocaleLowerCase().indexOf(term) > -1
        || item.pinCodeTo.toString().toLocaleLowerCase().indexOf(term) > -1
        || item.plant.toLocaleLowerCase().indexOf(term) > -1;
  }

  getAllPlantList() {
    let plantList = [];
    this.orderService.getAllPlantNames().subscribe((response: any) => {
      if (response.status === 200) {
        response.body.map(plant => {
          plant['formattedName'] = `${plant.plantName} [${plant.plantCode}]`;
          plantList.push(plant);
        });
        this.listOfPlants = [...plantList];
      }
    });
  }

  getplantRegionList() {
    let plantRegions = [];
    this.orderService.getAllPlantRegions().subscribe((response: any) => {
      if (response.status === 200) {
        response.body.map(plant => {
          plantRegions.push(plant);
        });
        this.plantRegionList = [...plantRegions];
      }
    });
  }

  getplantModelList() {
    let plantModels = [];
    this.orderService.getAllPlantModels().subscribe((response: any) => {
      if (response.status === 200) {
        response.body.map(plant => {
          plantModels.push(plant);
        });
        this.plantModelList = [...plantModels];
      }
    });
  }

  getPlaceholderAndReason(type) {
    let reason = '';
    let placeholder = '';
    if(type === 'kycReject') {
      reason = 'Please mention the reason for KYC Rejection.';
      placeholder = 'Reason for KYC rejection';
    } else if(type === 'kycPending') {
      reason = 'Please mention the reason for KYC Pending.';
      placeholder = 'Reason for KYC pending';
    } else {
      reason = 'Please mention the reason for Document Rejection.';
      placeholder = 'Reason for Document rejection';
    }
    return {
      reason,
      placeholder
    }
  }

  //Order Export popup filters
  onChangePlant(values) {
    let getPlantList = values.map(plant => plant.plantCode);
    this.orderExportForm.get('plant').patchValue(getPlantList);
  }

  onSelectAllPlantCode() {
    const selected = this.listOfPlants.map(item => item.plantCode);
    this.orderExportForm.get('plant').patchValue(selected);
    this.onChangePlant(this.listOfPlants);
  }

  onClearAllPlantCode() {
    this.onChangePlant([]);
  }

  onChangeStatus(values) {
    let getOrderStatusList = values.map(status => status.id);
    this.orderExportForm.get('status').patchValue(getOrderStatusList);
  }

  onChangePlantRegion(values) {
    let getOrderStatusList = values.map(item => item.id);
    this.orderExportForm.get('filterPlantRegion').patchValue(getOrderStatusList);
  }

  onChangePlantModel(values) {
    let getOrderStatusList = values.map(item => item.id);
    this.orderExportForm.get('filterPlantModel').patchValue(getOrderStatusList);
  }

  onSelectAllOrderStatus() {
    const selected = this.listOfOrderStatus.map(item => item.id);
    this.orderExportForm.get('status').patchValue(selected);
    this.onChangeStatus(this.listOfOrderStatus);
  }

  onClearAllOrderStatus() {
    this.onChangeStatus([]);
  }

  onSelectAllPlantRegion() {
    const selected = this.plantRegionList.map(item => item.id);
    this.orderExportForm.get('filterPlantRegion').patchValue(selected);
    this.onChangePlantRegion(this.plantRegionList);
  }

  onClearAllPlantRegion() {
    this.onChangePlantRegion([]);
  }

  onSelectAllPlantModel() {
    const selected = this.plantModelList.map(item => item.id);
    this.orderExportForm.get('filterPlantModel').patchValue(selected);
    this.onChangePlantModel(this.plantModelList);
  }

  onClearAllPlantModel() {
    this.onChangePlantModel([]);
  }

  handleSelectCheckbox(event) {
    event.stopPropagation();
  }
}
