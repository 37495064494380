import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';

import { SettingsModule } from '@app/settings';
import { StaticModule } from '@app/static';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { EventsService } from '@app/events/events.service';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import { MyDatePickerModule } from 'mydatepicker';
import { PopupModule } from '@app/pop-up/pop-up.module';
import { SpinnerModule } from '@app/shared/spinner/spinner.module';
import { SpinnerService } from '@app/services/spinner.service';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { NavService } from "./menu-list-item/service/nav.service";
import {CallbackComponent} from './callback.component';
import {TermsAndConditionPopupModule} from '@app/common-popup/terms-and-condition-modal/terms-and-condition-module';
// import {FestiveAnnouncementModule} from '@app/common-popup/festive-announcement/festive-announcement.module'
import { SellerAgreementModalModule } from './common-popup/seller-agreement-modal/seller-agreement.module';

import { registerLocaleData } from '@angular/common';
import localeIn from '@angular/common/locales/en-IN';

registerLocaleData(localeIn);
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { CommonModule } from '@angular/common';
import {CommonComponentsModule} from '@app/common-components/common-components.module'
import { MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,

    // core & shared
    CoreModule,
    SharedModule,

    // features
    StaticModule,
    SettingsModule,

    // app
    AppRoutingModule,

    SpinnerModule,

    // angular-multi-select
    // AngularMultiSelectModule,
    FormsModule,
    NgxSmartModalModule.forRoot(),
    CommonModule,
      // DatePicker
    MyDatePickerModule,
    PopupModule,
    TermsAndConditionPopupModule,
    // FestiveAnnouncementModule,
    SellerAgreementModalModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    CommonComponentsModule,
    providePerformance(() => getPerformance()),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: "4d213d05-349b-419f-b8fb-73a8f2aa8394", // Application (client) ID from the app registration
          authority:
            "https://login.microsoftonline.com/8bc97f9a-ea86-472e-8ab7-19db58c4c8af/oauth2/v2.0/authorize", // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: `${window.location.origin}/callback`, // This is your redirect URI,
          
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      null,
      null
    ),
  ],
  
  declarations: [AppComponent, MenuListItemComponent, CallbackComponent],
  providers: [
    EventsService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    SpinnerService,
    NavService,
    UserTrackingService,
    ScreenTrackingService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
