import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadMediaComponent implements OnInit, OnChanges {
  @Input() allowedMediaTypes: string;
  @Input() uploadedFile: string;
  @Input() restrictAccess: boolean = false;
  @Input() resetUpload: boolean = false;
  @Input() showTypeNote: boolean = false;
  @Output() mediaUploadEvent = new EventEmitter<string>();
  fileName: string;
  
  constructor() { }

  ngOnInit(): void {
    if(this.uploadedFile) {
      this.fileName = this.uploadedFile.substring(this.uploadedFile.lastIndexOf('/')+1)
    }
  }

  ngOnChanges() {
    if(this.resetUpload)
        this.fileName = '';
  }

  fileUpload(event) {
    let file = event.target.files[0];
    if(file) {
      this.fileName = file.name;
      this.mediaUploadEvent.emit(file);
    }
  }

}
