import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormArray,
} from "@angular/forms";
import { NotificationService } from "@app/core/notifications/notification.service";

@Component({
  selector: "multiple-upload",
  templateUrl: "./multiple-upload.component.html",
  styleUrls: ["./multiple-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MultipleUploadComponent implements OnInit {
  disableImport: boolean = false;
  productImages: any = [];
  deleteImages: any = [];
  @Input() form: FormGroup;
  @Input() maxMediaUploadCount: number = 5;
  @Output() uploadsUpdatedEvent = new EventEmitter<any>();

  constructor(
    private notification: NotificationService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {}

  getFileDetails(event) {
    let images = event.target.files;
    let totalImages = parseInt(images.length) + this.productImages.length;
    if (totalImages > this.maxMediaUploadCount) {
      this.notification.error(
        `Max ${this.maxMediaUploadCount} images can be imported`
      );
      return;
    }
    for (let image of images) {
      if (image?.size > 10000000) this.disableImport = true;
      this.productImages.push(image);
    }
    this.updateFiles(this.productImages);
    this.uploadsUpdatedEvent.emit(this.productImages);
  }

  removeImage(imageIndex) {
    this.disableImport = false;
    this.productImages.splice(imageIndex, 1);
    for (let image of this.productImages) {
      if (image.size > 10000000) this.disableImport = true;
    }
    this.updateFiles(this.productImages);
    this.uploadsUpdatedEvent.emit(this.productImages);
  }

  editFileDetails(eventData, i) {
    let image = eventData.target.files[0];
    this.disableImport = false;

    if (image.size > 10000000) this.disableImport = true;

    this.productImages[i] = image;

    for (let image of this.productImages) {
      if (image.size > 10000000) this.disableImport = true;
    }
    this.updateFiles(this.productImages);
    this.uploadsUpdatedEvent.emit(this.productImages);
  }

  createItem(data): FormGroup {
    return this.fb.group(data);
  }

  //Get all media controls as form array.
  get media(): FormArray {
    return this.form.get("media") as FormArray;
  }

  updateFiles(files) {
    if (files) {
      //If files are updated, deleted or edited, reset the 'media' form array.
      const control = <FormArray>this.form.controls['media'];
      for(let i = control.length-1; i >= 0; i--) {
        control.removeAt(i)
      }

      // Iterate through files and push it to 'media' form array
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.media.push(
            this.createItem({
              file,
              url: e.target.result, //Base64 string for preview image
            })
          );
        };
        reader.readAsDataURL(file);
      }
    }
  }
}
