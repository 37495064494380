<div class="container">
  <div class="row">
    <div class="col-sm-12"><h1>{{ 'anms.settings.title' | translate }}</h1></div>
  </div>
  <br>
  <ng-container *ngIf="settings$ | async as settings">
    <div class="row">
      <div class="col-md-6 group" [ngClass]="routeAnimationsElements">
        <h2>{{ 'anms.settings.general.title' | translate }}</h2>
        <div class="icon-form-field">
          <mat-icon color="accent"><fa-icon icon="language" color="accent"></fa-icon></mat-icon>
          <mat-form-field>
            <mat-select [placeholder]="'anms.settings.general.placeholder' | translate"
                        [ngModel]="settings.language"
                        (selectionChange)="onLanguageSelect($event)"
                        name="language">
              <mat-option *ngFor="let l of languages" [value]="l.value">
                {{ 'anms.settings.general.language.' + l.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="icon-form-field">
           <mat-icon color="accent"><fa-icon icon="bars" color="accent"></fa-icon></mat-icon>
           <mat-placeholder>{{ 'anms.settings.themes.sticky-header' | translate }}
           </mat-placeholder>
           <mat-slide-toggle
             [checked]="settings.stickyHeader"
             (change)="onStickyHeaderToggle($event)">
           </mat-slide-toggle>
         </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 group" [ngClass]="routeAnimationsElements">
        <h2>{{ 'anms.settings.themes.title' | translate }}</h2>
        <div class="icon-form-field">
          <mat-icon color="accent"><fa-icon icon="paint-brush" color="accent"></fa-icon></mat-icon>
          <mat-form-field>
            <mat-select [placeholder]="'anms.settings.themes.placeholder' | translate"
                        [ngModel]="settings.theme"
                        (selectionChange)="onThemeSelect($event)"
                        name="themes">
              <mat-option *ngFor="let t of themes" [value]="t.value">
                {{ 'anms.settings.themes.' + t.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="icon-form-field">
          <mat-icon color="accent"><fa-icon icon="lightbulb" color="accent"></fa-icon></mat-icon>
          <mat-placeholder>{{ 'anms.settings.themes.night-mode' | translate }}
          </mat-placeholder>
          <mat-slide-toggle
            [checked]="settings.autoNightMode"
            (change)="onAutoNightModeToggle($event)">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-md-6 group" [ngClass]="routeAnimationsElements">
        <h2>{{ 'anms.settings.animations.title' | translate }}</h2>
        <div class="icon-form-field">
          <mat-icon color="accent"><mat-icon color="accent"><fa-icon icon="window-maximize"></fa-icon></mat-icon></mat-icon>
          <mat-placeholder>{{ 'anms.settings.animations.page' | translate }}
          </mat-placeholder>
          <mat-slide-toggle
            matTooltip="Sorry, this feature is disabled in IE, EDGE and Safari"
            matTooltipPosition="before"
            *ngIf="settings.pageAnimationsDisabled"
            disabled>
          </mat-slide-toggle>
          <mat-slide-toggle
            *ngIf="!settings.pageAnimationsDisabled"
            [checked]="settings.pageAnimations"
            (change)="onPageAnimationsToggle($event)">
          </mat-slide-toggle>
        </div>
        <div class="icon-form-field">
          <mat-icon color="accent"><fa-icon icon="stream" color="accent"></fa-icon></mat-icon>
          <mat-placeholder>{{ 'anms.settings.animations.elements' | translate }}
          </mat-placeholder>
          <mat-slide-toggle
            [checked]="settings.elementsAnimations"
            (change)="onElementsAnimationsToggle($event)">
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </ng-container>
</div>
