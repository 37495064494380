import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { SharedModule } from '@app/shared';
import { UploadMediaComponent } from './upload-media/upload-media.component';
import { CustomDatePickerHeaderComponent } from './custom-date-picker-header/custom-date-picker-header.component';
import { MaxRangeDirective } from '@app/common-components/mat-date-range-picker/date-max-range.directive';
import { MatDateRangePickerCustomComponent } from './mat-date-range-picker/mat-date-range-picker-custom/mat-date-range-picker-custom.component';
import { MatRangePickerHeaderComponent } from './mat-date-range-picker/mat-range-picker-header/mat-range-picker-header.component'
import {CalendarService} from "@app/common-components/mat-date-range-picker/calendar-service.service";
import { StartEndDatePickerComponent } from './start-end-date-picker/start-end-date-picker.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { IvyCarouselModule } from "angular-responsive-carousel";
import { MultipleUploadComponent } from './multiple-upload/multiple-upload.component';

@NgModule({
  declarations: [
    DateRangePickerComponent,
    UploadMediaComponent,
    CustomDatePickerHeaderComponent,
    MaxRangeDirective,
    MatDateRangePickerCustomComponent,
    MatRangePickerHeaderComponent,
    StartEndDatePickerComponent,
    ImagePreviewComponent,
    MultipleUploadComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IvyCarouselModule
  ],
  exports: [
    DateRangePickerComponent,
    StartEndDatePickerComponent,
    UploadMediaComponent,
    MaxRangeDirective,
    MatDateRangePickerCustomComponent,
    ImagePreviewComponent,
    MultipleUploadComponent
  ],
  providers: [
    CalendarService
  ]
})
export class CommonComponentsModule { }
