import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Inject,
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from "@angular/material/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {CalendarService} from "@app/common-components/mat-date-range-picker/calendar-service.service";
import * as moment from "moment";


@Component({
  selector: 'mat-range-picker-header',
  templateUrl: './mat-range-picker-header.component.html',
  styleUrls: ['./mat-range-picker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatRangePickerHeaderComponent<D> implements OnInit, OnDestroy {

  private _destroyed = new Subject<void>();
  maxRange: number = 0;
  selectedStartDate: any;
  selectedEndDate: any;

  constructor(
    public calendarService: CalendarService,
    private _calendar ?: MatCalendar<D>,
    private _dateAdapter ?: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats ?: MatDateFormats,
    cdr ?: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.selectedStartDate = moment(this.calendarService.getStartDate).format("DD/MM/YYYY");
        this.selectedEndDate = moment(this.calendarService.getEndDate).format("DD/MM/YYYY");
        cdr.markForCheck()
      });
    this.maxRange = this.calendarService.getMaxRange;
  }

  ngOnInit(): void {
    this.selectedStartDate = moment(this.calendarService.getStartDate).format("DD/MM/YYYY");
    this.selectedEndDate = moment(this.calendarService.getEndDate).format("DD/MM/YYYY");
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  previousClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
